<template>
  <div class="view">
    <div class="view-header">
      <topbar>
        <template #title>
          <h1 v-if="datas">{{ datas.name }}</h1>
        </template>

        <template #actions>
          <btn
            :theme="'icon'"
            :disabled="loading"
            @click="onSave"
          >
            <sprite
              :type="'save'"
              :width="20"
              :height="20"
            />
          </btn>

          <btn
            :disabled="loading || create"
            :theme="'icon ghost'"
            @click="onToggleDelete"
          >
            <sprite :type="'less'" />
          </btn>
        </template>
      </topbar>
    </div>

    <div class="view-content">
      <div class="view-content-wrapper">
        <fields v-if="datas">
          <div class="fields-row" data-column="3">
            <!-- Name -->
            <div class="fields-field">
              <inputs
                :id="'client-name'"
                :value="datas.name"
                :label="$t('labels.name')"
                :required="true"
                @input="onInput($event, 'name')"
              />
            </div>

            <!-- Email -->
            <div class="fields-field">
              <inputs
                :id="'client-email'"
                :type="'email'"
                :value="datas.email"
                :label="$t('labels.email')"
                :required="true"
                @input="onInput($event, 'email')"
              />
            </div>

            <div v-if="datas.uuid" class="fields-field">
              <inputs
                :id="'client-uuid'"
                :value="datas.uuid"
                :label="$t('labels.uuid')"
                @input="onInput($event, 'subscribed_until')"
                @submit="onCopyUUID"
              >
                <template #icon>
                  <sprite :type="'copy'" />
                </template>
              </inputs>
            </div>

            <!-- Subscribed until -->
            <div class="fields-field">
              <inputs
                :id="'client-subscribed_until'"
                :type="'date'"
                :value="subscribed"
                :label="$t('labels.subscribed_until')"
                @input="onInput($event, 'subscribed_until')"
              />
            </div>

            <!-- Mail system -->
            <div class="fields-field">
              <switcher
                :id="'lawyer-mail_systeme'"
                :checked="common_box"
                :label="$t('labels.mail_sytem')"
                @change="onInput($event, 'mail_system')"
              />
            </div>

            <!-- Email Box -->
            <div
              v-show="common_box"
              class="fields-field"
            >
              <inputs
                :id="'client-email-box'"
                :type="'email'"
                :value="datas.email_box"
                :label="$t('labels.email_box')"
                :required="true"
                @input="onInput($event, 'email_box')"
              />
            </div>
          </div>

          <div class="fields-row" data-column="3">
            <!-- Lawyers -->
            <div class="fields-field">
              <search
                :id="'lawyers-search'"
                :type="'lawyers'"
                :selecteds="selectedsLawyers"
                @click="onLawyerSelect"
              />
            </div>
            <div class="fields-field" style="grid-column: 2 / span 2">
              <pills
                :property="'name'"
                :els="lawyers"
                @remove="onRemoveLawyer"
              />
            </div>
          </div>
          <div class="fields-row" data-column="2">
             <div class="fields-field">
              <btn @click="toggleCustomization">
                {{ $t('actions.customization') }}
              </btn>
              <!-- spartiate_mail_sent_on -->
              <btn
                v-if="!datas.spartiate_mail_sent_on"
                :theme="'ghost'"
                @click="toggleCreation"
              >
                {{ $t('actions.create-accounts') }}
              </btn>
              <span
                v-else
                class="fields-field-text"
              >
                Invitations envoyées le : <strong>{{ getDate(datas.spartiate_mail_sent_on) }}</strong>
              </span>
            </div>
          </div>
        </fields>
      </div>
      <loader v-show="loading"/>
    </div>

    <!-- Popup -->
    <popup
      v-if="showPopupDelete"
      :title="$t('clients.delete.title.singular')"
      @close="onCloseDelete"
    >
      <p class="popup-text">{{ $t('clients.delete.text') }}</p>
      <div class="popup-form">
        <inputs
          :id="'client-delete'"
          :value="deleteInput"
          :error="deleteError"
          @input="onDeleteInput"
        />
        <btn
          :theme="'ghost'"
          :disabled="deleteInput.length === 0"
          @click="onDelete"
        >
          {{ $t('actions.delete') }}
        </btn>
      </div>
    </popup>

    <popup
      v-if="creation"
      :title="$t('clients.creation.title')"
      @close="toggleCreation"
    >
      <p class="popup-text">{{ $t('clients.creation.text') }}</p>
      <btn
        :theme="'ghost'"
        @click="onCreate"
      >
        {{ $t('actions.create-accounts') }}
      </btn>
    </popup>

    <form-customization
      v-if="datas && customization"
      :currents="datas.custom_style_iframe_css"
      @close="toggleCustomization"
      @save="onSaveCustomization"
    />
  </div>
</template>

<script>
// Components
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';
import Inputs from '@/components/components/Inputs';
import Loader from '@/components/components/Loader';
import Switcher from '@/components/components/Switcher';
import Pills from '@/components/modules/Pills';
import Search from '@/components/modules/Search';
import Fields from '@/components/partials/Fields';
import Topbar from '@/components/partials/Topbar';
import Popup from '@/components/partials/Popup';
import FormCustomization from '@/components/partials/FormCustomization';
import moment from 'moment';

export default {
  components: {
    Btn, Sprite, Inputs, Loader, Switcher,
    Pills, Search,
    Fields, Topbar, Popup, FormCustomization
  },
  data() {
    return {
      showPopupDelete: false,
      deleteInput: '',
      deleteError: false,
      customization: false,
      creation: false
    }
  },
  computed: {
    datas() {
      return this.$store.state.client.datas;
    },
    loading() {
      return this.$store.state.client.requests > 0;
    },
    create() {
      return this.$route.name === 'ClientsCreate';
    },
    common_box() {
      return this.$store.state.client.datas.mail_system === 1;
    },
    lawyers() {
      return this.$store.state.client.datas.lawyers.map(l => {
        l.name = `${l.firstname} ${l.lastname}`;
        return l;
      });
    },
    selectedsLawyers() {
      return this.$store.state.client.datas.lawyers.map(l => l.id);
    },
    search() {
      return this.$store.state.client.search;
    },
    subscribed() {
      const { subscribed_until } = this.$store.state.client.datas;
      if (!subscribed_until) {
        return '';
      }

      const date = new Date(subscribed_until);
      const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const day = date.getDate() < 9 ? `0${date.getDate() + 1}` : date.getDate();
      return `${date.getFullYear()}-${month}-${day}`;
    }
  },
  beforeMount() {
    if (this.create) {
      this.$store.commit('client/reset');
      return;
    }

    const { uuid } = this.$route.params;
    this.$store.dispatch('client/get', { uuid });
  },
  watch: {
    $route(to) {
      if (to.name === 'ClientsDetail') {
        const { uuid } = to.params;
        this.$store.dispatch('client/get', { uuid });
      }
    }
  },
  methods: {
    // Form
    onInput(value, type) {
      if (type === 'mail_system') {
        this.$store.dispatch('client/update', {
          value: value ? 1 : 2,
          type });
        return;
      }

      this.$store.dispatch('client/update', { value, type });
    },
    onLawyerSelect(e) {
      const lawyer = e;
      lawyer.name = lawyer.firstname + ' ' + lawyer.lastname;

      this.$store.commit('client/updateDataArray', {
        value: lawyer,
        type: 'lawyers',
        property: 'id'
      });
    },
    onRemoveLawyer(e) {
      this.$store.commit('client/updateDataArray', {
        value: e,
        type: 'lawyers',
        property: 'id'
      });
    },
    onCopyUUID() {
      const $input = document.createElement('textarea');
      $input.value = this.datas.uuid;
      document.body.appendChild($input);
      $input.select();
      document.execCommand('copy');
      document.body.removeChild($input);
      this.$store.dispatch('notifications/addNotifs', [{
        type: 'success',
        text: this.$t('actions.copy-uuid')
      }]);
    },
    // Actions
    onSave() {
      if (this.create) {
        this.$store.dispatch('client/create');
        return;
      }

      this.$store.dispatch('client/save');
    },
    // Delete
    onCloseDelete() {
      this.showPopupDelete = false;
      this.deleteInput = '';
      this.deleteError = false;
    },
    onDeleteInput(value) {
      this.deleteInput = value;
    },
    onDelete() {
      this.$store.dispatch('client/delete');
    },
    onToggleDelete() {
      this.showPopupDelete = !this.showPopupDelete;
    },
    toggleCustomization() {
      this.customization = !this.customization;
    },
    async onCreate() {
      await this.$store.dispatch('client/createAccounts');
      this.creation = false;
    },
    toggleCreation() {
      this.creation = !this.creation;
    },
    onSaveCustomization(styles) {
      this.onInput(styles, 'custom_style_popup_css');
      this.onInput(styles, 'custom_style_iframe_css');
      this.toggleCustomization();
    },
    getDate(date) {
      return moment(date).format('DD[.]MM[.]YYYY');
    }
  }
}
</script>
