<template>
  <div :class="['selection', { 'is-open': showDropdown }, { 'is-multiple': multiple && selecteds.length > 0 }]">
     <label
      v-if="label"
      :for="id"
    >
      {{ label }}
    </label>

    <button
      class="selection-toggle"
      @click.stop="toggle"
    >
      <template v-if="selecteds && selecteds.length > 0">
        <span
          v-for="selected in selecteds"
          :key="`select-selected-${selected.id}`"
          class="selection-toggle-item"
        >
          {{ selected.name }}
          <button
            v-if="multiple"
            @click.stop="$emit('onRemove', selected)"
            class="selection-toggle-remove"
          />
        </span>
      </template>
      <template v-else>
        {{ placeholder }}
      </template>
    </button>

    <div v-show="showDropdown" class="selection-dropdown">
      <ul class="selection-list">
        <li
          v-for="option in options"
          :key="`selection-${id}-${option.id}`"
          class="selection-item"
        >
          <button
            @click.stop="onOption(option)"
            :class="['selection-option', { 'is-selected': selecteds.includes(option)}]">
            {{ option.name }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selecteds: Array,
    placeholder: String,
    label: String,
  },
  data() {
    return {
      showDropdown: false,
      windowClick: this.onWindowClick.bind(this)
    }
  },
  methods: {
    toggle() {
      if (this.showDropdown) {
        this.close();
        return;
      }

      this.open();
    },
    open() {
      this.showDropdown = true;

      window.addEventListener('click', this.windowClick);
    },
    close() {
      this.showDropdown = false;
      window.removeEventListener('click', this.windowClick);
    },
    onOption(option) {
      this.$emit('onOption', option);

      if (!this.multiple) {
        this.close();
        this.showDropdown = false;
      }
    },
    onWindowClick(e) {
      const { target } = e;
      const isChild = this.isDescendant(target);

      if (!isChild) {
        this.close();
      }
    },
    isDescendant(child) {
      let node = child.parentNode;
      while (node != null) {
        if (node == this.$el) {
          return true;
        }
        node = node.parentNode;
      }
     return false;
    }
  }
}
</script>

<style lang="scss" scoped>
.selection {
  position: relative;
  z-index: 1;
  width: 100%;

  &.is-multiple {
    z-index: 2;

    .selection-toggle {
      @include padding(0 22px 8px 7px);
    }

    .selection-toggle-item {
      @include margin(8px 3px null);
      @include padding(5px 20px 5px 10px);
      border-radius: 3px;
      background-color: var(--white-darker);
    }
  }

  label {
    @include padding(null null 10px);
    display: block;
    color: var(--grey);
    // color: var(--black);
    font-size: rem(14px);
    line-height: 1;
  }

  &.is-open {
    z-index: 2;

    .selection-toggle {
      border-radius: 4px 4px 0 0;
      border-bottom-color: rgba(0, 0, 0, 0);

      &::after {
        border-top: 0;
        border-bottom: 5px solid var(--grey-lightest);
      }

      &:hover {
        border-color: var(--grey-lightest);
        border-bottom-color: rgba($black, 0);

        &::after {
          border-top: 0;
          border-bottom-color: var(--primary);
        }
      }
    }
  }

  &-toggle {
    @include padding(13px 30px 13px 15px);
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    transition: border-color .3s $ease-out-quart;
    border: 1px solid var(--white-darker);
    border-radius: 4px;
    background-color: var(--white);
    outline: none;
    color: var(--black);
    font-size: rem(14px);
    text-align: left;
    cursor: pointer;

    &::after {
      @include position(absolute, calc(50% - 2px) 10px null null);
      @include triangle(10px, var(--grey-lightest), down);
      content: '';
      transition: border-top-color .3s $ease-out-quart, border-bottom-color .3s $ease-out-quart;
    }

    // &:focus,
    &:hover {
      // border-color: var(--primary);

      &::after {
        border-top-color: var(--primary);
      }
    }

    &-item {
      position: relative;
    }

    &-remove {
      @include position(absolute, 8px 5px null null);
      @include size(10px);
      outline: none;
      cursor: pointer;

      &::before,
      &::after {
        @include size(10px, 2px);
        @include position(absolute, calc(50% - 1px) null null 0);
        content: '';
        border-radius: 1px;
        background-color: $grey-darker;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }

      &:focus,
      &:hover {
        &::before,
        &::after {
          background-color: $primary;
        }
      }
    }
  }

  &-dropdown {
    @include position(absolute, 100% null null 0);
    @include size(100%, auto);
    border-top: 0;
    border-radius: 0 0 4px 4px;
    // border: 1px solid var(--grey-blue);
    border: 1px solid var(--white-darker);
    border-top: 0;
    // background-color: var(--grey-blue);
    background-color: var(--white);

    &::before {
      @include position(absolute, 0 null null 12px);
      @include size(calc(100% - 24px), 1px);
      content: '';
      opacity: .4;
      background-color: var(--grey-lightest);
    }
  }

  &-list {
    max-height: 180px;
    overflow: auto;
  }

  &-option {
    @include padding(8px 15px);
    display: block;
    position: relative;
    width: 100%;
    transition: color .3s $ease-out-quart;
    color: var(--black);
    text-align: left;
    cursor: pointer;
    outline: none;

    &:focus,
    &:hover {
      color: var(--primary);
    }

    &::before {
      @include position(absolute, 12px null null 0);
      @include size(15px, 2px);
      background-color: var(--primary);
    }

    &.is-selected {
      // @include padding(null null null 20px);
      background-color: var(--grey-blue);

      // &::before {
      //   content: '';
      // }
    }
  }
}
</style>
