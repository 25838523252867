<template>
   <a
    v-if="href"
    :class="`button ${theme}`"
    :target="target"
    :href="href"
    :type="type"
  >
    <slot></slot>
  </a>
  <button
    v-else
    :class="`button ${theme}`"
    :type="type"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    },
    href: {
      type: String
    },
    target: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  @include padding(11px 25px);
  display: inline-block;
  position: relative;
  transition: background-color .2s $ease-out-quart, border-color .2s $ease-out-quart, color .2s $ease-out-quart, opacity .3s $ease-out-quart;
  border: 1px solid var(--primary);
  border-radius: 4px;
  background-color: var(--primary);
  color: $white;
  font-family: $sans-serif;
  font-size: rem(14px);
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  outline: none;
  cursor: pointer;

  .svg {
    transition: fill .3s $ease-out-quart;
    fill: $white;
    // fill: var(--white);
  }

  &:hover {
    background-color: var(--white);
    color: var(--primary);

    .svg {
      fill: var(--primary);
    }
  }

  // Themes
  &.ghost {
    border-color: var(--primary);
    background-color: var(--white);
    color: var(--primary);

    .svg {
      fill: var(--primary);
    }

    &:hover {
      background-color: var(--primary);
      color: var(--white);

      .svg {
        fill: var(--white);
      }
    }
  }

  &.invisible {
    border-color: rgba($black, 0);
    background-color: rgba($black, 0);
    color: var(--grey-lighter);

    .svg {
      fill: var(--grey-lighter);
    }

    &:hover {
      .svg {
        fill: var(--black);
      }
    }
  }

  &.alert {
    border-color: var(--alert);
    background-color: var(--alert);

    &:hover {
      background-color: var(--white);
      color: var(--alert);

      .svg {
        fill: var(--alert);
      }
    }
  }

  &.icon {
    @include padding(null 0);
    @include size(40px);

    .svg {
      @include position(absolute, 50% null null 50%);
      transform: translate(-50%, -50%);
    }

    &.prev {
      .svg {
        margin-left: -1px;
      }
    }

    &.next {
      .svg {
        margin-left: 1px;
      }
    }
  }

  &.pill {
    @include padding(6px 12px 7px);
    border-radius: 15px;
    border: 1px solid var(--grey-lightest);
    background: none;
    color: var(--black);

    &:hover {
      .svg {
        fill: var(--black);
      }
    }

    .svg {
      @include margin(-2px null null 4px);
      display: inline-block;
      fill: var(--grey-lighter);
      transition: fill .3s $ease-out-quart;
      vertical-align: middle;
      cursor: pointer;
      pointer-events: auto;

      svg {
        display: inline-block;
      }
    }

    &-success {
      border-color: var(--success);
    }

    &-error {
      border-color: var(--alert);
    }
  }

  // Disabled
  &[disabled] {
    opacity: .3;
    pointer-events: none;
  }
}
</style>
