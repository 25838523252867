import router from '@/router';
import {
  getLawyer,
  saveLawyer,
  createLawyer,
  deleteLawyer,
  deleteLawyerPicture,
  changeLawyerPicture,
} from '@/data/calls';

const lawyer = {
  namespaced: true,
  state: {
    requests: 0,
    datas: {},
    startDatas: {}
  },
  mutations: {
    updateDataString(state, { value, type }) {
      state.datas[type] = value;
    },
    updateDataArray(state, { value, type }) {
      let index = -1;

      state.datas[type].map((item, i) => {
        if (item.name === value.name) {
          index = i;
        }
      });

      if (index >= 0) {
        state.datas[type].splice(index, 1);
      } else {
        state.datas[type].push(value);
      }
    },
    updateLoading(state, flag) {
      state.loading = flag;
    },
    updateRequests(state, desc = false) {
      if (desc) {
        state.requests--;
        return;
      }

      state.requests++;
    },
    setDatas(state, datas) {
      state.datas = datas;
    },
    setStartDatas(state, datas) {
      state.startDatas = datas;
    },
    addElement(state, { type, value }) {
      state.datas[type].push(value);
    },
    removeElement(state, { type, value }) {
      const index = state.datas[type].indexOf(value);

      if (index >= 0) {
        state.datas[type].splice(index, 1);
      }
    },
    reset(state) {
      const lawyer = {
        firstname: '',
        lastname: '',
        sex: '',
        email: '',
        is_tua_visible: false,
        verified: false,
        phone: '',
        mobile: '',
        fax: '',
        fees: '',
        business_hours: '',
        addresses: [],
        degrees: [],
        bars: [],
        languages: [],
        links: [],
        skills: [],
        string_skills: '',
        does_pro_deo: false
      };

      state.datas = JSON.parse(JSON.stringify(lawyer));
      state.startDatas = JSON.parse(JSON.stringify(lawyer));
    }
  },
  actions: {
    update({ state, commit } , { value, type }) {
      if (state.datas[type] !== undefined) {
        if (Array.isArray(state.datas[type])) {
          commit('updateDataArray', { value, type });
          return;
        }

        commit('updateDataString', { value, type });
        return
      }
    },
    async get({ commit }, { slug }) {
      commit('updateRequests');
      const request = await getLawyer({ slug });
      const data = request.data.data;

      if (request.status === 'success') {
        data.is_tua_visible = data.is_tua_visible === 1;
        data.verified = data.verified === 1;
        data.does_pro_deo = data.does_pro_deo === 1;
        data.profile_picture = null;
        data.languages.map(lang => {
          return {
            name: lang
          }
        });

        const parsedData = JSON.parse(JSON.stringify(request.data.data));
        const startData = JSON.parse(JSON.stringify(request.data.data));
        commit('setDatas', parsedData);
        commit('setStartDatas', startData);
      } else {
        console.log('error :', request.data);
      }
      
      commit('updateRequests', true);
    },
    async save({ state, commit, dispatch }) {
      commit('updateRequests');
      const request = await saveLawyer(state.datas, state.startDatas);
      let notif = null;

      if (request.status === 'success') {
        notif = {
          text: 'Changements sauvegardés',
          type: 'success'
        };
      } else {
        console.log('error :', request.data);
        notif = {
          text: 'Un problème est survenu lors de la sauvegarde',
          type: 'alert'
        };
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
    },
    async delete({ state, commit, dispatch }) {
      commit('updateRequests');
      const request = await deleteLawyer(state.datas.id);
      let notif = null

      if (request.status === 'success') {
        notif = {
          text: 'Avocat supprimé',
          type: 'success'
        };
        router.push({ name: 'LawyersListing' });
      } else {
        console.log('error :', request.data);
        notif = {
          text: 'Un problème est survenu lors de la suppression',
          type: 'alert'
        };
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
    },
    async create({ state, commit, dispatch }) {
      commit('updateRequests');
      const request = await createLawyer(state.datas);
      let notif = null;

      if (request.status === 'success') {
        const { data } = request.data;

        notif = {
          text: `${data.firstname} ${data.lastname} ajouté`,
          type: 'success'
        };

        router.push({ name: 'LawyersDetail', params: { slug: data.slug } });
      } else {
        console.log('error :', request.data);
        notif = {
          text: 'Un problème est survenu lors de la sauvegarde',
          type: 'alert'
        };
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
    },
    async uploadPicture({ state, commit, dispatch }, pic) {
      commit('updateRequests');
      const request = await changeLawyerPicture(pic, state.datas.id);

      if (request.status === 'success') {
        commit('updateDataString', {
          type: 'profile_picture_path',
          value: request.data
        });

        dispatch('notifications/addNotifs', [{
          text: 'Image ajoutée',
          type: 'success'
        }], { root: true });
      } else {
        console.log('error :', request.data);
        dispatch('notifications/addNotifs', [{
          text: 'Un problème est survenue lors de l\'upload',
          type: 'alert'
        }], { root: true });
      }

      commit('updateRequests', true);
    },
    async deletePicture({ commit, dispatch, state }) {
      commit('updateRequests');
      const request = await deleteLawyerPicture(state.datas.id);

      if (request.status === 'success') {
        commit('updateDataString', {
          type: 'profile_picture_path',
          value: request.data
        });

        dispatch('notifications/addNotifs', [{
          text: 'Image supprimée',
          type: 'success'
        }], { root: true });
      } else {
        console.log('error :', request.data);
        dispatch('notifications/addNotifs', [{
          text: 'Un problème est survenue lors de la suppression',
          type: 'alert'
        }], { root: true });
      }

      commit('updateRequests', true);
    }
  }
}

export default lawyer;
