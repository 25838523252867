<template>
  <div class="view">
    <div class="view-header">
      <topbar>
        <template #title>
          <h1 v-if="datas">{{ datas.content_fr }}</h1>
        </template>

        <template #actions>
          <btn
            :theme="'icon'"
            :disabled="loading"
            @click="onSave"
          >
            <sprite
              :type="'save'"
              :width="20"
              :height="20"
            />
          </btn>

          <btn
            :disabled="loading || create"
            :theme="'icon ghost'"
            @click="onToggleDelete"
          >
            <sprite :type="'less'" />
          </btn>
        </template>
      </topbar>
    </div>

    <div class="view-content">
      <div class="view-content-wrapper">
        <fields v-if="datas">
          <div class="fields-row" data-column="1">
            <!-- Content FR -->
            <div class="fields-field">
              <inputs
                :id="'content-fr'"
                :value="datas.content_fr"
                :label="$t('labels.content_fr')"
                :required="true"
                @input="onInput($event, 'content_fr')"
              />
            </div>

            <!-- Content EN -->
            <div class="fields-field">
              <inputs
                :id="'content-en'"
                :value="datas.content_en"
                :label="$t('labels.content_en')"
                :required="true"
                @input="onInput($event, 'content_en')"
              />
            </div>

            <!-- Content NL -->
            <div class="fields-field">
              <inputs
                :id="'content-nl'"
                :value="datas.content_nl"
                :label="$t('labels.content_nl')"
                :required="true"
                @input="onInput($event, 'content_nl')"
              />
            </div>
          </div>

          <div class="fields-row" data-column="3">
            <div class="fields-field">
              <inputs
                :id="'tag-primary'"
                :value="datas.algolia_primary"
                :label="$t('labels.tag_primary')"
                @input="onInput($event, 'algolia_primary')"
              />
            </div>
            <div class="fields-field">
              <inputs
                :id="'tag-secondary'"
                :value="datas.algolia_secondary"
                :label="$t('labels.tag_secondary')"
                @input="onInput($event, 'algolia_secondary')"
              />
            </div>

            <!-- Penal -->
            <div class="fields-field">
              <switcher
                :id="'penal'"
                :checked="penal"
                :label="$t('labels.penal')"
                @change="onInput($event, 'penal')"
              />
            </div>
          </div>

          <div class="fields-row" data-column="2">
            <div class="fields-field">
              <search
                :id="'skills-search'"
                :type="'skills'"
                :position="'top'"
                :selecteds="selectedSkills"
                @click="onSkillSelect"
              />
            </div>
            <div class="fields-field">
              <pills
                :property="'name'"
                :els="datas.skills"
                @remove="onRemoveSkill"
              />
            </div>
          </div>
        </fields>
      </div>
      <loader v-show="loading"/>
    </div>

    <!-- Popup -->
    <popup
      v-if="showPopupDelete"
      :title="$t('lawcases.delete.title.singular')"
      @close="onCloseDelete"
    >
      <p class="popup-text">{{ $t('lawcases.delete.text') }}</p>
      <div class="popup-form">
        <inputs
          :id="'lawcase-delete'"
          :value="deleteInput"
          :error="deleteError"
          @input="onDeleteInput"
        />
        <btn
          :theme="'ghost'"
          :disabled="deleteInput.length === 0"
          @click="onDelete"
        >
          {{ $t('actions.delete') }}
        </btn>
      </div>
      <div
        v-if="datas.has_associated_forms"
        class="popup-footer"
      >
        <checkbox
          :id="'delete-associated-form'"
          :label="'Supprimer le(s) formulaire(s) associé(s)'"
          :checked="deleteForm"
          @change="onToggleDeleteForm"
        />
      </div>
    </popup>
  </div>
</template>

<script>
// Components
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';
import Inputs from '@/components/components/Inputs';
import Loader from '@/components/components/Loader';
import Switcher from '@/components/components/Switcher';
import Search from '@/components/modules/Search';
import Pills from '@/components/modules/Pills';
import Fields from '@/components/partials/Fields';
import Topbar from '@/components/partials/Topbar';
import Popup from '@/components/partials/Popup';
import Checkbox from '@/components/components/Checkbox.vue';

export default {
  components: {
    Btn, Inputs, Loader, Sprite, Switcher,
    Search, Pills,
    Fields, Topbar, Popup,
    Checkbox
  },
  data() {
    return {
      showPopupDelete: false,
      deleteInput: '',
      deleteError: false,
      deleteForm: false
    }
  },
  computed: {
    datas() {
      return this.$store.state.lawcase.datas;
    },
    loading() {
      return this.$store.state.lawcase.requests > 0;
    },
    create() {
      return this.$route.name === 'LawcasesCreate';
    },
    selectedSkills() {
      return this.$store.state.lawcase.datas.skills.map(s => s.id);
    },
    penal() {
      return this.$store.state.lawcase.datas.penal === 1;
    }
  },
  beforeMount() {
    if (this.create) {
      this.$store.commit('lawcase/reset');
      return;
    }

    const { id } = this.$route.params;
    this.$store.dispatch('lawcase/get', { id });
  },
  watch: {
    $route(to) {
      if (to.name === 'LawcasesDetail') {
        const { id } = this.$route.params;
        this.$store.dispatch('lawcase/get', { id });
      }
    }
  },
  methods: {
    // Form
    onInput(value, type) {
      if (type === 'penal') {
        this.$store.dispatch('lawcase/update', {
          value: value ? 1 : 0,
          type
        });
        return;
      }
      this.$store.dispatch('lawcase/update', { value, type });
    },
    onSkillSelect(e) {
      const skill = e;

      this.$store.commit('lawcase/updateDataArray', {
        value: skill,
        type: 'skills',
        property: 'id'
      });
    },
    onRemoveSkill(e) {
      this.$store.commit('lawcase/updateDataArray', {
        value: e,
        type: 'skills',
        property: 'id'
      });
    },
    // Actions
    onSave() {
      if (this.create) {
        this.$store.dispatch('lawcase/create');
        return;
      }

      this.$store.dispatch('lawcase/save');
    },
    // Delete
    onCloseDelete() {
      this.showPopupDelete = false;
      this.deleteInput = '';
      this.deleteError = false;
      this.deleteForm = false;
    },
    onDeleteInput(value) {
      this.deleteInput = value;
    },
    onDelete() {
      this.$store.dispatch('lawcase/delete', this.deleteForm);
    },
    onToggleDelete() {
      this.showPopupDelete = !this.showPopupDelete;
    },
    onToggleDeleteForm() {
      this.deleteForm = !this.deleteForm;
    }
  }
}
</script>
