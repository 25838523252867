<template>
  <div class="page">
    <img
      :src="`${publicPath}assets/symplicy.svg`"
      class="page-logo"
      alt="Symplicy"
    >
    <div class="page-center">
      <form
        class="page-form"
        action="#"
        @submit.prevent="onSubmit"
      >
        <inputs
          :id="'login-username'"
          :label="'Adresse email'"
          :type="'email'"
          :autocomplete="'email'"
          @input="onInput($event, 'email')"
        />
        <inputs
          :id="'login-password'"
          :label="'Mot de passe'"
          :type="'password'"
          :autocomplete="'current-password'"
          @input="onInput($event, 'password')"
        />
        <btn type="submit">Se connecter</btn>
      </form>
      <loader v-if="waiting" />
    </div>
  </div>
</template>

<script>
import Btn from '@/components/components/Btn';
import Inputs from '@/components/components/Inputs';
import Loader from '@/components/components/Loader';

export default {
  components: {
    Btn,
    Inputs,
    Loader
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      email: '',
      password: ''
    }
  },
  computed: {
    waiting() {
      return this.$store.state.login.requests > 0;
    }
  },
  beforeMount() {
    const darkmode = window.localStorage.getItem('darkmode');

    if (darkmode && darkmode === 'true') {
      this.$store.commit('setDarkmode', true);
    }
  },
  methods: {
    onInput(value, type) {
      this[type] = value;
    },
    onSubmit() {
      // const darkmode = window.localStorage.getItem('darkmode');
      // if (darkmode && darkmode === 'true') {
      //   this.$store.commit('setDarkmode', true);
      // }

      this.$store.dispatch('login/authenticate', {
        email: this.email,
        password: this.password,
        redirection: { name: 'LawyersListing' }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  @include position(absolute, 0 null null 0);
  @include size(100%, 100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white-dark);

  &-logo {
    @include margin(-32px null 24px);
    width: 64px;
  }

  &-center {
    position: relative;
    width: 100%;
    max-width: 348px;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: 5px 5px 10px rgba($black-light, .1);
    overflow: hidden;

    .loader {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      background-color: rgba(var(--white), .9);
    }
  }

  &-form {
    @include padding(14px 24px 24px);
    display: flex;
    flex-direction: column;
    align-items: center;

    .input {
      + .input {
        @include margin(8px null null);
      }
    }

    .button {
      @include margin(24px null null);
      @include padding(12px null 13px);
      width: 100%;
    }
  }
}
</style>
