<template>
  <div
    class="customization"
  >
    <div class="customization-sidebar">
      <div class="customization-sidebar-header">
        <button
          class="customization-sidebar-close"
          @click="$emit('close')"
        />

        <button
          class="customization-sidebar-reset"
          @click="onReset()"
        >
          <sprite
            :type="'reset'"
            :width="20"
            :height="20"
          />
        </button>
      </div>

      <div class="customization-wrapper">
        <div
          v-for="type in types"
          :key="`customization-${type.id}`"
          class="customization-panel"
        >
          <div class="customization-panel-header">
            <h2 class="customization-panel-title">{{ type.label }}</h2>

            <button
              class="customization-panel-reset"
              @click="onReset(type.id)"
            >
              <sprite
                :type="'reset'"
                :width="16"
                :height="16"
              />
            </button>
          </div>

          <div :class="['customization-panel-fields', { 'cols-2': type.id === 'color' }]">
            <div
              v-for="field in type.fields"
              :key="`customization-${type.id}-${field.id}`"
              class="customization-panel-field"
            >
              <div class="customization-panel-field-header">
                <label :for="`customization-${type.id}-${field.type}-${field.id}`">
                  {{ field.label }}
                </label>

                <div
                  v-if="field.type !== 'select' && field.type !== 'color'"
                  class="customization-panel-field-input"
                >
                  <span v-if="field.type === 'color'">#</span>
                  <input
                    :type="field.type === 'color' ? 'text' : 'number'"
                    :value="field.type === 'color' ? styles[type.id][field.id].replace('#', '') : styles[type.id][field.id]"
                    :min="field.min"
                    :max="field.max"
                    @input="onChange($event, type.id, field.id)"
                  />
                  <span v-if="!field.type.match(/color/g)">px</span>
                </div>
              </div>

              <select
                v-if="field.type === 'select'"
                :id="`customization-${type.id}-${field.id}`"
                @input="onChange($event, type.id, field.id)"
              >
                <option
                  v-for="option in field.options"
                  :key="`${type.id}-${field.id}-${option.id}`"
                  :value="option.value"
                  :selected="styles[type.id][field.id] === option.value"
                >
                  {{ option.label }}
                </option>
              </select>
              <input
                v-else
                :id="`customization-${type.id}-${field.type}-${field.id}`"
                :type="field.type"
                :min="field.min"
                :max="field.max"
                :value="styles[type.id][field.id]"
                @input="onChange($event, type.id, field.id)"
              />
            </div>
          </div>
        </div>

        <btn @click="onSave">
          {{ $t('actions.save') }}
        </btn>
      </div>
    </div>

    <div class="customization-popup">
      <casus
        ref="casus"
        @mounted="setCSS"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';
import Casus from '@/components/partials/Casus';

import questions from '@/data/static/form-example.json';
import customization from '@/data/static/customization';

export default {
  components: {
    Btn, Sprite,
    Casus
  },
  props: {
    currents: {
      type: String
    }
  },
  data() {
    return {
      styles: _.cloneDeep(customization.defaults),
      defaults: _.cloneDeep(customization.defaults),
      types: customization.types
    }
  },
  beforeMount() {
    this.updateFromString(this.currents);
    this.$store.dispatch('casus/updateQuestions', questions);
  },
  methods: {
    setCSS() {
      Object.keys(this.styles).forEach(type => {
        Object.keys(this.styles[type]).forEach(property => {
          this.setCSSVariable(type, property, this.styles[type][property]);
        });
      });
    },
    setCSSVariable(type, property, value) {
      let val = value;
      switch (type) {
        case 'color':
          val = this.hexToRgb(value);
          break;
        case 'font':
          val = value;
          break;
        default:
          val = `${value}px`;
          break;
      }

      this.$refs.casus.$el.style.setProperty(`--symplicy-${type}-${property}`, val);
    },
    updateFromString(styles) {
      if (!styles || styles.length < 10) {
        return;
      }

      const stylesObj = JSON.parse(styles);
      const test = {};

      Object.keys(stylesObj).forEach(key => {
        const keys = key.split('-');

        if (!test[keys[0]]) {
          test[keys[0]] = {};
        }

        if (keys.length === 3) {
          test[keys[0]][`${keys[1]}-${keys[2]}`] = stylesObj[key];
        } else {
          test[keys[0]][keys[1]] = stylesObj[key];
        }
      });

      this.updateStyles(test);
    },
    updateStyles(styles) {
      Object.keys(styles).forEach(type => {
        Object.keys(styles[type]).forEach(property => {
          let value = null;

          switch (type) {
            case 'color':
              const rgb = styles[type][property].split(',');
              const color = {
                r: parseInt(rgb[0], 10),
                g: parseInt(rgb[1], 10),
                b: parseInt(rgb[2], 10)
              };

              value = this.rgbToHex(color.r, color.g, color.b);
              break;
            case 'spacing':
            case 'border':
              value = parseInt(styles[type][property].replace('px', ''), 10);
              break;
            default:
              value = styles[type][property];
              break;
          }

          this.styles[type][property] = value;
        });
      });
    },

    // Events
    onChange(event, type, field) {
      const { value } = event.currentTarget;
      this.styles[type][field] = value;
      this.setCSSVariable(type, field, value);
    },
    onSave() {
      const styles = {};

      Object.keys(this.styles).forEach(type => {
        Object.keys(this.styles[type]).forEach(property => {
          switch (type) {
            case 'color':
              styles[`${type}-${property}`] = this.hexToRgb(this.styles[type][property]);
              break;
            case 'font':
              styles[`${type}-${property}`] = this.styles[type][property];
              break;
            default:
              styles[`${type}-${property}`] = `${this.styles[type][property]}px`;
              break;
          }
        });
      });

      const str = JSON.stringify(styles);
      this.$emit('save',str );
    },
    onReset(field) {
      if (field) {
         Object.keys(this.styles[field]).forEach(property => {
          this.styles[field][property] = customization.defaults[field][property];
        });

        this.setCSS();
        return;
      }

      Object.keys(this.styles).forEach(type => {
        Object.keys(this.styles[type]).forEach(property => {
          this.styles[type][property] = customization.defaults[type][property];
        });
      });

      this.setCSS();
    },
  
    // Helpers
    hexToRgb(hex, obj = false) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

      if (!result) {
        return null;
      }

      return obj ?
      {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
      : `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}`;
    },
    rgbToHex(r, g, b) {
      return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    },
    componentToHex(c) {
      const hex = c.toString(16);
      return hex.length == 1 ? '0' + hex : hex;
    },
  }
};
</script>

<style lang="scss" scoped>
.customization {
  @include position(fixed, 0 null null 0);
  @include size(100%);
  display: flex;
  z-index: 100;
  // background-color: rgba($black, .5);

  // Popup
  // -----------------------------------------------------------------------------
  &-popup {
    position: relative;
    flex-grow: 1;
    height: 100%;
  }

  // Sidebar
  // -----------------------------------------------------------------------------
  &-sidebar {
    @include size(400px, 100%);
    border-right: 1px solid var(--grey-lightest);
    background-color: var(--white);

    .button {
      margin-top: 24px;
    }

    &-header {
      @include size(100%, auto);
      @include padding(15px 28px);
      display: flex;
      justify-content: space-between;
      background-color: var(--white);
    }

    &-reset {
      @include padding(5px);
      cursor: pointer;

      &:focus,
      &:hover {
        .svg {
          fill: var(--primary);
        }
      }

      .svg {
        fill: var(--grey-lighter);
      }
    }

    &-close {
      @include size(30px);
      position: relative;
      cursor: pointer;

      &:focus,
      &:hover {
        &::before,
        &::after {
          background-color: var(--primary);
        }
      }

      &::before,
      &::after {
        @include position(absolute, calc(50% - 1px) null null calc(50% - 12px));
        @include size(24px, 2px);
        content: '';
        transition: background-color .3s $ease-out-quart;
        background-color: var(--black);
      }

      &::before { transform: rotate(-45deg); }
      &::after { transform: rotate(45deg); }

      + .customization-panel {
        margin-top: 0;
      }
    }
  }

  &-wrapper {
    @include padding(null 32px 44px);
    max-height: calc(100% - 60px);
    overflow: auto;
  }

  &-panel {
    &:not(:first-child) {
      @include margin(48px null null);
    }

    &-header {
      display: flex;
      align-items: flex-end;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid var(--grey-lightest);
    }

    &-title {
      @include padding(null null 10px);
      font-size: rem(18px);
      font-weight: 600;
      line-height: 1;
      text-align: left;
    }

    &-reset {
      @include padding(8px);
      margin-right: -8px;
      cursor: pointer;

      &:focus,
      &:hover {
        .svg {
          fill: var(--primary);
        }
      }

      .svg {
        fill: var(--grey-lighter);
      }
    }

    &-fields {
      display: flex;
      flex-direction: column;

      &.cols-2 {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .customization-panel-field {
          width: calc(50% - 12px);
        }
      }
    }

    &-field {
      margin-top: 16px;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 8px;
      }

      label {
        display: block;
      }

      &-input {
        display: block;
        border-radius: 0;

        span {
          &:first-child {
            margin-right: 5px;

            + input {
              text-align: left;
            }
          }

          &:last-child {
            margin-left: 5px;
          }
        }

        input {
          border: 1px solid var(--grey-lightest);
          max-width: 50px;
          text-align: right;
          appearance: none;
          border: 0;
          border-bottom: 1px solid $grey-lighter;
          outline: none;
          text-align: center;
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
          -webkit-appearance: none;
        }
      }


      > input[type="color"] {
        display: block;
        padding: 0;
        width: 100%;
        height: 40px;
        border: 0;
        appearance: none;
        outline: none;
        cursor: pointer;

        &::-webkit-color-swatch-wrapper {
          padding: 0;
          border: 0;
        }

        &::-webkit-color-swatch {
          border: 1px solid var(--grey-lightest);
        }
      }

      > input[type="range"] {
        display: block;
        appearance: none;
        width: 100%;
        background: transparent;
        outline: none;
        padding-top: 7px;
        border: 0;

        // Thumb
        &::-moz-range-thumb {
          @include size(20px);
          appearance: none;
          margin-top: -7px;
          border: 0;
          border-radius: 50%;
          background: var(--primary);
          cursor: pointer;
        }

        &::-webkit-slider-thumb {
          @include size(20px);
          margin-top: -7px;
          border: 0;
          border-radius: 50%;
          background: var(--primary);
          cursor: pointer;
          appearance: none;
        }

        // Track
        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 6px;
          cursor: pointer;
          background: var(--grey-lightest);
        }
      }

      > select {
        @include padding(5px 0);
        display: block;
        width: 100%;
        margin: 0;
        border: 0;
        border-bottom: 1px solid var(--grey-lightest);
        border-radius: 0;
        background: none;
        cursor: pointer;
        appearance: none;
        outline: none;
        color: var(--black);

        &:focus {
          border-bottom-color: var(--primary);
        }
      }
    }
  }
}
</style>
