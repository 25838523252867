import router from '@/router';
import {
  getClient,
  saveClient,
  createClient,
  deleteClient,
  createAccounts
} from '@/data/calls';

const client = {
  namespaced: true,
  state: {
    requests: 0,
    datas: null,
    startDatas: {},
    search: {
      requests: 0,
      lawyers: []
    }
  },
  mutations: {
    updateDataString(state, { value, type }) {
      state.datas[type] = value;
    },
    updateDataArray(state, { value, type, property = 'name' }) {
      let index = -1;

      state.datas[type].map((item, i) => {
        if (item[property] === value[property]) {
          index = i;
        }
      });

      if (index >= 0) {
        state.datas[type].splice(index, 1);
      } else {
        state.datas[type].push(value);
      }
    },
    updateLoading(state, flag) {
      state.loading = flag;
    },
    updateRequests(state, desc = false) {
      if (desc) {
        state.requests--;
        return;
      }

      state.requests++;
    },
    setDatas(state, datas) {
      state.datas = datas;
    },
    setStartDatas(state, datas) {
      state.startDatas = datas;
    },
    addElement(state, { type, value }) {
      state.datas[type].push(value);
    },
    removeElement(state, { type, value }) {
      const index = state.datas[type].indexOf(value);

      if (index >= 0) {
        state.datas[type].splice(index, 1);
      }
    },
    reset(state) {
      const client = {
        name: '',
        email: '',
        email_box: '',
        mail_system: 1,
        subscribed_until: null,
        lawyers: [],
        custom_style_iframe_css: '',
        custom_style_popup_css: ''
      };

      state.datas = JSON.parse(JSON.stringify(client));
      state.startDatas = JSON.parse(JSON.stringify(client));
    }
  },
  actions: {
    update({ state, commit }, { value, type }) {
      if (state.datas[type] !== undefined) {
        if (Array.isArray(state.datas[type])) {
          commit('updateDataArray', { value, type });
          return;
        }

        commit('updateDataString', { value, type });
        return;
      }
    },
    async get({ commit }, { uuid }) {
      commit('updateRequests');
      const request = await getClient({ uuid });
      const data = request.data.data;

      if (request.status === 'success') {
        const parsedData = JSON.parse(JSON.stringify(request.data.data));
        const startData = JSON.parse(JSON.stringify(request.data.data));
        commit('setDatas', parsedData);
        commit('setStartDatas', startData);
      } else {
        console.log('error :', data);
      }

      commit('updateRequests', true);
    },
    async save({ state, commit, dispatch }) {
      commit('updateRequests');
      const request = await saveClient(state.datas);
      let notif = null;

      if (request.status === 'success') {
        notif = {
          text: 'Changements sauvegardés',
          type: 'success'
        };
      } else {
        console.log('error :', request.data);
        notif = {
          text: 'Un problème est survenu lors de la sauvegarde',
          type: 'alert'
        };
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
    },
    async create({ state, commit, dispatch }) {
      commit('updateRequests');
      const request = await createClient(state.datas);
      let notif = null;

      if (request.status === 'success') {
        const { data } = request.data;

        notif = {
          text: `${data.name} crée`,
          type: 'success'
        };

        router.push({ name: 'ClientsDetail', params: { uuid: data.uuid } })
      } else {
        console.log('error :', request.data);
        notif = {
          text: 'Un problème est survenu lors de la création',
          type: 'alert'
        };
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
    },
    async delete({ state, commit, dispatch }) {
      commit('updateRequests');
      const request = await deleteClient(state.datas.uuid);
      let notif = null

      if (request.status === 'success') {
        notif = {
          text: 'Client supprimé',
          type: 'success'
        };
        router.push({ name: 'ClientsListing' });
      } else {
        notif = {
          text: 'Un problème est survenu lors de la suppression',
          type: 'alert'
        };
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
    },
    async createAccounts({ state, commit, dispatch }) {
      commit('updateRequests');
      // eslint-disable-next-line
      const request = await createAccounts(state.datas.uuid);
      let notif = null;

      if (request.status === 'success') {
        notif = {
          text: 'Invitations envoyées',
          type: 'success'
        };

        const { uuid } = state.datas;
        dispatch('get', { uuid });
      } else {
        notif = {
          text: 'Un problème est survenu lors de la requête',
          type: 'alert'
        };
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
      return true;
    },
  }
}

export default client;
