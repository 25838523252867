<template>
  <div  class="popup">
    <div class="popup-container">
      <div class="popup-header">
        <h2 v-html="title" class="popup-title" />

        <button @click="$emit('close')" class="popup-close">
          <sprite :type="'close'" />
          <!-- <span class="u-visually-hidden">{{ $t('popup.close') }}</span> -->
        </button>
      </div>

      <div class="popup-content">
        <slot />
      </div>
    </div>

    <div @click="$emit('close')" class="popup-backdrop" />
  </div>
</template>

<script>
import Sprite from '@/components/components/Sprite.vue';

export default {
  components: {
    Sprite
  },
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .popup {
    @include position(fixed, 0 null null 0);
    @include size(100%);
    z-index: 1001;

    // Container
    // -----------------------------------------------------------------------------
    &-container {
      @include position(absolute, 50% 50% null null);
      @include size(calc(100% - 32px), auto);
      display: flex;
      flex-direction: column;
      z-index: 1;
      transform: translate(50%, -50%);
      max-width: 686px;
      max-height: calc(100% - 32px);
      border-radius: 10px;
      border: 1px solid var(--grey-lightest);
      overflow: hidden;
      background-color: var(--white);

      @include mq($from: 'm') {
        max-height: calc(100% - 100px);
      }
    }

    // Header
    // -----------------------------------------------------------------------------
    &-header {
      @include padding(16px null);
      position: relative;
      border-bottom: 1px solid var(--grey-lighter);
    }

    &-title {
      @include margin(null auto);
      color: var(--black-light);
      font-size: rem(20px);
      line-height: (24 / 16);
      text-align: center;
    }

    &-close {
      @include position(absolute, 20px 16px null null);
      outline: none;
      cursor: pointer;

      &:focus,
      &:hover {
        .svg {
          fill: var(--primary);
        }
      }

      .svg {
        transition: fill .3s $ease-out-quart;
        fill: var(--grey-light);
      }
    }

    // Content
    // -----------------------------------------------------------------------------
    &-content {
      @include padding(32px);
      overflow: auto;

      @include mq($until: 'm') {
        @include padding(null 16px);
      }

      > .button {
        @include margin(null auto);
        display: block;
      }
    }

    &-text {
      @include margin(null null 24px);
      color: var(--black-light);
      text-align: center;
      font-size: rem(16px);
      line-height: 1.5;
    }

    &-form {
      @include margin(null auto);
      display: flex;
      align-items: flex-end;
      max-width: 500px;

      .button {
        @include padding(13px null);
      }
    }

    &-footer {
      @include margin(null auto null);
      display: flex;
      max-width: 500px;

      button {
        @include padding(8px null);
        cursor: pointer;
        outline: none;
        transition: color .3s $ease-out-quart;
        color: $primary;
        font-size: rem(14px);
        font-weight: 700;

        &:hover {
          color: var(--black);
        }

        + button {
          @include margin(null null null 16px);
        }
      }
    }

    // Backdrop
    // -----------------------------------------------------------------------------
    &-backdrop {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      z-index: 0;
      opacity: .7;
      background-color: $black;
      // background-color: var(--black);
      backdrop-filter: blur(13px);
      backface-visibility: hidden;
    }
  }
</style>

<style lang="scss">
.popup {
  &-form {
    .button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .input {
      // min-width: 170px;

      &:not(:first-child) {
        input {
          border-radius: 0;
        }
      }

      &:first-child {
        input {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
</style>
