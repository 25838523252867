import Vue from 'vue';
import router from '@/router';
import { getRandomString } from '@/helpers';
import Formatters from '@/data/formatters';
import {
  getForm,
  createForm,
  updateForm,
  deleteForm
} from '@/data/calls';

const form = {
  namespaced: true,
  state: {
    requests: 0,
    datas: {},
    startDatas: {},
    search: {
      requests: 0,
      lawyers: []
    },
    categories: []
  },
  mutations: {
    updateData(state, { value, type }) {
      state.datas[type] = value;
    },
    updateMeta(state, { value, type }) {
      if (type === 'tags' || type === 'tagToAdd') {
        Vue.set(state.datas.meta, type, value);
        return;
      }

      state.datas.meta[type] = value;
    },
    updateRequests(state, desc = false) {
      if (desc) {
        state.requests--;
        return;
      }

      state.requests++;
    },
    reset(state) {
      const form = {
        meta: {
          name: '',
          uuid: '',
          case: null,
          case_id: null,
          client: null,
          client_uuid: ''
        },
        data: []
      };

      state.datas = JSON.parse(JSON.stringify(form));
      state.startDatas = JSON.parse(JSON.stringify(form));
    },
    setDatas(state, datas) {
      const data = Formatters.questions.from(datas);

      console.log('state.datas.data before :', state.datas.data);
      state.datas.data = JSON.parse(JSON.stringify(data));
      state.datas.startDatas = JSON.parse(JSON.stringify(data));
      console.log('state.datas.data :', state.datas.data);
    },
    // Questions
    addQuestion(state, index) {
      const q = {
        title: { fr: '', nl: '', en: '' },
        id: getRandomString(12),
        description: { fr: '', nl: '', en: '' },
        type: 'text',
        answers: [],
        visibility: { all: true, rules: [] },
      };

      const answer = {
        label: { fr: '', nl: '', en: '' },
        value: getRandomString()
      };

      q.answers.push(answer);

      state.datas.data.splice(index, 0, q);
    },
    deleteQuestion(state, index) {
      state.datas.data.splice(index, 1);
    },
    updateQuestion(state, { value, index, type }) {
      state.datas.data[index][type] = value;
    },
    updateQuestionTitle(state, { value, index, lang }) {
      state.datas.data[index].title[lang] = value;
    },
    updateQuestionDescription(state, { value, index, lang }) {
      state.datas.data[index].description[lang] = value;
    },
    updateQuestionType(state, { value, index }) {
      state.datas.data[index].type = value;
    },
    updateQuestionAnswerLabel(state, { value, indexQ, indexA, lang }) {
      state.datas.data[indexQ].answers[indexA].label[lang] = value;
    },
    addAnswer(state, index) {
      const answer = {
        label: { fr: '', nl: '', en: '' },
        value: getRandomString(),
        tagToAdd: '',
        tags: {}
      };

      state.datas.data[index].answers.push(answer);
    },
    deleteAnswer(state, { indexA, indexQ }) {
      state.datas.data[indexQ].answers.splice(indexA, 1);
    },
    inputTag(state, { indexA, indexQ, value }) {
      state.datas.data[indexQ].answers[indexA].tagToAdd = value;
    },
    addTag(state, { indexA, indexQ }) {
      const tag = state.datas.data[indexQ].answers[indexA].tagToAdd;

      if (tag && !state.datas.data[indexQ].answers[indexA].tags[tag]) {
        Vue.set(state.datas.data[indexQ].answers[indexA].tags, tag, { type: 'add' });
      }
    },
    removeTag(state, { indexA, indexQ, tag }) {
      Vue.delete(state.datas.data[indexQ].answers[indexA].tags, tag);
    },
    toggleTags(state, { indexA, indexQ }) {
      state.datas.data[indexQ].answers[indexA].tagsVisible = !state.datas.data[indexQ].answers[indexA].tagsVisible;
    },
    updateTagType(state, { indexA, indexQ, tag }) {
      if (state.datas.data[indexQ].answers[indexA].tags[tag].type === 'add') {
        state.datas.data[indexQ].answers[indexA].tags[tag].type = 'remove';
        return;
      }

      state.datas.data[indexQ].answers[indexA].tags[tag].type = 'add';
    },
    inputFormTag(state, { value }) {
      state.datas.meta.tagToAdd = value;
    },
    addFormTag(state) {
      const tag = state.datas.meta.tagToAdd;

      if (tag && !state.datas.meta.tags[tag]) {
        Vue.set(state.datas.meta.tags, tag, { type: 'add' });
      }
    },
    removeFormTag(state, { tag }) {
      Vue.delete(state.datas.meta.tags, tag);
    },
    updateFormTagType(state, { tag }) {
      if (state.datas.meta.tags[tag].type === 'add') {
        state.datas.meta.tags[tag].type = 'remove';
        return;
      }

      state.datas.meta.tags[tag].type = 'add';
    },
    addRule(state, indexQ) {
      const questions = state.datas.data.filter((q, i) => (q.type === 'checkbox' || q.type === 'radio') && i < indexQ);

      const rule = {
        question: questions[questions.length - 1].id,
        values: []
      };

      state.datas.data[indexQ].visibility.rules.push(rule);
    },
    updateRule(state, { indexQ, prevQuestion, newQuestion }) {
      let index = null;
      state.datas.data[indexQ].visibility.rules.map((r, i) => {
        if (r.question === prevQuestion) {
          index = i;
        }
      });

      state.datas.data[indexQ].visibility.rules[index].question = newQuestion;
      state.datas.data[indexQ].visibility.rules[index].values = [];
    },
    updateRuleAnswer(state, { indexQ, indexR, value }) {
      if (state.datas.data[indexQ].visibility.rules[indexR].values.includes(value)) {
        const indexOf = state.datas.data[indexQ].visibility.rules[indexR].values.indexOf(value);
        state.datas.data[indexQ].visibility.rules[indexR].values.splice(indexOf, 1);
        return;
      }

      state.datas.data[indexQ].visibility.rules[indexR].values.push(value);
    },
    deleteRule(state, { indexQ, indexR }) {
      state.datas.data[indexQ].visibility.rules.splice(indexR, 1);
    }
  },
  actions: {
    update({ state, commit }, { value, type }) {
      if (state.datas[type] !== undefined) {
        if (Array.isArray(state.datas[type])) {
          commit('updateDataArray', { value, type });
          return;
        }

        commit('updateDataString', { value, type });
        return;
      }
    },
    async get({ commit }, { uuid }) {
      commit('updateRequests');
      const request = await getForm({ uuid });

      if (request.status === 'success') {
        const { content } = request.data;
        const { meta, data } = content;

        meta.tagToAdd = '';

        if (!meta.tags) {
          meta.tags = {};
        } else if (Array.isArray(meta.tags)) {
          const tags = {};
          meta.tags.map(m => {
            tags[m] = 'add'
          });
          meta.tags = tags;
        }
        
        const parsedMeta = JSON.parse(JSON.stringify(meta));
        const parsedData = JSON.parse(JSON.stringify(data));

        commit('updateMeta', { type: 'case', value: parsedMeta.case });
        commit('updateMeta', { type: 'case_id', value: parsedMeta.case_id });
        commit('updateMeta', { type: 'name', value: parsedMeta.name });
        commit('updateMeta', { type: 'client', value: parsedMeta.client });
        commit('updateMeta', { type: 'client_uuid', value: parsedMeta.client_uuid });
        commit('updateMeta', { type: 'uuid', value: parsedMeta.uuid });
        commit('updateMeta', { type: 'path', value: parsedMeta.path });
        commit('updateMeta', { type: 'tags', value: parsedMeta.tags });

        commit('updateData', {
          value: parsedData,
          type: 'data'
        });
      } else {
        console.log('error :', request.error);
        console.log('error :', request.data);
      }

      commit('updateRequests', true);
    },
    async save({ state, commit, dispatch }) {
      commit('updateRequests');
      const request = await updateForm({
        uuid: state.datas.meta.uuid,
        meta: state.datas.meta,
        data: state.datas.data
      });

      let notif = null;

      if (request.status === 'success') {
        notif = {
          text: 'Changements sauvegardés',
          type: 'success'
        };
        dispatch('get', { uuid: state.datas.meta.uuid });
      } else {
        notif = {
          text: 'Un problème est survenu lors de la création',
          type: 'alert'
        };
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
    },
    async create({ state, commit , dispatch}) {
      commit('updateRequests');
      const request = await createForm({
        meta: state.datas.meta,
        data: state.datas.data
      });

      let notif = null;

      if (request.status === 'success') {
        const { data } = request.data;

        notif = {
          text: `Formulaire '${data.name}' crée`,
          type: 'success'
        };

        router.push({ name: 'FormsDetail', params: { uuid: data.uuid } })
      } else {
        if (request.status === 480) {
          notif = {
            text: request.data,
            type: 'alert'
          };
        } else {
          notif = {
            text: 'Un problème est survenu lors de la création',
            type: 'alert'
          };
        }
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
    },
    async delete({ state, commit, dispatch }) {
      commit('updateRequests');
      const request = await deleteForm(state.datas.meta.uuid);
      let notif = null

      if (request.status === 'success') {
        notif = {
          text: 'Formulaire supprimé',
          type: 'success'
        };
        router.push({ name: 'FormsListing' });
      } else {
        console.log('error :', request.data);
        notif = {
          text: 'Un problème est survenu lors de la suppression',
          type: 'alert'
        };
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
    }
  }
}

export default form;
