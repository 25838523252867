<template>
  <div class="view">
    <div class="view-header">
      <topbar>
        <template #title>
          <h1 v-if="datas">{{ datas.description_fr }}</h1>
        </template>

        <template #actions>
          <btn
            :theme="'icon'"
            :disabled="loading"
            @click="onSave"
          >
            <sprite
              :type="'save'"
              :width="20"
              :height="20"
            />
          </btn>

          <btn
            :disabled="loading || create"
            :theme="'icon ghost'"
            @click="onToggleDelete"
          >
            <sprite :type="'less'" />
          </btn>
        </template>
      </topbar>
    </div>

    <div class="view-content">
      <div class="view-content-wrapper">
        <fields v-if="datas">
          <div class="fields-row" data-column="2">
            <!-- Content FR -->
            <div class="fields-field">
              <inputs
                :id="'description-fr'"
                :value="datas.description_fr"
                :label="$t('labels.description_fr')"
                :required="true"
                @input="onInput($event, 'description_fr')"
              />
            </div>

            <!-- Content EN -->
            <div class="fields-field">
              <inputs
                :id="'description-en'"
                :value="datas.description_en"
                :label="$t('labels.description_en')"
                :required="true"
                @input="onInput($event, 'description_en')"
              />
            </div>

            <!-- Content EN -->
            <div class="fields-field">
              <inputs
                :id="'description-nl'"
                :value="datas.description_nl"
                :label="$t('labels.description_nl')"
                :required="true"
                @input="onInput($event, 'description_nl')"
              />
            </div>
          </div>

          <div class="fields-row" data-column="2">
            <div class="fields-field">
              <search
                :id="'skills-search'"
                :type="'skills'"
                :position="'top'"
                :selecteds="selectedSkills"
                @click="onSkillSelect"
              />
            </div>
            <div class="fields-field">
              <pills
                :property="'name'"
                :els="datas.skills"
                @remove="onRemoveSkill"
              />
            </div>
          </div>
        </fields>
      </div>
      <loader v-show="loading"/>
    </div>

    <!-- Popup -->
    <popup
      v-if="!create && showPopupDelete"
      :title="$t('categories.delete.title.singular')"
      @close="onCloseDelete"
    >
      <p class="popup-text">{{ $t('categories.delete.text') }}</p>
      <div class="popup-form">
        <inputs
          :id="'category-delete'"
          :value="deleteInput"
          :error="deleteError"
          @input="onDeleteInput"
        />
        <btn
          :theme="'ghost'"
          :disabled="deleteInput.length === 0"
          @click="onDelete"
        >
          {{ $t('actions.delete') }}
        </btn>
      </div>
    </popup>
  </div>
</template>

<script>
// Components
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';
import Inputs from '@/components/components/Inputs';
import Loader from '@/components/components/Loader';
import Pills from '@/components/modules/Pills';
import Search from '@/components/modules/Search';
import Fields from '@/components/partials/Fields';
import Topbar from '@/components/partials/Topbar';
import Popup from '@/components/partials/Popup';

export default {
  components: {
    Btn, Inputs, Loader, Sprite,
    Search, Pills,
    Fields, Topbar, Popup
  },
  data() {
    return {
      showPopupDelete: false,
      deleteInput: '',
      deleteError: false,
    }
  },
  computed: {
    datas() {
      return this.$store.state.category.datas;
    },
    loading() {
      return this.$store.state.category.requests > 0;
    },
    create() {
      return this.$route.name === 'CategoriesCreate';
    },
    selectedSkills() {
      return this.$store.state.category.datas.skills.map(s => s.id);
    }
  },
  beforeMount() {
    if (this.create) {
      this.$store.commit('category/reset');
      return;
    }

    const { id } = this.$route.params;
    this.$store.dispatch('category/get', { id });
  },
  watch: {
    $route(to) {
      if (to.name === 'CategoriesDetail') {
        const { id } = this.$route.params;
        this.$store.dispatch('category/get', { id });
      }
    }
  },
  methods: {
    // Form
    onInput(value, type) {
      this.$store.dispatch('category/update', { value, type });
    },
    onSkillSelect(e) {
      const skill = e;

      this.$store.commit('category/updateDataArray', {
        value: skill,
        type: 'skills',
        property: 'id'
      });
    },
    onRemoveSkill(e) {
      this.$store.commit('category/updateDataArray', {
        value: e,
        type: 'skills',
        property: 'id'
      });
    },
    // Actions
    onSave() {
      if (this.create) {
        this.$store.dispatch('category/create');
        return;
      }

      this.$store.dispatch('category/save');
    },
    // Delete
    onCloseDelete() {
      this.showPopupDelete = false;
      this.deleteInput = '';
      this.deleteError = false;
    },
    onDeleteInput(value) {
      this.deleteInput = value;
    },
    onDelete() {
      this.$store.dispatch('category/delete');
    },
    onToggleDelete() {
      this.showPopupDelete = !this.showPopupDelete;
    },
  }
}
</script>
