
const category = {
  to: (data) => {
    return {
      description_fr: data.description_fr,
      description_en: data.description_en,
      description_nl: data.description_nl,
      skill_ids: data.skills.map(s => s.id)
    }
  }
};

export default category;
