<template>
  <div :class="['app', { 'app-dark': darkmode }]">
    <template v-if="$route.name === 'Home'">
      <router-view />
    </template>
    <template v-else-if="isLogged !== 'waiting'">
      <sidebar @changeMode="changeMode"/>
      <router-view />
    </template>
    <loader :label="'Authentification'" v-else />
    <notifications />
  </div>
</template>

<script>
import Loader from '@/components/components/Loader';
import Notifications from '@/components/modules/Notifications';
import Sidebar from '@/components/partials/Sidebar';

export default {
  components: {
    Notifications,
    Loader,
    Sidebar
  },
  async beforeMount() {
    if (process.env.VUE_APP_AUTH_DISABLED) {
      this.$store.commit('login/updateLogged', true);

      const darkmode = window.localStorage.getItem('darkmode');

      if (darkmode && darkmode === 'true') {
        this.$store.commit('setDarkmode', true);
      }
      return;
    }

    const { cookie } = document;
    const isLogged = window.localStorage.getItem('is-logged');

    if (this.$route.name === 'Home') {
      if (cookie.split(';').some((item) => item.trim().startsWith('XSRF-TOKEN=')) && isLogged && isLogged === 'true') {
        this.$store.commit('login/updateLogged', true);
        this.$router.push({ name: 'LawyersListing' });
      }

      return;
    }

    if (!cookie.split(';').some((item) => item.trim().startsWith('XSRF-TOKEN='))) {
      this.$router.push({ name: 'Home' });
      return;
    }

    this.$store.commit('login/updateLogged', true);

    // Darkmode
    const darkmode = window.localStorage.getItem('darkmode');

    if (darkmode && darkmode === 'true') {
      this.$store.commit('setDarkmode', true);
    }
  },
  computed: {
    darkmode() {
      return this.$store.state.darkmode;
    },
    isLogged() {
      return this.$store.state.login.logged;
    }
  },
  methods: {
    changeMode(darkMode) {
      this.$store.commit('setDarkmode', darkMode);
      window.localStorage.setItem('darkmode', JSON.stringify(darkMode));
    }
  }
};
</script>


<style lang="scss">
@import '@/scss/reset.scss';
@import '@/scss/colors.scss';

html {
  @include font-smoothing();
}

html,
body {
  @include size(100%);
  font-family: $sans-serif;
  font-size: 16px;
}

.app {
  @include size(100%);
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto;
  grid-template-areas: "sidebar view";
  color: var(--black-light);

  .sidebar {
    grid-area: sidebar;
  }

  .view {
    display: flex;
    flex-direction: column;
    position: relative;
    grid-area: view;
    max-height: 100vh;

    &-header {
      @include padding(12px $gs-padding null);
      position: relative;
      z-index: 12;
      flex-shrink: 0;
      width: 100%;
      border-bottom: 1px solid var(--grey-lightest);
      background-color: var(--white);
    }

    &-content {
      @include padding($gs-padding $gs-padding);
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;
      background-color: var(--white);

      &-wrapper {
        @include margin(-$gs-padding);
        @include size(calc(100% + #{2 * $gs-padding}));
        @include padding($gs-padding);
        overflow: auto;
      }

      &-title {
        @include margin(null null 24px);
        @include padding(null null 12px);
        font-size: rem(20px);
        line-height: 1.2;border-bottom: 1px solid var(--white-darker);

        &:not(:first-child) {
          @include margin(32px null null);
        }
      }

      // View loader
      > .loader {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        z-index: 24;
        background-color: var(--white);
        opacity: .9;
      }
    }

    &-footer {
      @include padding(12px $gs-padding);
      flex-shrink: 0;
      width: 100%;
      border-top: 1px solid var(--grey-lightest);
      background-color: var(--white);
    }
  }

  .view-enter,
  .view-leave-to {
    opacity: 0;
  }

  // App loader
  > .loader {
    @include position(absolute, 50% null null 50%);
    transform: translate(-50%, -50%);
  }
}

.container {
  @include margin(null auto);
  @include padding(null $gs-padding);
  width: 100%;
  max-width: $gs-container;
}

.svg {
  font-size: 0;

  svg {
    display: inline-block;
  }
}

.u-visually-hidden {
  border: none !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}
</style>
