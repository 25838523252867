<template>
  <div
    :class="['picture', { 'has-picture': src }]"
  >
    <input
      ref="input"
      :id="id"
      type="file"
      class="u-visually-hidden"
      @change="onChange"
    />
    <label :for="id">
      <img
        v-if="src || img"
        :src="src ? src : img"
        class="pic"
      >
      <span class="text">{{ label }}</span>
      <span class="plus">
        <sprite
          :type="'upload'"
          :width="24"
          :height="24"
        />
      </span>
      <span
        class="delete"
        @click.stop="$emit('delete')"
      ></span>
    </label>
  </div>
</template>

<script>
import Sprite from '@/components/components/Sprite';
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    src: { type: String },
    label: { type: String },
  },
  components: {
    Sprite
  },
  data() {
    return {
      img: null
    }
  },
  methods: {
    onChange(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      this.$emit('change', files[0]);
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.img = e.target.result;
      };

      reader.readAsDataURL(file);
    }
  }
}
</script>


<style lang="scss" scoped>
.picture {
  position: relative;
  width: 100%;

  &.has-picture {
    .plus {
      width: 50%;
    }

    .delete {
      display: block;
    }
  }

  input:focus {
    + label {
      border-color: var(--primary);

      .pic { opacity: .1; }
      .text { opacity: 0;}
      .plus { opacity: 1;}
    }
  }

  label {
    @include aspect-ratio(1, 1);
    @include size(100%, auto);
    display: block;
    transition: border-color .3s $ease-out-quart;
    border-radius: 4px;
    border: 1px solid var(--grey-lightest);
    background-color: var(--grey-blue);
    overflow: hidden;
    cursor: pointer;

    &:hover {
      border-color: var(--primary);

      .pic { opacity: .1; }
      .text { opacity: 0; }
      .plus {
        opacity: 1;

        &:hover {
          background-color: rgba($primary, .8);

          .svg {
            fill: $white;
          }
        }
      }

      .delete {
        opacity: 1;

        &:hover {
          background-color: rgba($primary, .8);

          &::before,
          &::after {
            background-color: $white;
          }
        }
      }
    }

    .pic {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      transition: opacity .3s $ease-out-quart;
      object-fit: cover;

      + .text {
        display: none;
      }
    }

    .text {
      @include position(absolute, 50% null null 50%);
      transition: opacity .3s $ease-out-quart;
      width: calc(100% - 40px);
      transform: translate(-50%, -50%);
      color: var(--black);
      text-align: center;
    }

    .plus {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      transition: opacity .3s $ease-out-quart, background-color .3s $ease-out-quart;
      opacity: 0;

      .svg {
        @include position(absolute, calc(50% - 12px) null null calc(50% - 12px));
        fill: $primary;
      }
    }

    .delete {
      @include position(absolute, 0 0 null null);
      @include size(50%, 100%);
      display: none;
      transition: opacity .3s $ease-out-quart, background-color .3s $ease-out-quart;
      opacity: 0;

      &::before,
      &::after {
        @include position(absolute, calc(50% - 1px) null null calc(50% - 12px));
        @include size(24px, 2px);
        content: '';
        transform: rotate(-45deg);
        background-color: var(--primary);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }
}
</style>
