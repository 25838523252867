<template>
  <div class="view">
    <div class="view-header">
      <topbar>
        <template #title>
          <h1 v-if="datas">{{ datas.name_fr }}</h1>
        </template>

        <template #actions>
          <btn
            :theme="'icon'"
            :disabled="loading"
            @click="onSave"
          >
            <sprite
              :type="'save'"
              :width="20"
              :height="20"
            />
          </btn>

          <btn
            :disabled="loading || create"
            :theme="'icon ghost'"
            @click="onToggleDelete"
          >
            <sprite :type="'less'" />
          </btn>
        </template>
      </topbar>
    </div>

    <div class="view-content">
      <div class="view-content-wrapper">
        <fields v-if="datas">
          <div class="fields-row" data-column="2">
            <!-- Content FR -->
            <div class="fields-field">
              <inputs
                :id="'name-fr'"
                :value="datas.name_fr"
                :label="$t('labels.name_fr')"
                :required="true"
                @input="onInput($event, 'name_fr')"
              />
            </div>

            <!-- Content EN -->
            <div class="fields-field">
              <inputs
                :id="'name-en'"
                :value="datas.name_en"
                :label="$t('labels.name_en')"
                :required="true"
                @input="onInput($event, 'name_en')"
              />
            </div>

            <!-- Content EN -->
            <div class="fields-field">
              <inputs
                :id="'name-nl'"
                :value="datas.name_nl"
                :label="$t('labels.name_nl')"
                :required="true"
                @input="onInput($event, 'name_nl')"
              />
            </div>

            <div class="fields-field">
              <selection
                :id="'skill-category'"
                :placeholder="$t('placeholders.category')"
                :selecteds="category"
                :options="categories"
                :label="$t('labels.category')"
                @onRemove="onInput($event, 'skill_category_id')"
                @onOption="onInput($event, 'skill_category_id')"
              />
            </div>
          </div>

          <div class="fields-row" data-column="2">
            <!-- Cases -->
            <div class="fields-field">
              <search
                :id="'cases-search'"
                :type="'cases'"
                :selecteds="selectedsLawcases"
                @click="onCaseSelect"
              />
            </div>
          </div>

          <div class="fields-row" data-column="1">
            <!-- Cases -->
            <div class="fields-field">
              <pills
                :property="'content'"
                :els="lawcases"
                @remove="onRemoveCase"
              />
            </div>
          </div>
        </fields>
      </div>
      <loader v-show="loading"/>
    </div>

    <!-- Popup -->
    <popup
      v-if="showPopupDelete"
      :title="$t('skills.delete.title.singular')"
      @close="onCloseDelete"
    >
      <p class="popup-text">{{ $t('skills.delete.text') }}</p>
      <div class="popup-form">
        <inputs
          :id="'lawcase-delete'"
          :value="deleteInput"
          :error="deleteError"
          @input="onDeleteInput"
        />
        <btn
          :theme="'ghost'"
          :disabled="deleteInput.length === 0"
          @click="onDelete"
        >
          {{ $t('actions.delete') }}
        </btn>
      </div>
    </popup>
  </div>
</template>

<script>
// Components
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';
import Inputs from '@/components/components/Inputs';
import Loader from '@/components/components/Loader';
import Selection from '@/components/components/Selection';
import Search from '@/components/modules/Search';
import Pills from '@/components/modules/Pills';
import Fields from '@/components/partials/Fields';
import Topbar from '@/components/partials/Topbar';
import Popup from '@/components/partials/Popup';

export default {
  components: {
    Btn, Inputs, Loader, Sprite, Selection,
    Search, Pills,
    Fields, Topbar, Popup
  },
  data() {
    return {
      showPopupDelete: false,
      deleteInput: '',
      deleteError: false,
    }
  },
  computed: {
    datas() {
      return this.$store.state.skill.datas;
    },
    loading() {
      return this.$store.state.skill.requests > 0;
    },
    create() {
      return this.$route.name === 'SkillsCreate';
    },
    lawcases() {
      return this.$store.state.skill.datas.lawcases;
    },
    selectedsLawcases() {
      return this.$store.state.skill.datas.lawcases.map(l => l.id);
    },
    category() {
      const category = this.$store.state.skill.datas.skill_category_id;
      if (!category) {
        return [];
      }

      const categories = this.$store.state.skill.categories;
      return categories.filter(c => c.id === category.id);
    },
    categories() {
      return this.$store.state.skill.categories;
    }
  },
  beforeMount() {
    this.$store.dispatch('skill/getCategories');

    if (this.create) {
      this.$store.commit('skill/reset');
      return;
    }

    const { id } = this.$route.params;
    this.$store.dispatch('skill/get', { id });
  },
  watch: {
    $route(to) {
      if (to.name === 'SkillsDetail') {
        const { id } = this.$route.params;
        this.$store.dispatch('skill/get', { id });
      }
    }
  },
  methods: {
    // Form
    onInput(value, type) {
      this.$store.dispatch('skill/update', { value, type });
    },
    onCaseSelect(e) {
      const skill = e;

      this.$store.commit('skill/updateDataArray', {
        value: skill,
        type: 'lawcases',
        property: 'id'
      });
    },
    onRemoveCase(e) {
      this.$store.commit('skill/updateDataArray', {
        value: e,
        type: 'lawcases',
        property: 'id'
      });
    },
    // Actions
    onSave() {
      if (this.create) {
        this.$store.dispatch('skill/create');
        return;
      }

      this.$store.dispatch('skill/save');
    },
    // Delete
    onCloseDelete() {
      this.showPopupDelete = false;
      this.deleteInput = '';
      this.deleteError = false;
    },
    onDeleteInput(value) {
      this.deleteInput = value;
    },
    onDelete() {
      this.$store.dispatch('skill/delete');
    },
    onToggleDelete() {
      this.showPopupDelete = !this.showPopupDelete;
    }
  }
}
</script>
