import Vue from 'vue';

const casus = {
  namespaced: true,
  state: {
    name: '',
    questions: [],
    sending: false,
    response: null,
    saved: null,
    filesSize: 0
  },
  mutations: {
    addQuestion(state, question) {
      state.questions.push(question);
    },
    updateName(state, name) {
      state.name = name;
    },
    updateValue(state, { index, value }) {
      state.questions[index].value = value;
    },
    updateVisibility(state, { index, visibility }) {
      state.questions[index].isVisible = visibility;
    },
    updateSending(state, flag) {
      state.sending = flag;
    },
    updateFilesSize(state) {
      const inputs = state.questions.filter(question => question.type === 'file')
      // eslint-disable-next-line
      const visibles = inputs.filter(question => !question.hasOwnProperty('isVisible') || question.isVisible);
      let size = 0;

      visibles.map((question) => {
        question.value.map((file) => {
          size = size + file.size;
        });
      });

      size = size / 1000000;
      size = Math.round((size + Number.EPSILON) * 100) / 100;
      state.filesSize = size;
    }
  },
  actions: {
    updateQuestions({ state }, data) {
      const questions = JSON.parse(JSON.stringify(data));
      state.questions = questions.map(q => {
        q.title = q.title['fr'].trim();
        q.description = q.description ? q.description['fr'].trim() : null;

        if (q.type === 'checkbox' || q.type === 'file') {
          Vue.set(q, 'value', []);
        } else {
          Vue.set(q, 'value', '');
        }

        if (q.visibility) {
          Vue.set(q, 'isVisible', false);
        }

        if (q.answers) {
          q.answers = q.answers.filter(answer => {
            if (!answer.label || !answer.label['fr'] || !answer.label.length === 0) {
              return false;
            }

            return true;
          })
          .map((answer) => {
            answer.label = answer.label['fr'];
            return answer;
          });
        }

        return q;
      });
    }
  }

};

export default casus;
