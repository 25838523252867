<template>
  <div class="view lawcases">
    <!-- Header -->
    <div class="view-header">
      <topbar>
        <template #input>
          <inputs
            :type="'search'"
            :id="'lawcases-search'"
            :value="filter"
            @input="onSearchInput"
            @submit="onSearchSubmit"
          >
            <template #icon>
              <sprite
                :type="'search'"
              />
              <!-- <span class="svg">
                <svg viewBox="0 0 1000 1000" ><path d="m932.8 850-201-201c56.4-67.6 90.3-154.5 90.3-249.5.1-215.3-174.2-389.5-389.4-389.5-215.3 0-389.5 174.2-389.5 389.5 0 215.2 174.2 389.5 389.5 389.5 61.1 0 119-14.1 170.5-39.1 3 4.7 6.6 9.1 10.7 13.2l203 203c32 32 84 32 116 0 31.9-32.1 31.9-84.1-.1-116.1zm-807.6-450.5c0-169.8 137.7-307.5 307.5-307.5s307.5 137.7 307.5 307.5-137.8 307.5-307.5 307.5c-169.8 0-307.5-137.7-307.5-307.5z"/></svg>
              </span> -->
            </template>
          </inputs>
          <selection
            :id="'items_per_lisiting'"
            :selecteds="[perPage]"
            :options='itemsPerListing'
            @onOption="onChangeItemsPerListing"
          />
        </template>

        <template #actions>
          <btn
            :theme="'icon'"
            :disabled="loading"
            @click="$router.push({ name: 'LawcasesCreate' })"
          >
            <sprite :type="'plus'" />
          </btn>
          <!-- <btn
            :theme="'icon ghost'"
            :disabled="selecteds.length === 0 || loading"
            @click="onToggleDelete"
          >
            <sprite :type="'less'" />
          </btn> -->
        </template>
      </topbar>
    </div>

    <!-- Content -->
    <div class="view-content">
      <div class="view-content-wrapper">
        <listing
          :items="items"
          :ordering="ordering"
          :properties="properties"
          :linkOn="['content']"
          :loading="loading"
          :to="{
            name: 'LawcasesDetail',
            param: 'id'
          }"
          @toggle="onToggle($event)"
          @toggleAll="onToggleAll"
        />
      </div>

      <loader v-show="loading"/>
    </div>

    <!-- Footer -->
    <div class="view-footer">
      <pagination
        v-bind="pages"
        @click="onPage"
        @prev="onPage('prev')"
        @next="onPage('next')"
      />
    </div>

    <!-- Popup -->
    <popup
      v-if="showPopupDelete"
      :title="deleteTitle"
      @close="onCloseDelete"
    >
      <p class="popup-text">{{ $t('lawcases.delete.text') }}</p>
      <div class="popup-form">
        <inputs
          :id="'clients-deletes'"
          :value="deleteInput"
          :error="deleteError"
          @input="onDeleteInput"
        />
        <btn
          :theme="'ghost'"
          :disabled="deleteInput.length === 0"
          @click="onDelete"
        >
          {{ $t('actions.delete') }}
        </btn>
      </div>
    </popup>
  </div>
</template>

<script>
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';
import Inputs from '@/components/components/Inputs';
import Selection from '@/components/components/Selection';
import Loader from '@/components/components/Loader';
import Listing from '@/components/modules/Listing';
import Pagination from '@/components/modules/Pagination';
import Topbar from '@/components/partials/Topbar';
import Popup from '@/components/partials/Popup';

import itemsPerListing from '@/data/static/items-per-listing';

export default {
  components: {
    Pagination,
    Popup,
    Topbar,
    Inputs,
    Sprite,
    Selection,
    Loader,
    Listing,
    Btn
  },
  data() {
    return {
      showPopupDelete: false,
      deleteInput: '',
      deleteError: false,
      itemsPerListing
    }
  },
  computed: {
    properties() {
      return this.$store.state.lawcases.properties;
    },
    ordering() {
      return this.$store.state.lawcases.ordering;
    },
    items() {
      if (!this.$store.state.lawcases.items || this.$store.state.lawcases.items.length === 0) {
        return [];
      }

      // return this.$store.state.lawcases.items;
      return this.$store.state.lawcases.items.map(item => {
        item.penal = item.penal === 1 ? 'check' : '';
        return item;
      });
    },
    filter() {
      return this.$store.state.clients.filter;
    },
    selecteds() {
      if (!this.$store.state.lawcases.items || this.$store.state.lawcases.items.length === 0) {
        return [];
      }

      return this.$store.state.lawcases.items.filter(item => item.selected);
    },
    deleteTitle() {
      if (this.selecteds.length <= 1) {
        return this.$t('lawcases.delete.title.singular');
      }

      return this.$t('lawcases.delete.title.plural', { number: this.selecteds.length });
    },
    loading() {
      return this.$store.state.lawcases.requests > 0;
    },
    pages() {
      return this.$store.state.lawcases.pages;
    },
    perPage() {
      return this.$store.state.perPage;
    }
  },
  watch: {
    $route(to, from) {
      const { query } = to;
      const { page, items, filter } = query;

      if (page !== from.query.page || items !== from.query.items || filter !== from.query.filter) {
        this.$store.dispatch('lawcases/getItems', { page, items, filter });
      }
    }
  },
  mounted() {
    const { query } = this.$route;
    let { page, items, filter } = query;
    // const storedPerPage = window.localStorage.getItem('items_per_page');

    if (filter) {
      this.$store.commit('lawcases/updateFilter', filter);
    }

    if (page && items) {
      this.$store.commit('setPerPage', {
        id: items,
        name: items
      });
      this.$store.dispatch('lawcases/getItems', { page, items, filter });
      return;
    }

    if (!page) {
      page = this.$store.state.lawcases.pages.current;
    }

    if (!items) {
      items = this.$store.state.perPage;
    }

    this.$store.commit('setPerPage', items );
    this.$router.push({ name: 'LawcasesListing', query: { page: this.$store.state.lawcases.pages.current, items: items.name, filter } });
  },
  methods: {
    onSearchInput(e) {
      this.$store.commit('lawcases/updateFilter', e);
    },
    onSearchSubmit() {
      this.$router.push({
        name: 'LawcasesListing',
        query: {
          page: 1,
          items: this.$store.state.perPage.name,
          filter: this.$store.state.lawcases.filter
        }
      });
    },
    onToggleAll() {
      this.$store.dispatch('lawcases/toggleAll');
    },
    onToggle(el) {
      this.$store.dispatch('lawcases/updateSelection', [el]);
    },
    onToggleDelete() {
      this.showPopupDelete = !this.showPopupDelete;
    },
    onCloseDelete() {
      this.showPopupDelete = false;
      this.deleteInput = '';
      this.deleteError = false;
    },
    onDeleteInput(value) {
      this.deleteInput = value;
    },
    onDelete() {
      if (this.deleteInput !== 'DELETE') {
        this.deleteError = true;
        return;
      }

      this.onCloseDelete();
      this.$store.dispatch('lawcases/deleteSelecteds');
    },
    onCheck() {},
    onPage(page) {
      if (page === 'prev') {
        this.$router.push({ name: 'LawcasesListing', query: {
          page: this.$store.state.lawcases.pages.current - 1,
          items: this.$store.state.perPage.name,
          filter: this.$store.state.lawcases.filter
        } });
        return;
      }

      if (page === 'next') {
        this.$router.push({ name: 'LawcasesListing', query: {
          page: this.$store.state.lawcases.pages.current + 1,
          items: this.$store.state.perPage.name,
          filter: this.$store.state.lawcases.filter
         } })
        return;
      }

      this.$router.push({ name: 'LawcasesListing', query: {
        page,
        items: this.$store.state.perPage.name,
        filter: this.$store.state.lawcases.filter
      } });
    },
    onChangeItemsPerListing(value) {
      this.$store.commit('setPerPage', value);
      this.$router.push({ name: 'LawcasesListing', query: { page: this.$store.state.lawcases.pages.current, items: value.name } });
    }
  }
}
</script>

<style lang="scss">
.lawcases {
  .view {
    &-content {
      &-wrapper {
        .listing {
          td.main {
            width: 600px;
          }
        }
      }
    }
  }
}
</style>
