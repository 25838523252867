<template>
  <header class="sidebar">
    <div class="sidebar-logo">
      <img
        :src="`${publicPath}assets/symplicy.svg`"
        class="sidebar-logo-img"
        alt="Symplicy"
      >
      <h1 class="sidebar-logo-text">Symplicy</h1>
    </div>

    <nav class="sidebar-nav">
      <ul class="sidebar-list">
        <li class="sidebar-item">
          <router-link class="sidebar-link" to="/lawyers">Avocats</router-link>
        </li>
        <li class="sidebar-item">
          <router-link class="sidebar-link" to="/clients">Clients</router-link>
        </li>
        <li class="sidebar-item">
          <router-link class="sidebar-link" to="/lawcases">Cas</router-link>
        </li>
        <li class="sidebar-item">
          <router-link class="sidebar-link" to="/skills">Compétences</router-link>
        </li>
        <li class="sidebar-item">
          <router-link class="sidebar-link" to="/categories">Catégories</router-link>
        </li>
        <li class="sidebar-item">
          <router-link class="sidebar-link" to="/forms">Formulaires</router-link>
        </li>
        <!--li class="sidebar-item">
          <router-link class="sidebar-link" to="/spartiate">Spartiate</router-link>
        </li> -->
      </ul>
    </nav>

    <ul class="sidebar-actions">
      <li>
        <btn
          :theme="'ghost'"
          :disabled="backupDisabled"
          @click="onBackup"
        >
          Backup
        </btn>
      </li>
      <li>
        <btn
          :theme="'ghost'"
          :disabled="backupFilesDisabled"
          @click="onBackupFiles"
        >
          Backup photos
        </btn>
      </li>
      <li>
        <btn
          :theme="'ghost'"
          :disabled="backupFormFilesDisabled"
          @click="onBackupFormFiles"
        >
          Backup fichiers formulaires
        </btn>
      </li>
      <li>
        <btn
          :theme="'ghost'"
          @click="onRemoveCache"
        >
          Vider le cache
        </btn>
      </li>
    </ul>

    <div class="sidebar-footer">
      <switcher
        :id="'app-theme'"
        :checked="$store.state.darkmode"
        :lite="true"
        @change="onChange"
      />
    </div>
  </header>
</template>

<script>
import Btn from '@/components/components/Btn';
import Switcher from '@/components/components/Switcher';

import { deleteCache } from '@/data/calls';

import {
  downloadBackup,
  downloadBackupFiles,
  downloadBackupFormFiles
} from '@/data/calls';
import moment from 'moment';

export default {
  components: {
    Btn,
    Switcher
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      backupDisabled: false,
      backupFilesDisabled: false,
      backupFormFilesDisabled: false,
    }
  },
  methods: {
    onChange(darkMode) {
      this.$emit('changeMode', darkMode);
    },
    async onRemoveCache() {
      const req = await deleteCache();

      if (req.status === 'success') {
        this.$store.dispatch('notifications/addNotifs', [{
          type: 'success',
          text: 'Le cache a été vidé avec succès'
        }]);
        return;
      }

      this.$store.dispatch('notifications/addNotifs', [{
        type: 'alert',
        text: 'Une erreur est survenue lors du vidage cache'
      }]);
    },
    async onBackup() {
      this.backupDisabled = true;
      const backup = await downloadBackup();

      if (backup.status === 'success') {
        window.open(backup.data.data);
      }

      this.backupDisabled = false;
    },
    async onBackupFiles() {
      this.backupFilesDisabled = true;
      const backup = await downloadBackupFiles();

      if (backup.status === 'success') {
        const data = backup.data.data;

        if (data) {
          const blob = new Blob([data], { type: 'application/zip' });
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobURL;
          a.download = `Symplicy-Profile-Pictures-${moment().format('YYYY[-]MM[-]DD[-]HH[-]mm')}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
      this.backupFilesDisabled = false;
    },
    async onBackupFormFiles() {
      this.backupFormFilesDisabled = true;
      const backup = await downloadBackupFormFiles();
      console.log('after request backup :', backup);

      if (backup.status === 'success') {
        const data = backup.data.data;

        if (data) {
          console.log("in data :", data);
          const blob = new Blob([data], { type: 'application/zip' });
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobURL;
          a.download = `Symplicy-Form-File-${moment().format('YYYY[-]MM[-]DD[-]HH[-]mm')}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
      this.backupFormFilesDisabled = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  @include padding(20px null);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid var(--grey-lightest);
  background-color: $black;

  // Logo
  // -----------------------------------------------------------------------------
  &-logo {
    @include padding(null 18px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include mq($until: 'm') {
      @include padding(null null null 16px);
    }

    &-img {
      @include size(32px);
      @include margin(null 4px null -4px);
    }

    &-text {
      color: $white;
      font-size: rem(14px);
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  // Nav
  // -----------------------------------------------------------------------------
  &-nav {
    width: 100%;
    margin-top: 16px;
  }

  &-list {
    width: 100%;
  }

  &-link {
    @include padding(7px 20px 9px);
    display: block;
    position: relative;
    transition: background-color .4s $ease-out-quad, color .3s $ease-out-quart;
    color: $white;
    font-family: $sans-serif;
    font-size: rem(16px);
    text-decoration: none;
    overflow: hidden;

    &::before {
      @include position(absolute, 0 null null 0);
      @include size(2px, 100%);
      content: '';
      transform: scaleX(0);
      transition: transform .3s $ease-out-quad;
      background-color: $symplicy;
    }

    &:hover {
      color: $symplicy;
    }

    &.router-link-active {
      color: $symplicy;
      background-color: $black-light;

      &::before {
        transform: scaleX(1);
      }
    }
  }

  // Actions
  // -----------------------------------------------------------------------------
  &-actions {
    width: 100%;
    margin-top: auto;

    .button {
      @include margin(12px null null 20px);
      width: calc(100% - 40px);
      background-color: $black-light;
    }
  }

  // Footer
  // -----------------------------------------------------------------------------
  &-footer {
    @include margin(20px null null);
  }
}
</style>

<style lang="scss">
.sidebar {
  .sidebar-footer {
    .switcher {
      .switcher-toggle {
        background-color: $grey-darkest !important;

        .switcher-toggle-circle {
          background-color: $black !important;
        }
      }
    }
  }
}
</style>
