<template>
  <div class="checkbox">
    <div v-for="answer in question.answers" :key="getId(answer)" class="checkbox-answer">
      <input
        v-on:change="onChange(answer)"
        :id="getId(answer)"
        :checked="question.value.indexOf(answer.value) >= 0"
        class="u-visually-hidden"
        type="checkbox"
      >
      <label :for="getId(answer)">{{ answer.label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  methods: {
    getId(answer) {
      return `${this.question.id}-${answer.value}`;
    },
    onChange(answer) {
      const index = this.question.value.indexOf(answer.value);

      if (index >= 0) {
        const value = [...this.question.value];
        value.splice(index, 1);
        this.$store.commit('casus/updateValue', { index: this.index, value });
        return;
      }

      const value = [...this.question.value];
      value.push(answer.value);
      this.$store.commit('casus/updateValue', { index: this.index, value });
    }
  }
};
</script>

<style lang="scss" scoped>
  .checkbox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &-answer {
      @include margin(8px null null);
    }

    input:checked {
      + label {
        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    label {
      @include padding(null null null 32px);
      display: block;
      position: relative;
      transition: color .3s $ease-out-quart;
      color: color('text');
      font-family: var(--symplicy-font-secondary);
      font-size: rem(14px);
      line-height: (24 / 14);
      cursor: pointer;

      &:focus,
      &:hover {
        color: color('highlight');

        &::before {
          background-color: color('highlight', .2);
        }
      }

      &::before {
        @include position(absolute, 4px null null 0);
        @include size(16px);
        content: '';
        transition: background-color .3s $ease-out-quart;
        border: 1px solid color('highlight');
      }

      &::after {
        @include position(absolute, 6px null null 2px);
        @include size(12px);
        content: '';
        transform: scale(.6);
        transition: opacity .3s $ease-out-quad, transform .3s $ease-out-quad;
        opacity: 0;
        background-color: color('highlight');
      }
    }
  }
</style>
