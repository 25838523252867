<template>
  <div class="pills">
    <btn
      v-for="el in els"
      :key="el.id"
      :theme="'pill'"
    >
      {{ el[property] }}
      <sprite
        v-if="remove"
        :type="'close'"
        :width="12"
        :height="12"
        @click.native="$emit('remove', el)"
      />
    </btn>
  </div>
</template>

<script>
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';

export default {
  components: {
    Btn,
    Sprite
  },
  props: {
    property: {
      type: String,
      required: true
    },
    els: {
      type: Array,
      required: true
    },
    remove: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.pills {
  @include margin(null -4px);
  display: flex;
  flex-wrap: wrap;

  &:not(:first-child) {
    @include margin(12px null null);
  }

  .button {
    @include margin(null 4px 8px);
    pointer-events: none;
  }
}
</style>
