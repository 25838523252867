<template>
  <div
    :class="[
      'input',
      theme,
      { 'icon-left': iconOnLeft },
      { 'expand': expand },
      { 'has-error': error }
    ]"
  >
    <label
      v-if="label"
      :for="id"
    >
      {{ label }}
      <span v-if="readonly">(readonly)</span>
      <span v-if="required" class="required">*</span>
    </label>

    <div class="input-container">
      <button
        v-if="$slots.icon"
        @click="$emit('submit')"
        class="icon"
      >
        <slot name="icon"></slot>
      </button>

      <textarea
        v-if="type === 'textarea'"
        ref="textarea"
        :id="id"
        :autocomplete="autocomplete || id"
        :value="value"
        :readonly="readonly"
        :placeholder="placeholder"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @input="onInput"
        @keypress="onKeyPress"
      />
      <input
        v-else
        :type="type"
        :id="id"
        :autocomplete="autocomplete || id"
        :value="value"
        :name="name || id"
        :readonly="readonly"
        :placeholder="placeholder"
        @input="onInput"
        @change="onChange"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @keypress="onKeyPress"
      />

      <pre
        v-if="expand"
        class="invisible-input"
      >{{ (!value || value.length === 0) && placeholder ? placeholder : value }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    iconOnLeft: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false
    },
    lineBreak: {
      type: Boolean,
      default: true
    },
    expand: {
      type: Boolean,
      default: false
    },
    required: Boolean,
    value: String,
    label: String,
    name: String,
    autocomplete: String,
    theme: String
  },
  methods: {
    onInput(e) {
      const { value } = e.currentTarget;
      this.$emit('input', value);
    },
    onChange(e) {
      this.$emit('change', e);
    },
    onKeyPress(e) {
      const { keyCode } = e;

      if (keyCode === 13) {
        if (!this.lineBreak) {
          e.preventDefault();
          return;
        }

        this.$emit('submit');
        return;
      }

      const { value } = e.currentTarget;
      this.$emit('keypress', value);
    }
  }
}
</script>

<style lang="scss">
.input {
  position: relative;
  width: 100%;

  &.icon-left {
    .icon {
      right: auto;
      left: 0;
      border-radius: 4px 0 0 4px;

      + input {
        @include padding(null 0 null 42px);
      }
    }
  }

  &.expand {
    textarea,
    input {
      @include position(absolute, 0 null null 0);
      @include size(100%);
    }
  }

  &.has-error {
    input {
      border-color: var(--alert);
    }
  }

  // Themes
  &.ghost:not(.has-error) {
    textarea,
    input,
    .invisible-input {
      @include padding(0);
      border-color: var(--white);

      &:focus,
      &:hover {
        border-color: var(--white);
      }
    }

    input,
    .invisible-input {
      height: auto;
    }

    &:not(.expand) {
      textarea {
        height: auto;
      }
    }
  }

  &.plain {
    textarea,
    input {
      border-color: var(--white-darkest);
      background-color: var(--white-darkest);

      &:focus,
      &:not(:focus):hover {
        border-color: var(--white-darkest);
        background-color: var(--white-darkest);
      }
    }
  }

  &.faded {
    textarea,
    input,
    .invisible-input {
      color: var(--grey-darker);

      &::placeholder {
        color: var(--grey-lightest);
      }
    }
  }

  &.small {
    input,
    textarea {
      @include padding(7px null);
      font-size: rem(14px);
    }

    input {
      height: 32px;
    }
  }

  &.big {
    textarea,
    input,
    .invisible-input {
      font-size: rem(20px);
      font-weight: 700;
    }

    .invisible-input {
      min-height: 24px;
    }
  }

  label {
    @include padding(null null 10px);
    display: block;
    color: var(--grey);
    // color: var(--black);
    font-size: rem(14px);
    line-height: 1;

    .required {
      font-size: rem(16px);
      line-height: .8;
      color: var(--primary);
    }
  }

  &-container {
    position: relative;
  }

  .icon {
    @include position(absolute, 0 0 null null);
    @include size(40px, 100%);
    transition: background-color .3s $ease-out-quart;
    border-radius: 0 4px 4px 0;
    outline: none;
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: var(--primary);

      .svg {
        fill: var(--white);
      }
    }

    .svg {
      @include position(absolute, 50% null null 50%);
      display: block;
      transform: translate(-50%, -50%);
      transition: fill .3s $ease-out-quart;
      fill: var(--primary);
    }

    + input,
    + textarea {
      @include padding(null 42px null null);
    }
  }

  input,
  textarea,
  .invisible-input {
    @include padding(11px 15px);
    display: block;
    width: 100%;
    max-width: 100%;
    transition: border-color .3s $ease-out-quart;
    border: 1px solid var(--white-darker);
    border-radius: 4px;
    background-color: var(--white);
    outline: none;
    color: var(--black);
    font-family: $sans-serif;
    font-size: rem(16px);
    line-height: 1.2;
    resize: none;
    appearance: none;
    white-space: pre-wrap;

    &:focus {
      border-color: var(--primary);
    }

    &:not(:focus):hover {
      border-color: var(--grey-lighter);
    }

    &::placeholder {
      color: var(--grey);
    }
  }

  .invisible-input {
    visibility: hidden;
    min-height: 21px;
  }

  input {
    height: 44px;
  }

  textarea {
    height: 136px;
    overflow: hidden;
  }
}
</style>
