<template>
  <div class="radio">
    <div v-for="answer in question.answers" :key="getId(answer)" class="radio-answer">
      <input
        v-on:change="onChange($event, answer)"
        :id="getId(answer)"
        :name="`radio-${question.id}`"
        :checked="answer.value === question.value"
        type="radio"
        class="u-visually-hidden"
      >
      <label :for="getId(answer)">{{ answer.label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  methods: {
    getId(answer) {
      return `${this.question.id}-${answer.value}`;
    },
    onChange(e, answer) {
      e.currentTarget.blur();
      this.$store.commit('casus/updateValue', { index: this.index, value: answer.value });
    }
  }
};
</script>

<style lang="scss" scoped>
  .radio {
    @include margin(null calc(var(--symplicy-spacing-h) / 4 * -1));
    display: flex;
    flex-wrap: wrap;

    &-answer {
      @include margin(8px calc(var(--symplicy-spacing-h) / 4) null);
    }

    input {
      &:focus {
        + label {
          color: color('highlight');
        }
      }

      &:checked {
        + label {
          background-color: color('text');
          color: color('background');
        }

        &:focus {
          + label {
            color: color('highlight');
          }
        }
      }
    }

    label {
      @include padding(9px 22px);
      display: block;
      transition: color .3s $ease-out-quart, background-color .3s $ease-out-quart;
      border-radius: var(--symplicy-border-radius);
      background-color: color('input-background');
      color: color('input-text');
      font-family: var(--symplicy-font-secondary);
      cursor: pointer;

      &:hover {
        color: color('highlight');
      }
    }
  }
</style>
