const bars = [
  {
    id: 1,
    name: 'Brabant Wallon',
  },
  {
    id: 2,
    name: 'Bruxelles',
  },
  {
    id: 3,
    name: 'Charleroi',
  },
  {
    id: 4,
    name: 'Dinant',
  },
  {
    id: 5,
    name: 'Liège',
  },
  {
    id: 6,
    name: 'Limbourg',
  },
  {
    id: 7,
    name: 'Luxembourg',
  },
  {
    id: 8,
    name: 'Mons',
  },
  {
    id: 9,
    name: 'Namur',
  },
  {
    id: 10,
    name: 'Paris',
  },
  {
    id: 11,
    name: 'Verviers',
  },
  {
    id: 12,
    name: 'Tournai',
  },
  {
    id: 13,
    name: 'Eupen',
  }
];

export default bars;
