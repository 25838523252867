const itemsPerListing = [
  {
    id: 20,
    name: 20
  },
  {
    id: 50,
    name: 50
  },
  {
    id: 75,
    name: 75
  },
  {
    id: 100,
    name: 100
  }
];

export default itemsPerListing;
