import skill from './skill';
import lawcase from './lawcase';
import category from './category';
import questions from './questions';

const Formatters = {
  skill,
  lawcase,
  category,
  questions
};

export default Formatters;
