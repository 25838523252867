const langs = [
  {
    id: 1,
    name: 'Français'
  },
  {
    id: 2,
    name: 'Néerlandais'
  },
  {
    id: 3,
    name: 'Anglais'
  },
  {
    id: 4,
    name: 'Allemand'
  },
  {
    id: 5,
    name: 'Espagnol'
  },
  {
    id: 6,
    name: 'Italien'
  },
  {
    id: 7,
    name: 'Arabe'
  },
  {
    id: 8,
    name: 'Russe'
  },
  {
    id: 9,
    name: 'Grec'
  },
  {
    id: 10,
    name: 'Turc'
  }
];

export default langs;
