/* eslint-disable */
import router from '@/router';
// import { authenticate } from '@/data/calls';
import axios from 'axios';

const login = {
  namespaced: true,
  state: {
    requests: 0,
    logged: 'waiting'
  },
  mutations: {
    updateLogged(state, logged) {
      state.logged = logged;
    },
    updateRequests(state, desc = false) {
      if (desc) {
        state.requests--;
        return;
      }

      state.requests++;
    },
  },
  actions: {
    async authenticate({ commit }, { email, password, redirection }) {
      commit('updateRequests');

      axios({
        url: 'sanctum/csrf-cookie',
        baseURL: process.env.VUE_APP_API_URL,
        withCredentials: true
      }).then((response) => {
        axios({
          url: 'auth/login',
          baseURL: process.env.VUE_APP_API_URL,
          method: 'POST',
          withCredentials: true,
          data: { email, password }
        }).then(response => {
          // Logged
          commit('updateLogged', true);
          window.localStorage.setItem('is-logged', 'true');

          if (redirection) {
            router.push(redirection);
          }
        }).catch(error => {
          console.log('error login :', error);
          commit('updateLogged', false);
          window.localStorage.setItem('is-logged', 'false');
        }); // credentials didn't match
      }).catch(error => {
        console.log('error csrf :', error);
        commit('updateLogged', false);
        window.localStorage.setItem('is-logged', 'false');
      });

      // if (request.status === 'success') {
      //   commit('updateLogged', true);

      //   if (redirect) {
      //     router.push(redirect);
      //   }
      // } else {
      //   commit('updateLogged', false);
      // }

      commit('updateRequests', true);
    }
  }
}

export default login;
