<template>
  <div class="checkbox">
    <input
      :id="id"
      :checked="checked"
      type="checkbox"
      @change="$emit('change')"
    />
    <label
      :for="id"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  input {
    display: none;

    &:checked {
      + label {
        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  label {
    @include padding(6px null 6px 24px);
    display: block;
    position: relative;
    transition: color .3s $ease-out-quart;
    font-size: rem(16px);
    color: var(--black);
    cursor: pointer;

    &:hover {
      color: var(--primary);
    }

    &::before {
      @include position(absolute, 7px null null 0);
      @include size(16px);
      content: '';
      border: 1px solid var(--primary);
    }

    &::after {
      @include position(absolute, 9px null null 2px);
      @include size(12px);
      content: '';
      transform: scale(.4);
      transition: transform .3s $ease-out-quart;
      background-color: var(--primary);
      opacity: 0;
    }
  }
}
</style>
