<template>
  <div class="search">
    <!-- <div :class="['search', { 'search-open': displayDropdown }]"> -->
    <label for="search-input" class="u-visually-hidden">{{ $t('casus.search.label') }}</label>
    <input
      id="search-input"
      ref="input"
      :value="value"
      :placeholder="$t('casus.search.placeholder')"
      disabled
      class="search-input"
      type="text"
    >
    <button @click.prevent class="search-modify">{{ $t('casus.search.modify') }}</button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .search {
    position: relative;

    &.search-open {
      box-shadow: 0px 2px 8px rgba(20, 20, 20, .16);
      border-radius: var(--symplicy-border-radius);

      .search-input {
        background-color: color('background');
        border-radius: var(--symplicy-border-radius) var(--symplicy-border-radius) 0 0;
      }
    }

    &:not(.search-open) {
      .search-input {
        &:focus {
          background-color: color('background');
          box-shadow: 0px 2px 8px rgba(20, 20, 20, .16);
        }
      }
    }

    &-input {
      @include padding(4px 50px 4px 16px);
      display: block;
      width: 100%;
      transition: background-color .3s $ease-out-quart, box-shadow .3s $ease-out-quart;
      border: 0;
      border-radius: var(--symplicy-border-radius);
      background-color: color('input-background');
      appearance: none;
      color: color('input-text');
      font-family: var(--symplicy-font-secondary);
      font-size: rem(14px);
      line-height: (24 / 14);
      outline: none;

      @include mq($until: 'm') {
        @include padding(null 40px null 10px);
        font-size: rem(16px);
      }

      &:disabled {
        @include padding(3px 80px 3px null);

        @include mq($until: 'm') {
          @include padding(null 67px null null);
        }
      }

      &::placeholder {
        color: color('input-text', .6);
      }
    }

    &-submit {
      @include position(absolute, 0 0 null null);
      @include padding(8px 16px);
      cursor: pointer;

      .svg {
        display: block;
        fill: color('highlight');
      }
    }

    &-modify {
      @include position(absolute, 0 0 null null);
      @include padding(9px 16px);
      display: block;
      flex-shrink: 0;
      color: color('highlight');
      font-family: var(--symplicy-font-secondary);
      font-size: rem(12px);
      line-height: 1.2;
      font-weight: 600;
      cursor: pointer;
      outline: none;

      @include mq($until: 'm') {
        @include padding(7px 10px);
      }
    }

    // Dropdown
    // -----------------------------------------------------------------------------
    &-dropdown {
      @include size(100%, auto);
      border-radius: 0 0 calc(var(--symplicy-border-radius) / 2) calc(var(--symplicy-border-radius) / 2);
      border-top: 1px solid color('border');
      overflow: auto;

      // Results
      .case {
        &:not(:first-child) {
          border-top: 1px solid color(border);
        }

        &:last-child {
          border-radius: 0 0 var(--symplicy-border-radius) var(--symplicy-border-radius);
        }
      }

      // No result
      .search-noresult {
        @include padding(16px null);
        width: 100%;
        text-align: center;

        &-title {
          color: color('text-light');
          font-family: var(--symplicy-font-secondary);
          font-size: rem(14px);
          line-height: (16 / 14);
        }

        &-text {
          @include margin(4px null null);
          color: color('text');
          font-family: var(--symplicy-font-secondary);
          font-size: rem(14px);
          font-weight: 600;
          line-height: (16 / 14);
        }

        .button {
          @include margin(16px null null);
          font-size: rem(14px);
        }
      }

      // Loader
      .loader {
        @include margin(20px null);
      }
    }
  }
</style>
