<template>
  <div class="view">
    <div class="view-header">
      <topbar>
        <template #title>
          <h1>
            <a
              v-if="datas && datas.is_tua_visible"
              :href="`${tuaURL}avocats/${datas.slug}/profile`"
              target="_blank"
            >
              {{ completeName }}
            </a>
            <span v-else>{{ completeName }}</span>
          </h1>
        </template>

        <template #actions>
          <btn
            :theme="'icon'"
            :disabled="loading"
            @click="onSave"
          >
            <sprite
              :type="'save'"
              :width="20"
              :height="20"
            />
          </btn>
          <btn
            :disabled="loading || create"
            :theme="'icon ghost'"
            @click="onToggleDelete"
          >
            <sprite :type="'less'" />
          </btn>
        </template>
      </topbar>
    </div>

    <div class="view-content">
      <div class="view-content-wrapper">
        <fields v-if="!loading">
          <div class="fields-row">
            <!-- Firstname -->
            <div class="fields-field">
              <inputs
                :id="'lawyer-firstname'"
                :value="datas.firstname"
                :label="$t('labels.firstname')"
                :required="true"
                @input="onInput($event, 'firstname')"
              />
            </div>

            <!-- Lastname -->
            <div class="fields-field">
              <inputs
                :id="'lawyer-lastname'"
                :value="datas.lastname"
                :label="$t('labels.lastname')"
                :required="true"
                @input="onInput($event, 'lastname')"
              />
            </div>

            <!-- Sex -->
            <div class="fields-field">
              <inputs
                :id="'lawyer-sex'"
                :value="datas.sex"
                :label="$t('labels.sex')"
                @input="onInput($event, 'sex')"
              />
            </div>

            <!-- Verified / visible -->
            <div class="fields-field">
              <switcher
                :id="'lawyer-is_tua_visible'"
                :checked="datas.is_tua_visible"
                :label="$t('labels.is_tua_visible')"
                @change="onInput($event, 'is_tua_visible')"
              />

              <switcher
                :id="'lawyer-verified'"
                :checked="datas.verified"
                :label="$t('labels.verified')"
                @change="onInput($event, 'verified')"
              />

              <switcher
                :id="'lawyer-pro-deo'"
                :checked="datas.does_pro_deo"
                :label="$t('labels.does_pro_deo')"
                @change="onInput($event, 'does_pro_deo')"
              />
            </div>
          </div>

          <div class="fields-row">
            <!-- Email -->
            <div class="fields-field">
              <inputs
                :id="'lawyer-email'"
                :type="'email'"
                :value="datas.email"
                :label="$t('labels.email')"
                @input="onInput($event, 'email')"
              />
            </div>

            <!-- Phone -->
            <div class="fields-field">
              <inputs
                :id="'lawyer-phone'"
                :value="datas.phone"
                :label="$t('labels.phone')"
                @input="onInput($event, 'phone')"
              />
            </div>

            <!-- Mobile -->
            <div class="fields-field">
              <inputs
                :id="'lawyer-mobile'"
                :value="datas.mobile"
                :label="$t('labels.mobile')"
                @input="onInput($event, 'mobile')"
              />
            </div>

            <!-- Fax -->
            <div class="fields-field">
              <inputs
                :id="'lawyer-fax'"
                :value="datas.fax"
                :label="$t('labels.fax')"
                @input="onInput($event, 'fax')"
              />
            </div>
          </div>

          <div class="fields-row" data-column="2">
            <!-- Addresses -->
            <div class="fields-field">
              <list
                :id="'lawyer-addresses'"
                :label="$t('labels.addresses')"
                :values="datas.addresses"
                :displayProperty="'formatted_string'"
                @add="onAdd($event, 'addresses')"
                @remove="onRemove($event, 'addresses')"
              />
            </div>

            <!-- Degrees -->
            <div class="fields-field">
              <list
                :id="'lawyer-degrees'"
                :label="$t('labels.degrees')"
                :values="datas.degrees"
                :displayProperty="'title'"
                @add="onAdd($event, 'degrees')"
                @remove="onRemove($event, 'degrees')"
              />
            </div>

            <!-- Links -->
            <div class="fields-field">
              <list
                :id="'lawyer-links'"
                :label="$t('labels.links')"
                :values="datas.links"
                :displayProperty="'name'"
                :isButton="true"
                :isLink="true"
                @add="onToggleAddLink"
                @remove="onRemove($event, 'links')"
              />
            </div>
          </div>

          <div class="fields-row" data-column="2">
            <!-- Bars -->
            <div class="fields-field">
              <selection
                :id="'lawyer-bars'"
                :placeholder="$t('placeholders.bars')"
                :selecteds="datas.bars"
                :options="bars"
                :multiple="true"
                :label="$t('labels.bars')"
                @onRemove="onInput($event, 'bars')"
                @onOption="onInput($event, 'bars')"
              />
            </div>

            <!-- Languages -->
            <div class="fields-field">
              <selection
                :id="'lawyer-languages'"
                :placeholder="$t('placeholders.langs')"
                :selecteds="datas.languages"
                :options="langs"
                :multiple="true"
                :label="$t('labels.langs')"
                @onRemove="onInput($event, 'languages')"
                @onOption="onInput($event, 'languages')"
              />
            </div>

            <!-- Schedule -->
            <div class="fields-field">
              <inputs
                :id="'lawyer-business_hours'"
                :type="'textarea'"
                :value="datas.business_hours"
                :label="$t('labels.business_hours')"
                @input="onInput($event, 'business_hours')"
              />
            </div>

            <!-- Fees -->
            <div class="fields-field">
              <inputs
                :id="'lawyer-fees'"
                :type="'textarea'"
                :value="datas.fees"
                :label="$t('labels.fees')"
                @input="onInput($event, 'fees')"
              />
            </div>
          </div>

          <div class="fields-row" data-column="2">
            <div class="fields-field">
              <search
                :id="'skills-search'"
                :type="'skills'"
                :position="'top'"
                :selecteds="selectedSkills"
                @click="onSkillSelect"
              />
            </div>
            <div class="fields-field">
              <pills
                :property="'name'"
                :els="datas.skills"
                @remove="onRemoveSkill"
              />
            </div>
          </div>

          <!-- Profile pic -->
          <div class="fields-row" v-if="!create">
            <div class="fields-field">
              <pic
                :id="'lawyer-profile-pic'"
                :label="$t('labels.profile-pic')"
                :src="datas.profile_picture_path"
                @change="onChangePicture($event)"
                @delete="onDeletePicture"
              />
            </div>
          </div>
        </fields>
      </div>

      <loader v-show="loading"/>
    </div>

    <!-- Popup -->
    <popup
      v-if="showPopupDelete"
      :title="$t('lawyers.delete.title.singular')"
      @close="onCloseDelete"
    >
      <p class="popup-text">{{ $t('lawyers.delete.text') }}</p>
      <div class="popup-form">
        <inputs
          :id="'lawyer-delete'"
          :value="deleteInput"
          :error="deleteError"
          @input="onDeleteInput"
        />
        <btn
          :theme="'ghost'"
          :disabled="deleteInput.length === 0"
          @click="onDelete"
        >
          {{ $t('actions.delete') }}
        </btn>
      </div>
    </popup>

    <popup
      v-if="showPopupLinks"
      :title="$t('lawyers.add.link')"
      @close="onCloseAddLink"
    >
      <div class="popup-form">
        <inputs
          :id="'lawyer-link-name'"
          :value="link.name"
          :label="$t('labels.text')"
          @input="onLinkInput($event, 'name')"
        />
        <inputs
          :id="'lawyer-link-href'"
          :value="link.href"
          :label="$t('labels.url')"
          @input="onLinkInput($event, 'href')"
        />
        <btn
          @click="onAddLink"
        >
          {{ $t('actions.add') }}
        </btn>
      </div>
      <div class="popup-footer">
        <button
          class="popup-footer-link"
          @click="onLinkInput('Facebook', 'name')"
        >
          Facebook
        </button>
        <button
          class="popup-footer-link"
          @click="onLinkInput('LinkedIn', 'name')"
        >
          LinkedIn
        </button>
        <button
          class="popup-footer-link"
          @click="onLinkInput('Site web', 'name')"
        >
          Site web
        </button>
        <button
          class="popup-footer-link"
          @click="onLinkInput('Twitter', 'name')"
        >
          Twitter
        </button>
        <button
          class="popup-footer-link"
          @click="onLinkInput('Youtube', 'name')"
        >
          Youtube
        </button>
      </div>
    </popup>
  </div>
</template>

<script>
// Dependencies
import isEqual from 'lodash/isEqual';

// Components
import Btn from '@/components/components/Btn';
import Pic from '@/components/components/Pic';
import Sprite from '@/components/components/Sprite';
import Inputs from '@/components/components/Inputs';
import Loader from '@/components/components/Loader';
import Switcher from '@/components/components/Switcher';
import Selection from '@/components/components/Selection';
import Search from '@/components/modules/Search';
import Pills from '@/components/modules/Pills';
import List from '@/components/modules/List';
// import Skills from '@/components/modules/Skills';
import Fields from '@/components/partials/Fields';
import Topbar from '@/components/partials/Topbar';
import Popup from '@/components/partials/Popup';

// Data
import bars from '@/data/static/bars';
import langs from '@/data/static/langs';

// Calls
import { getCategory } from '@/data/calls';

export default {
  components: {
    // eslint-disable-next-line
    Pic, Btn, List, Sprite, Loader, Inputs, Switcher, Selection,
    Search, Pills,
    Popup, Fields, Topbar
  },
  data() {
    return {
      bars,
      langs,
      tuaURL: process.env.VUE_APP_TUA_URL,
      showPopupDelete: false,
      deleteInput: '',
      deleteError: false,
      showPopupLinks: false,
      link: {
        name: '',
        href: ''
      }
    }
  },
  computed: {
    datas() {
      return this.$store.state.lawyer.datas;
    },
    selectedSkills() {
      return this.$store.state.lawyer.datas.skills.map(s => s.id);
    },
    completeName() {
      let datas = this.$route.name === 'LawyersCreate' ? this.$store.state.lawyer.datas : this.$store.state.lawyer.startDatas;
      if (!datas.firstname || !datas.lastname) {
        return '';
      }

      return `${datas.firstname} ${datas.lastname}`;
    },
    isVerified() {
      return this.$store.state.lawyer.datas.verified !== 0;
    },
    noModification() {
      return isEqual(this.$store.state.lawyer.datas, this.$store.state.lawyer.startDatas);
    },
    loading() {
      return this.$store.state.lawyer.requests > 0;
    },
    create() {
      return this.$route.name === 'LawyersCreate';
    }
  },
  beforeMount() {
    if (this.create) {
      this.$store.commit('lawyer/reset');
      return;
    }

    const { slug } = this.$route.params;
    this.$store.dispatch('lawyer/get', { slug });
  },
  watch: {
    $route(to) {
      if (to.name === 'LawyersDetail') {
        const { slug } = this.$route.params;
        this.$store.dispatch('lawyer/get', { slug });
      }
    }
  },
  methods: {
    onInput(value, type) {
      this.$store.dispatch('lawyer/update', { value, type });
    },
    onSave() {
      if (this.create) {
        this.$store.dispatch('lawyer/create');
        return;
      }

      this.$store.dispatch('lawyer/save');
    },
    onDelete() {
      if (this.deleteInput !== 'DELETE') {
        this.deleteError = true;
        return;
      }

      this.onCloseDelete();
      this.$store.dispatch('lawyer/delete');
    },
    onToggleDelete() {
      this.showPopupDelete = !this.showPopupDelete;
    },
    onCloseDelete() {
      this.showPopupDelete = false;
      this.deleteInput = '';
      this.deleteError = false;
    },
    onDeleteInput(value) {
      this.deleteInput = value;
    },
    onAdd(event, type) {
      switch (type) {
        case 'addresses':
          this.$store.commit('lawyer/addElement', {
            type, value: { formatted_string: event }
          });
          break;
        case 'skills':
          this.$store.commit('lawyer/addElement', {
            type, value: { id: event }
          });
          break;
        case 'degrees':
          this.$store.commit('lawyer/addElement', {
            type, value: { title: event }
          });
          break;
        default:
          this.$store.commit('lawyer/addElement', { type, value: event});
          break;
      }
    },
    onToggleAddLink() {
      this.showPopupLinks = !this.showPopupLinks;
    },
    onCloseAddLink() {
      this.showPopupLinks = false;
      this.link.name = '';
      this.link.href = '';
    },
    onLinkInput(value, prop) {
      this.link[prop] = value;
    },
    onAddLink() {
      if (this.link.name.length === 0 || this.link.href.length === 0) {
        return;
      }

      const link = {...this.link};
      this.$store.commit('lawyer/addElement', {
        type: 'links',
        value: link
      });

      this.onCloseAddLink();
    },
    onRemove(event, type) {
      this.$store.commit('lawyer/removeElement', { type, value: event});
    },
    onChangePicture(file) {
      this.$store.dispatch('lawyer/uploadPicture', file);
    },
    onDeletePicture() {
      this.$store.dispatch('lawyer/deletePicture');
    },
    async onSkillSelect(e) {
      const skill = e;

      if (skill.isCat) {
        const request = await getCategory({ id: skill.id });
        const { skills } = request.data.data;

        skills.map(s => {
          const index = this.selectedSkills.indexOf(s.id);

          if (index < 0) {
            this.$store.commit('lawyer/updateDataArray', {
              value: {
                ...s,
                displayLabel: skill.name,
                cat: skill.name
              },
              type: 'skills',
              property: 'id'
            });
          }
        });
        return;
      }

      this.$store.commit('lawyer/updateDataArray', {
        value: skill,
        type: 'skills',
        property: 'id'
      });
    },
    onRemoveSkill(e) {
      this.$store.commit('lawyer/updateDataArray', {
        value: e,
        type: 'skills',
        property: 'id'
      });
    },
  }
}
</script>

