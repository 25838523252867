
const skill = {
  from: (data) => {
    const datas = data;
    datas.skill_categories = datas.skill_categories.map(c => {
      c.name = c.description;
      return c;
    });

    const category = datas.skill_categories.filter(c => c.id === datas.skill_category_id);
    datas.skill_category_id = category.length > 0 ? category[0] : null;

    return datas;
  },
  to: (data) => {
    return {
      name_fr: data.name_fr,
      name_en: data.name_en,
      name_nl: data.name_nl,
      skill_category_id: data.skill_category_id ? data.skill_category_id.id : null,
      case_ids: data.lawcases.map(s => s.id)
    }
  }
};

export default skill;
