<template>
  <div :class="`question ${question.id}${question.error ? ' has-error' : ''}`" v-show="hasVisibility">
    <h4 class="question-title">{{ question.title }}</h4>
    <p v-if="question.description" class="question-text">{{ question.description }}</p>

    <checkbox v-if="question.type === 'checkbox'" :question="question" :index="index" />
    <radio v-else-if="question.type === 'radio'" :question="question" :index="index" />
    <file v-else-if="question.type === 'file'" :question="question" :index="index" />
    <privacy v-else-if="question.type === 'privacy'" :question="question" />
    <terms-of-use v-else-if="question.type === 'terms'" :question="question" />
    <inputs v-else :question="question" :index="index" />

    <!-- Error for privacy -->
    <span v-if="question.error && question.type !== 'privacy' && question.type !== 'terms'" class="question-error">{{ $t(`form.errors.${question.error}`) }}</span>
    <span v-if="question.error && question.type === 'privacy'" class="question-error">{{ $t(`form.errors.privacy`) }}</span>
    <span v-if="question.error && question.type === 'terms'" class="question-error">{{ $t(`form.errors.terms`) }}</span>
  </div>
</template>

<script>
import File from '@/components/components/casus/File';
import Radio from '@/components/components/casus/Radio';
import Inputs from '@/components/components/casus/Inputs';
import Checkbox from '@/components/components/casus/Checkbox';
import Privacy from '@/components/components/casus/Privacy';
import TermsOfUse from '@/components/components/casus/Terms-of-use';

export default {
  components: {
    File,
    Radio,
    Inputs,
    Privacy,
    Checkbox,
    TermsOfUse
  },
  props: {
    question: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    hasVisibility() {
      if (this.question.id === 'question-lawyer' && this.question.answers.length <= 1) {
        return false;
      }

      if (this.question.type === 'file') {
        this.$store.commit('form/updateFilesSize');
      }

      if (!this.question.visibility) {
        return true;
      }

      const { rules, all } = this.question.visibility;
      const questions = this.$store.state.casus.questions;
      const checks = [];

      rules.map((rule) => {
        const question = questions.filter(q => q.id === rule.question)[0];
        let check = false;

        if (question.type === 'checkbox') {
          rule.values.map((val) => {
            if (question.value.includes(val)) {
              check = true;
            }
          });
        }

        if (question.type === 'radio') {
          if (rule.values.includes(question.value)) {
            check = true;
          }
        }

        checks.push(check);
      });

      // eslint-disable-next-line
      let visibility = checks.indexOf(true) >= 0;

      if (all) {
        // eslint-disable-next-line
        visibility = checks.indexOf(false) < 0;
      }

      if (this.question.isVisible !== visibility) {
        this.$store.commit('casus/updateVisibility', { index: this.index, visibility });
      }

      return visibility;
    }
  }
};
</script>

<style lang="scss" scoped>
  .question {
    &-title {
      color: color('text');
      font-size: rem(16px);
      line-height: (20 / 16);
    }

    &.question-privacy,
    &.question-terms {
      .question-title {
        display: none;
      }
    }

    &-text {
      color: color('text-light');
      font-family: var(--symplicy-font-secondary);
      font-size: rem(12px);
      line-height: (16 / 12);
    }

    // Inputs
    .input {
      @include margin(8px null null);
    }

    // Errors
    &-error {
      @include margin(5px null null);
      display: block;
      color: color('error');
      font-family: var(--symplicy-font-secondary);
      font-size: rem(14px);
    }
  }
</style>

<style lang="scss">
.question {
  &.has-error {
    .input {
      input,
      textarea,
      .file-wrapper {
        &:not(:focus) {
          border-color: $primary;
        }
      }
    }

    .radio {
      input[type="radio"] {
        + label {
          border-color: $primary;
        }
      }
    }
  }
}
</style>
