<template>
  <div class="input">
    <textarea
      v-on:input="onInput($event)"
      v-if="question.type === 'textarea'"
      :value="question.value"
    />
    <input
      v-on:input="onInput($event)"
      v-else
      :type="question.type"
      :value="question.value"
    >
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  methods: {
    onInput(e) {
      const $input = e.currentTarget;
      const { value } = $input;
      this.$store.commit('casus/updateValue', { index: this.index, value });
    }
  }
};
</script>

<style lang="scss" scoped>
  .input {
    textarea,
    input {
      @include padding(3px 15px);
      display: block;
      width: 100%;
      transition: border-color .3s $ease-out-quart;
      border: 1px solid color('input-background');
      border-radius: var(--symplicy-border-radius);
      background-color: color('input-background');
      color: color('input-text');
      font-family: var(--symplicy-font-secondary);
      font-size: rem(14px);
      line-height: (24 / 14);
      outline: none;
      appearance: none;

      &:focus {
        border-color: color('highlight');
      }
    }

    textarea {
      height: 120px;
      resize: none;
    }
  }
</style>
