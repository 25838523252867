<template>
  <div class="listing">
    <table>
      <thead>
        <tr>
          <th>
            <input
              :checked="allSelected"
              @change="$emit('toggleAll')"
              id="toggle-all"
              class="u-visually-hidden"
              type="checkbox"
            >
            <label for="toggle-all"></label>
          </th>
          <th
            v-for="property in properties"
            :key="`lawyers-${property}`"
            :class="[{ 'main': linkOn && linkOn.includes(property) }]"
            v-html="$t(`listing.properties.${property}`)"
          />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in items"
          :key="`listing-${i}`"
        >
          <td>
            <input
              :id="`toggle-${id(item)}`"
              :checked="item.selected"
              @change="$emit('toggle', item)"
              class="u-visually-hidden"
              type="checkbox"
            >
            <label :for="`toggle-${id(item)}`"></label>
          </td>
          <td
            v-for="property in properties"
            :key="`listing-${i}-${property}`"
            :class="[{ 'main': linkOn && linkOn.includes(property) }]"
          >
            <router-link
              v-if="linkOn.includes(property)"
              :to="{
                name: to.name,
                params: { [to.param]: item[to.param] }
              }"
            />
            <sprite
              v-if="item[property] === 'check'"
              :type="'check'"
              :width="18"
              :height="18"
            />
            <span v-else>{{ item[property] }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-if="items.length === 0 && !loading"
      class="listing-noresult">
      No result
    </div>
  </div>
</template>

<script>
import Sprite from '@/components/components/Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    ordering: {
      type: Object,
      default: () => {},
      required: true
    },
    properties: {
      type: Array,
      default: () => [],
      required: true
    },
    to: {
      type: Object,
      default: () => {}
    },
    linkOn: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    allSelected() {
      return this.items.filter(el => el.selected).length === this.items.length;
    }
  },
  methods: {
    id(item) {
      if (item.uuid) {
        return item.uuid;
      }

      return item.id;
    }
  }
}
</script>

<style lang="scss" scoped>
.listing {
  table {
    position: relative;
    width: 100%;
  }

  thead {
    th {
      @include padding(12px 15px);
      position: sticky;
      top: -25px;
      z-index: 12;
      border-top: 1px solid var(--grey-lightest);
      border-bottom: 0;
      background-color: var(--white);
      color: var(--black);
      font-size: rem(16px);
      font-weight: 600;
      line-height: 1;
      text-align: left;
      // cursor: pointer;

      &.main {
        width: 300px;
      }

      &::before {
        @include position(absolute, null null 0 0);
        @include size(100%, 1px);
        content: '';
        background-color: var(--grey-lightest);
      }

      &:first-child {
        width: 46px !important;
        border-left: 1px solid var(--grey-lightest);

        &::after {
          content: none;
        }
      }

      &:last-child {
        border-right: 1px solid var(--grey-lightest);
      }

      // Arrow
      &::after {
        @include triangle(10px, var(--grey-light), 'down');
        @include position(absolute, calc(50% - 2px) 15px null null);
        // content: '';
        transition: border-color .3s $ease-out-quart;
      }

      &:not(.is-active) {
        &:hover {
          &::after {
            border-top-color: var(--primary);
          }
        }
      }

      &.is-active {
        &::after {
          border-top-color: var(--primary);
        }

        &.desc {
          &::after {
            @include triangle(10px, var(--primary), 'up');
          }
        }
      }
    }
  }

  tbody {
    tr {
       // Background
      &:nth-child(odd) {
        background-color: var(--grey-blue);
      }

      &:last-child {
        td {
          border-bottom: 1px solid var(--grey-lightest);
        }
      }
    }

    td {
      @include padding(20px 15px);
      position: relative;
      // border-right: 1px solid var(--grey-lightest);
      color: var(--black);
      font-size: rem(16px);
      line-height: (20 / 18);
      vertical-align: middle;

      &.main {
        width: 300px;
      }

      &:first-child {
        width: 35px;
        border-left: 1px solid var(--grey-lightest);
      }

      &:last-child {
        border-right: 1px solid var(--grey-lightest);
      }

      // &:not(.td--main) {
      //   max-width: 210px;
      // }

      span {
        transition: color .3s $ease-out-quart;
      }

      .svg {
        &.check {
          display: flex;
          justify-content: center;
          fill: var(--primary);
        }
      }

      a {
        @include position(absolute, 0 null null 0);
        @include size(100%);

        &:focus,
        &:hover {
          + span {
            color: var(--primary);
          }
        }

        + span {
          font-weight: 600;
        }
      }
    }
  }

  // Toggle
  // -----------------------------------------------------------------------------
  th,
  td {
    input[type="checkbox"] {
      display: none;

      + label {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        cursor: pointer;

        &::before {
          @include position(absolute, calc(50% - 8px) null null calc(50% - 8px));
          @include size(16px);
          content: '';
          display: block;
          border: 1px solid var(--grey-lighter);
          background-color: var(--white);
        }

        &::after {
          @include position(absolute, calc(50% - 8px) null null calc(50% - 8px));
          @include size(16px);
          content: '';
          display: block;
          transform: scale(.6);
          transition: transform .2s $ease-out-quart, opacity .2s $ease-out-quart;
          opacity: 0;
          background-color: var(--primary);
        }
      }

      &:checked {
        + label::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  &-noresult {
    @include padding(64px null);
    font-size: rem(24px);
    text-align: center;
  }
}
</style>
