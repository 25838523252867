import Vue from 'vue';
import { getRandomString } from '@/helpers';

const questions = {
  from: (data) => {
    return data.map(q => {
      if (!q.description) q.description = { fr: '', nl: '', en: '' };
      if (!q.visibility) q.visibility = { all: true, rules: [] };
      if (!q.answers) q.answers = [];

      const answer = {
        label: { fr: '', nl: '', en: '' },
        value: getRandomString()
      };

      q.answers.push(answer);
      q.answers = q.answers.map(a => {
        a.tagToAdd = '';
        // a.tagsVisible = false;
        Vue.set(a, 'tagsVisible', false);
        if (!a.tags || Array.isArray(a.tags)) a.tags = {};
        return a;
      });

      return q;
    });
  },
  to: (data) => {
    const datas = data.map(q => {
      const question = {
        title: q.title,
        id: q.id,
        type: q.type
      };

      if (q.description.fr || q.description.en || q.description.nl) {
        question.description = q.description;
      }

      if (q.type === 'checkbox' || q.type === 'radio') {
        question.answers = q.answers
        .filter(a => a.value && a.label.fr)
        .map(a => {
          delete a.tagToAdd;
          delete a.tagsVisible;
          return a;
        });
      }

      if (q.visibility.rules.length > 0) {
        question.visibility = q.visibility;
      }

      return question;
    })

    return datas.filter(q => q.id && q.title && (q.title.fr || q.title.nl || q.title.en))
  }
};

export default questions;
