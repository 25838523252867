import Vue from 'vue';
import { getForms, deleteForm } from '@/data/calls';

const forms = {
  namespaced: true,
  state: {
    requests: 0,
    filter: '',
    properties: [
      'name'
    ],
    ordering: {
      property: 'name',
      dir: 'asc'
    },
    pages: {
      next: null,
      prev: null,
      current: 1,
      total: null,
    },
    items: []
  },
  mutations : {
    updateFilter(state, value) {
      state.filter = value;
    },
    updateSelected(state, el) {
      const index = state.items.indexOf(el);
      if (index >= 0) {
        state.items[index].selected = !state.items[index].selected;
        return;
      }
    },
    updateRequests(state, desc = false) {
      if (desc) {
        state.requests--;
        return;
      }

      state.requests++;
    },
    setItems(state, items) {
      state.items = items;
    },
    setPagesElement(state, { el, value }) {
      state.pages[el] = value;
    }
  },
  actions: {
    updateSelection({ commit }, els) {
      for (let i = 0; i < els.length; i++) {
        commit('updateSelected', els[i]);
      }
    },
    toggleAll({ state, commit }) {
      const notSelecteds = state.items.filter(item => !item.selected);

      if (notSelecteds && notSelecteds.length > 0) {
        for (let i = 0; i < notSelecteds.length; i++) {
          commit('updateSelected', notSelecteds[i]);
        }

        return;
      }

      for (let i = 0; i < state.items.length; i++) {
        state.items[i].selected = false;
      }
    },
    updatePages({ commit }, pages) {
      commit('setPagesElement', { el: 'next', value: pages.next_page_url });
      commit('setPagesElement', { el: 'prev', value: pages.prev_page_url });
      commit('setPagesElement', { el: 'current', value: pages.current_page });
      commit('setPagesElement', { el: 'total', value: pages.last_page });
    },
    // Calls
    async getItems({ rootState, commit, dispatch }, { page, items, filter }) {
      commit('updateRequests');
      const request = await getForms({
        page,
        items: items ? items : rootState.perPage,
        filter: filter ? filter : null
      });

      if (request.status === 'success') {
        const { data } = request;

        dispatch('updatePages', data);
        commit('setItems', data.data.map(item => {
          Vue.set(item, 'selected', false);
          return item;
        }));
      }

      commit('updateRequests', true);
    },
    async deleteSelecteds({ state, commit, dispatch }) {
      commit('updateRequests');
      const selecteds = state.items.filter(item => item.selected);
      const notifs = [];

      for (let i = 0; i < selecteds.length; i++) {
        const { uuid } = selecteds[i];
        const request = await deleteForm(uuid);

        if (request.status === 'success') {
          notifs.push({
            type: 'success',
            text: 'Formulaire supprimé'
          });
        } else {
          notifs.push({
            type: 'error',
            text: `Problem : formulaire ${uuid} non deleté`
          });
        }
      }

      dispatch('notifications/addNotifs', notifs, { root: true });
      commit('updateRequests', true);
    }
  },
}

export default forms;
