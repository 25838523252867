<template>
  <div class="input">
    <div class="page-box">
      <h2 v-html="$t('casus.terms_of_use.title')" />
      <div v-if="termsContent" v-html="termsContent['fr']" />
    </div>

    <div class="input-wrapper">
      <input
        id="input-checkbox-terms"
        v-on:change="onChange($event)"
        :checked="question.value"
        type="checkbox"
        name="input-checkbox-terms"
        class="u-visually-hidden"
      >
      <label v-html="$t('casus.labels.terms')" for="input-checkbox-terms" class="label-privacy" />
    </div>
  </div>
</template>

<script>
import { staticPage } from '@/data/calls';

export default {
  props: {
    question: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      termsVisibility: false,
      termsContent: null,
    }
  },
  async beforeMount() {
    // const terms = await this.$axios.get('https://static.trouveunavocat.be/wp-json/acf/v3/pages/16');
    const terms = await staticPage('16');
    this.termsContent = terms.data;
  },
  methods: {
    // eslint-disable-next-line
    onChange(e) {
      // const $input = e.currentTarget;
      // const index = this.$store.state.casus.questions.indexOf(this.question);
      // const value = $input.checked;
      // this.$store.commit('casus/updateValue', { index, value });
    }
  }
}
</script>

<style lang="scss" scoped>
  .input {
    .page-box {
      @include margin(15px null 20px);
      @include padding(20px);
      display: block;
      max-height: 100px;
      overflow: auto;
      background-color: color('input-background');
      color: color('input-text');
      font-family: var(--symplicy-font-secondary);
      font-size: rem(14px);
      line-height: 1.4;

      h2,
      h3,
      h4 {
        margin-bottom: 10px;
        font-weight: 600;

        &:not(:first-child) {
          margin-top: 20px;
        }
      }

      p,
      ul {
        + p,
        + ul {
          @include margin(10px null null null);
        }
      }
    }

    // Input
    &-wrapper {
      @include margin(8px null null);
    }

    input:checked {
      + label {
        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    label {
      @include padding(null null null 32px);
      display: block;
      position: relative;
      transition: color .3s $ease-out-quart;
      color: color('text');
      font-family: var(--symplicy-font-secondary);
      font-size: rem(14px);
      line-height: (24 / 14);
      cursor: pointer;

      &:focus,
      &:hover {
        color: color('highlight');

        &::before {
          background-color: color('highlight', .2);
        }
      }

      &::before {
        @include position(absolute, 4px null null 0);
        @include size(16px);
        content: '';
        transition: background-color .3s $ease-out-quart;
        border: 1px solid color('highlight');
      }

      &::after {
        @include position(absolute, 6px null null 2px);
        @include size(12px);
        content: '';
        transform: scale(.6);
        transition: opacity .3s $ease-out-quad, transform .3s $ease-out-quad;
        opacity: 0;
        border: 1px solid color('highlight');
      }
    }
  }
</style>

<style lang="scss">
  .page-box-toggle {
    strong {
      font-weight: normal;
    }
  }
</style>
