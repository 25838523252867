<template>
  <div class="casus">
    <div class="casus-wrapper">
      <div class="casus-header">
        <div class="casus-title">Contact</div>
        <button
          class="casus-close"
          @click="$emit('close')"
        />
      </div>

      <div class="casus-iframe">
        <h3 v-html="$t('casus.title')" class="casus-iframe-title" />

        <!-- Search -->
        <h3 class="casus-search-title">{{ $t('casus.search.title') }}</h3>
        <span class="casus-search-subtitle">
          {{ $t('casus.search.subtitle') }}
          <button class="app-search-subtitle-link">
            <svg x="0px" y="0px" viewBox="0 0 1000 1000">
              <path d="M387.1,925.4c-86.1,86.1-226.3,86.1-312.5-0.1c-86.1-86.1-86.1-226.3,0-312.4l221.3-221.3c86.1-86.1,226.3-86.1,312.5,0.1c2.3,2.3,4.6,4.7,6.8,7.1c1.3,1.1,2.5,2.2,3.8,3.4c3.5,3.5,6.4,7.3,8.7,11.4c0.3,0.4,0.6,0.7,0.9,1.1c-0.1,0.1-0.1,0.1-0.2,0.1c10.6,20,7.5,45.4-9.4,62.2c-17,17-42.6,20-62.6,9.1c-1.4,1.5-15.3-12.3-22.6-19.7l-0.1-0.1c-45-45-117.6-45.3-162.6-0.4L149.4,687.7c-45,45-45,117.9,0,162.9l0.1,0.1c45,45,117.9,45,162.9,0l133.5-133.5c46.3,22.7,100.8,23.5,147.5,2L387.1,925.4L387.1,925.4L387.1,925.4z M925.4,387.1L704.1,608.4c-86.1,86.1-226.3,86.1-312.5-0.1c-2.3-2.3-4.6-4.7-6.8-7.1c-1.3-1.1-2.5-2.2-3.7-3.4c-3.5-3.5-6.4-7.3-8.7-11.4c-0.3-0.4-0.6-0.7-0.8-1.1c0,0,0.1-0.1,0.2-0.1c-10.6-20-7.5-45.4,9.4-62.2c17-17,42.6-20,62.6-9.1c1.4-1.5,15.3,12.4,22.6,19.7l0.1,0.1c45,45,117.6,45.4,162.5,0.4l221.7-221.7c45-45,45-117.9,0-162.9l0-0.1c-45-45-117.9-45-162.9,0L554.2,282.8c-46.2-22.6-100.8-23.5-147.5-2L612.9,74.6c86.1-86.1,226.3-86.1,312.5,0.1C1011.5,160.8,1011.5,301,925.4,387.1L925.4,387.1L925.4,387.1z"/>
            </svg>
          </button>
        </span>

        <search :value="name" />

        <!-- Form -->
        <form
          @submit.prevent
          class="casus-form"
          novalidate
        >
          <!-- Question -->
          <question
            v-for="(question, index) in questions"
            :key="question.id"
            :question="question"
            :index="index"
          />

          <div class="casus-form-footer">
            <!-- Privacy -->
            <question :question="privacy" />
            <question :question="terms" />

            <!-- Trust message -->
            <div class="casus-form-trust">
              <span class="svg">
                <svg :width="18" :height="18" viewBox="0 0 229.5 229.5">
                  <path d="M214.419,32.12c-0.412-2.959-2.541-5.393-5.419-6.193L116.76,0.275c-1.315-0.366-2.704-0.366-4.02,0L20.5,25.927 c-2.878,0.8-5.007,3.233-5.419,6.193c-0.535,3.847-12.74,94.743,18.565,139.961c31.268,45.164,77.395,56.738,79.343,57.209 c0.579,0.14,1.169,0.209,1.761,0.209s1.182-0.07,1.761-0.209c1.949-0.471,48.076-12.045,79.343-57.209 C227.159,126.864,214.954,35.968,214.419,32.12z M174.233,85.186l-62.917,62.917c-1.464,1.464-3.384,2.197-5.303,2.197 s-3.839-0.732-5.303-2.197l-38.901-38.901c-1.407-1.406-2.197-3.314-2.197-5.303s0.791-3.897,2.197-5.303l7.724-7.724 c2.929-2.928,7.678-2.929,10.606,0l25.874,25.874l49.89-49.891c1.406-1.407,3.314-2.197,5.303-2.197s3.897,0.79,5.303,2.197 l7.724,7.724C177.162,77.508,177.162,82.257,174.233,85.186z" />
                </svg>
              </span>
              <p v-html="$t('casus.trust-message')" />
            </div>

            <!-- Submit -->
            <button :disabled="sending || filesSize > 10" class="button button-cta" type="submit">{{ $t('casus.send') }}</button>
          </div>
        </form>
      </div>
    </div>

    <div
      class="casus-backdrop"
      @click="$emit('close')"
    />
  </div>
</template>

<script>
import Question from '@/components/modules/casus/Question';
import Search from '@/components/modules/casus/Search';

import staticQuestions from '@/data/static/questions';

export default {
  components: {
    Question,
    Search
  },
  data() {
    return {
      terms: staticQuestions[4],
      privacy: staticQuestions[5],
    };
  },
  computed: {
    name() {
      return this.$store.state.casus.name;
    },
    questions() {
      return this.$store.state.casus.questions;
    },
    sending() {
      return this.$store.state.casus.sending;
    },
    filesSize() {
      return this.$store.state.casus.filesSize;
    },
  },
  mounted() {
    this.$emit('mounted');
  }
}
</script>

<style lang="scss" scoped>
// Popup
// -----------------------------------------------------------------------------
.casus {
  @include position(absolute, 0 null null 0);
  @include size(100%);

  // Colors
  --symplicy-color-background: 255,255,255; // #fff
  --symplicy-color-backdrop: 0,0,0; // #000
  --symplicy-color-highlight: 255, 43, 96; // #FF2B60
  --symplicy-color-error: 255, 43, 96; // #FF2B60
  --symplicy-color-success: 43, 114, 255; // #2B72FF
  --symplicy-color-text: 15,15,15; // #0F0F0F
  --symplicy-color-text-light: 143,137,143; // #8F898F
  --symplicy-color-input-text: 15,15,15; // #F2F4F5
  --symplicy-color-input-background: 242, 244, 245; // #F2F4F5
  --symplicy-color-border: 235, 233, 232; // #EBE9E8
  // Spacing
  --symplicy-spacing-h: 32px;
  --symplicy-spacing-v: 32px;
  // Radius
  --symplicy-border-radius: 10px;
  // Fonts
  --symplicy-font-primary: 'Crimson Text', serif;
  --symplicy-font-secondary: 'Source Sans Pro', sans-serif;

  font-family: var(--symplicy-font-primary);

  &-wrapper {
    @include position(absolute, 50% null null 50%);
    @include size(calc(100% - 40px), calc(100% - 100px));
    z-index: 1;
    max-width: 686px;
    transform: translate(-50%, -50%);
    border-radius: var(--symplicy-border-radius);
    background-color: rgba(var(--symplicy-color-background), 1);
  }

  &-header {
    padding-top:16px;
    padding-bottom:16px;
    position:relative;
    border-bottom:1px solid #e6e1e6;
  }

  &-title {
    display:block;
    margin: 0 auto;
    padding: 0;
    max-width: 500px;
    color: color('text');
    font-family: var(--symplicy-font-secondary);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
  }

  &-close {
    @include position(absolute, 3px 0 null null);
    @include size(50px);
    z-index: 2;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius:0;
    background: none;
    appearance:none;
    outline:none;
    cursor:pointer;

    &:focus,
    &:hover {
      &::before,
      &::after {
        color: color('highlight');
      }
    }

    &::before,
    &::after {
      @include position(absolute, calc(50% - 1px) null null calc(50% - 10px));
      @include size(20px, 2px);
      content:"";
      transform-origin: 50% 50%;
      transition: background-color .3s $ease-out-quart;
      background-color: color('text-light');
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  // Content
  &-iframe {
    @include padding(var(--symplicy-spacing-v) var(--symplicy-spacing-h));
    @include size(100%, calc(100% - 57px));
    max-width: 100%;
    border: 0;
    overflow: auto;

    @include mq($until: 'm') {
      @include margin(null null 32px);
    }

    &-title {
      color: color('text-light');
      font-family: var(--symplicy-font-secondary);
      font-size: rem(12px);
      line-height: (16 / 12);
    }
  }

  &-message {
    @include margin(16px auto);
    display: block;
    color: color('text');
    font-size: rem(20px);
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  &-search {
    &-title {
      @include margin(24px null null);
      color: color('text');
      font-size: rem(16px);
      line-height: (24 / 16);
    }

    &-subtitle {
      @include margin(null null 8px);
      display: flex;
      justify-content: space-between;
      color: color('text-light');
      font-family: var(--symplicy-font-secondary);
      font-size: rem(12px);
      line-height: (16 / 12);

      &-link {
        @include size(16px);
        cursor: pointer;
        outline: none;

        &:hover {
          svg {
            fill: color('highlight');
          }
        }

        svg {
          @include size(100%);
          display: block;
          transition: fill .3s $ease-out-quart;
          fill: color('text-light', .5);
        }
      }
    }
  }

  &-form {
    @include margin(var(--symplicy-spacing-v) null null);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .question {
      width: 100%;

      &.question-firstname,
      &.question-lastname,
      &.question-email,
      &.question-phone {
        @include mq($from: 'm') {
          width: calc(50% - var(--symplicy-spacing-h));
        }
      }

      + .question:not(.question-terms):not(.question-privacy) {
        @include margin(var(--symplicy-spacing-v) null null);
      }
    }

    &-footer {
      @include padding(var(--symplicy-spacing-v) null null);
      @include margin(calc(var(--symplicy-spacing-v) * 2) null 32px);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      border-top: 1px solid color('border');

      .question {
        width: 100%;
        margin-bottom: calc(var(--symplicy-spacing-v) * 1.5);
      }

      .button {
        @include mq($until: 'm') {
          width: 100%;
        }

        @include mq($from: 'm') {
          margin-left: auto;
        }
      }
    }

    &-trust {
      display: flex;
      align-items: center;
      flex-grow: 1;
      position: relative;
      max-width: calc(100% - 130px);

      @include mq($until: 'm') {
        @include margin(12px null null);
        align-items: flex-start;
        order: 3;
        width: 100%;
        max-width: 100%;

        br {
          display: none;
        }
      }

      .svg {
        @include margin(null 8px null null);
        fill: color('text-light', .5);
      }

      p {
        color: color('text-light');
        font-family: var(--symplicy-font-secondary);
        font-size: rem(12px);
      }
    }

    .button {
      @include padding(2px 24px);
      transition: color .3s $ease-out-quart, background-color .3s $ease-out-quart;
      border: 2px solid color('highlight');
      border-radius: 10px;
      background-color: color('highlight');
      color: color('background');
      font-family: var(--symplicy-font-secondary);
      font-size: rem(18px);
      font-weight: 600;
      line-height: (32 / 18);
      outline: none;

      &:not([disabled]) {
        cursor: pointer;

        &:focus,
        &:hover {
          color: color('highlight');
          background-color: color('background');
        }
      }

      &[disabled] {
        opacity: .6;
      }
    }
  }

  // Backdrop
  // -----------------------------------------------------------------------------
  &-backdrop {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    z-index: 0;
    background-color: color('backdrop', .5);
  }
}
</style>

<style lang="scss">
.casus {
  p,
  li,
  label {
    a {
      color: color('highlight');
    }
  }
}
</style>
