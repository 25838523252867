<template>
  <div class="view forms">
    <div class="view-header">
      <topbar>
        <template #input>
          <inputs
            :type="'search'"
            :id="'forms-search'"
            :value="filter"
            @input="onSearchInput"
            @submit="onSearchSubmit"
          >
            <template #icon>
              <sprite
                :type="'search'"
              />
            </template>
          </inputs>
          <selection
            :id="'items_per_lisiting'"
            :selecteds="[perPage]"
            :options='itemsPerListing'
            @onOption="onChangeItemsPerListing"
          />
        </template>

        <template #actions>
          <btn
            :disabled="actionsDisabled.includes('documents')"
            @click="onBackup('documents')"
          >
            Télécharger les documents
          </btn>
          <btn
            :disabled="actionsDisabled.includes('forms')"
            @click="onBackup('forms')"
          >
            Télécharger les formulaires
          </btn>
          <btn
            :theme="'icon'"
            :disabled="loading"
            @click="onCreate"
          >
            <sprite :type="'plus'" />
          </btn>
          <btn
            :theme="'icon ghost'"
            :disabled="selecteds.length === 0"
            @click="onToggleDelete"
          >
            <sprite :type="'less'" />
          </btn>
        </template>
      </topbar>
    </div>

    <div class="view-content">
      <div class="view-content-wrapper">
        <listing
          :items="items"
          :ordering="ordering"
          :properties="properties"
          :linkOn="['name']"
          :loading="loading"
          :to="{
            name: 'FormsDetail',
            param: 'uuid'
          }"
          @toggle="onToggle($event)"
          @toggleAll="onToggleAll"
        />
      </div>

      <loader v-show="loading"/>
    </div>

    <div class="view-footer">
      <pagination
        v-bind="pages"
        @click="onPage"
        @prev="onPage('prev')"
        @next="onPage('next')"
      />
    </div>

    <!-- Popup -->
    <popup
      v-if="showPopupDelete"
      :title="deleteTitle"
      @close="onCloseDelete"
    >
      <p class="popup-text">{{ $t('forms.delete.text') }}</p>
      <div class="popup-form">
        <inputs
          :id="'forms-deletes'"
          :value="deleteInput"
          :error="deleteError"
          @input="onDeleteInput"
        />
        <btn
          :theme="'ghost'"
          :disabled="deleteInput.length === 0"
          @click="onDelete"
        >
          {{ $t('actions.delete') }}
        </btn>
      </div>
    </popup>
  </div>
</template>

<script>
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';
import Inputs from '@/components/components/Inputs';
import Selection from '@/components/components/Selection';
import Loader from '@/components/components/Loader';
import Pagination from '@/components/modules/Pagination';
import Listing from '@/components/modules/Listing';
import Popup from '@/components/partials/Popup';
import Topbar from '@/components/partials/Topbar';

import itemsPerListing from '@/data/static/items-per-listing';

import {
  downloadForms,
  downloadDocuments
} from '@/data/calls';
import moment from 'moment';

export default {
  components: {
    Pagination,
    Sprite,
    Popup,
    Topbar,
    Inputs,
    Listing,
    Loader,
    Selection,
    Btn
  },
  data() {
    return {
      showPopupDelete: false,
      deleteInput: '',
      deleteError: false,
      itemsPerListing,
      actionsDisabled: []
    }
  },
  mounted() {
    const { query } = this.$route;
    let { page, items, filter } = query;

    if (filter) {
      this.$store.commit('forms/updateFilter', filter);
    }

    if (page && items) {
      this.$store.commit('setPerPage', {
        id: items,
        name: items
      });

      this.$store.dispatch('forms/getItems', { page, items , filter });
      return;
    }

    if (!page) {
      page = this.$store.state.forms.pages.current;
    }

    if (!items) {
      items = this.$store.state.perPage;
    }

    this.$store.commit('setPerPage', items );
    this.$router.push({ name: 'FormsListing', query: { page: this.$store.state.forms.pages.current, items: items.name, filter } });
  },
  computed: {
    properties() {
      return this.$store.state.forms.properties;
    },
    ordering() {
      return this.$store.state.forms.ordering;
    },
    items() {
      return this.$store.state.forms.items;
    },
    filter() {
      return this.$store.state.forms.filter;
    },
    selecteds() {
      return this.$store.state.forms.items.filter(item => item.selected);
    },
    deleteTitle() {
      if (this.selecteds.length <= 1) {
        return this.$t('forms.delete.title.singular');
      }

      return this.$t('forms.delete.title.plural', { number: this.selecteds.length });
    },
    loading() {
      return this.$store.state.forms.requests > 0;
    },
    pages() {
      return this.$store.state.forms.pages;
    },
    perPage() {
      return this.$store.state.perPage;
    }
  },
  watch: {
    $route(to, from) {
      const { query } = to;
      const { page, items, filter } = query;

      if (page !== from.query.page || items !== from.query.items || filter !== from.query.filter) {
        this.$store.dispatch('forms/getItems', { page, items, filter });
      }
    }
  },
  methods: {
    onSearchInput(e) {
      this.$store.commit('forms/updateFilter', e);
    },
    onSearchSubmit() {
      this.$router.push({
        name: 'FormsListing',
        query: {
          page: 1,
          items: this.$store.state.perPage.name,
          filter: this.$store.state.forms.filter
        }
      });
    },
    onToggleAll() {
      this.$store.dispatch('forms/toggleAll');
    },
    onToggle(el) {
      this.$store.dispatch('forms/updateSelection', [el]);
    },
    onToggleDelete() {
      this.showPopupDelete = !this.showPopupDelete;
    },
    onCreate() {
      this.$router.push({ name: 'FormsCreate' });
    },
    onCloseDelete() {
      this.showPopupDelete = false;
      this.deleteInput = '';
      this.deleteError = false;
    },
    onDeleteInput(value) {
      this.deleteInput = value;
    },
    onDelete() {
      if (this.deleteInput !== 'DELETE') {
        this.deleteError = true;
        return;
      }

      this.onCloseDelete();

      this.$store.dispatch('forms/deleteSelecteds');
    },
    onPage(page) {
      if (page === 'prev') {
        this.$router.push({ name: 'FormsListing', query: {
          page: this.$store.state.forms.pages.current - 1,
          items: this.$store.state.perPage.name,
          filter: this.$store.state.forms.filter
        } });
        return;
      }

      if (page === 'next') {
        this.$router.push({ name: 'FormsListing', query: {
          page: this.$store.state.forms.pages.current + 1,
          items: this.$store.state.perPage.name,
          filter: this.$store.state.forms.filter
         } })
        return;
      }

      this.$router.push({ name: 'FormsListing', query: {
        page,
        items: this.$store.state.perPage.name,
        filter: this.$store.state.lawyers.filter
      } });
    },
    onChangeItemsPerListing(value) {
      this.$store.commit('setPerPage', value);
      this.$router.push({ name: 'FormsListing', query: {
        page: this.$store.state.forms.pages.current,
        items: value.name,
        filter: this.$store.state.forms.filter
      } });
    },
    async onBackup(type) {
      this.actionsDisabled.push(type);

      let data = null;
      if (type === 'documents') {
        const documents = await downloadDocuments();
        if (documents.status === 'success') {
          data = documents.data.data
        }
      } else {
        const forms = await downloadForms();
        if (forms.status === 'success') {
          data = forms.data.data
        }
      }

      if (data) {
        const blob = new Blob([data], { type: 'application/zip' });
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.download = `Symplicy-${type}-${moment().format('YYYY[-]MM[-]DD[-]HH[-]mm')}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }


      const index = this.actionsDisabled.indexOf(type);
      this.actionsDisabled.splice(index, 1);
    }
  }
}
</script>

<style lang="scss">
.forms {
  .view {
    &-content {
      &-wrapper {
        .listing {
          td.main {
            width: 500px;
          }
        }
      }
    }
  }
}
</style>
