/* eslint-disable */
import router from '@/router';
import {
  getLawcase,
  saveLawcase,
  createLawcase,
  deleteLawcase
} from '@/data/calls';

const lawcase = {
  namespaced: true,
  state: {
    requests: 0,
    datas: null,
    startDatas: {},
    search: {
      requests: 0,
      lawyers: []
    }
  },
  mutations: {
    updateDataString(state, { value, type }) {
      state.datas[type] = value;
    },
    updateDataArray(state, { value, type, property = 'name' }) {
      let index = -1;

      state.datas[type].map((item, i) => {
        if (item[property] === value[property]) {
          index = i;
        }
      });

      if (index >= 0) {
        state.datas[type].splice(index, 1);
      } else {
        state.datas[type].push(value);
      }
    },
    updateLoading(state, flag) {
      state.loading = flag;
    },
    updateRequests(state, desc = false) {
      if (desc) {
        state.requests--;
        return;
      }

      state.requests++;
    },
    setDatas(state, datas) {
      state.datas = datas;
    },
    setStartDatas(state, datas) {
      state.startDatas = datas;
    },
    addElement(state, { type, value }) {
      state.datas[type].push(value);
    },
    removeElement(state, { type, value }) {
      const index = state.datas[type].indexOf(value);

      if (index >= 0) {
        state.datas[type].splice(index, 1);
      }
    },
    reset(state) {
      const client = {
        content_fr: '',
        content_en: '',
        content_nl: '',
        algolia_primary: '',
        algolia_secondary: '',
        penal: 0,
        skills: []
      };

      state.datas = JSON.parse(JSON.stringify(client));
      state.startDatas = JSON.parse(JSON.stringify(client));
    }
  },
  actions: {
    update({ state, commit }, { value, type }) {
      if (state.datas[type] !== undefined) {
        if (Array.isArray(state.datas[type])) {
          commit('updateDataArray', { value, type });
          return;
        }

        commit('updateDataString', { value, type });
        return;
      }
    },
    async get({ commit, dispatch }, { id }) {
      commit('updateRequests');
      const request = await getLawcase({ id });
      
      if (request.status === 'success') {
        const parsedData = JSON.parse(JSON.stringify(request.data.data));
        const startData = JSON.parse(JSON.stringify(request.data.data));
        commit('setDatas', parsedData);
        commit('setStartDatas', startData);
      } else {
        dispatch('notifications/addNotifs', [{
          text: `Un problème est suvrenu lors de la récupération du cas concret : ${request.error}`,
          type: 'alert'
        }], { root: true });
      }

      commit('updateRequests', true);
    },
    async save({ state, commit, dispatch }) {
      commit('updateRequests');
      const request = await saveLawcase(state.datas);
      let notif = null;

      if (request.status === 'success') {
        notif = {
          text: 'Changements sauvegardés',
          type: 'success'
        };
      } else {
        notif = {
          text: 'Un problème est survenu lors de la sauvegarde',
          type: 'alert'
        };
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
    },
    async create({ state, commit, dispatch }) {
      commit('updateRequests');
      const request = await createLawcase(state.datas);
      let notif = null;

      if (request.status === 'success') {
        const { data } = request.data;

        notif = {
          text: `'${data.content_fr}' crée`,
          type: 'success'
        };

        router.push({ name: 'LawcasesDetail', params: { id: data.id } })
      } else {
        console.log('error :', request.data);
        notif = {
          text: 'Un problème est survenu lors de la création',
          type: 'alert'
        };
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
    },
    async delete({ state, commit, dispatch }, deleteForm = false) {
      commit('updateRequests');
      const request = await deleteLawcase(state.datas.id, deleteForm);
      let notif = null

      if (request.status === 'success') {
        notif = {
          text: 'Cas supprimé',
          type: 'success'
        };
        router.push({ name: 'LawcasesListing' });
      } else {
        console.log('error :', request.data);
        notif = {
          text: 'Un problème est survenu lors de la suppression',
          type: 'alert'
        };
      }

      dispatch('notifications/addNotifs', [notif], { root: true });
      commit('updateRequests', true);
    }
  }
}

export default lawcase;
