<template>
  <div class="pagination">
    <btn
      :theme="'icon prev ghost'"
      :disabled="!prev"
      @click.native="$emit('prev')"
    >
      <sprite
        :type="'chevron-left'"
        :width="7"
        :height="14"
      />
      <span class="u-visually-hidden">{{ $t('pagination.prev') }}</span>
    </btn>

    <ul class="pagination-list">
      <li
        v-for="(page, index) in displayPages"
        :key="`pagination-${page}-${index}`"
        class="pagination-item"
      >
        <button
          :class="['pagination-link', { 'is-active': page === current }]"
          :disabled="page === '...'"
          @click="$emit('click', page)"
        >
          <span>{{ page }}</span>
        </button>
      </li>
    </ul>

    <btn
      :theme="'icon next ghost'"
      :disabled="!next"
      @click.native="$emit('next')"
    >
      <span class="u-visually-hidden">{{ $t('pagination.next') }}</span>
      <sprite
        :type="'chevron-right'"
        :width="7"
        :height="14"
      />
    </btn>
  </div>
</template>

<script>
import Sprite from '@/components/components/Sprite';
import Btn from '@/components/components/Btn';

export default {
  components: {
    Sprite,
    Btn
  },
  props: {
    next: String,
    prev: String,
    current: Number,
    total: Number
  },
  computed: {
    displayPages() {
      const limit = 4;
      let start =[];
      let middle =[];
      let end =[];

      if (this.total > 12) {
        if (this.current < limit || this.current > this.total - (limit - 1)) {
          start = start.concat([1, 2, 3, 4, '...', this.total - 3, this.total - 2, this.total - 1, this.total]);
        } else {
          start = start.concat([1]);

          if (this.current !== limit) {
            middle = middle.concat(['...']);
          }

          middle = middle.concat([this.current - 2, this.current - 1, this.current, this.current + 1, this.current + 2]);


          if (this.current !== this.total - (limit - 1)) {
            end = end.concat(['...']);
          }

          end = end.concat([this.total]);
        }

        return [].concat(start, middle, end);
      }

      return this.total;
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  @include margin(null auto);
  display: flex;
  justify-content: space-between;

  &:not(:first-child) {
    @include margin(30px null null);
  }

  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &-link {
    @include padding(12px 10px);
    display: block;
    position: relative;
    transition: color .2s $ease-out-quart, opacity .3s $ease-out-quart;
    font-family: $sans-serif;
    font-size: rem(16px);
    color: var(--grey-light);
    line-height: 1;
    outline: none;

    &::after {
      @include position(absolute, null null 6px 10px);
      @include size(calc(100% - 20px), 2px);
      content: '';
      transform: scaleX(0);
      transition: transform .3s $ease-out-quart;
      background-color: var(--primary);
    }

    &.is-active {
      color: var(--black-light);

      &::after {
        transform: scaleX(1);
      }
    }

    &:not([disabled]){
      cursor: pointer;

      &:hover {
        color: var(--black-light);
      } 
    }

    &[disabled] {
      opacity: .6;
    }
  }
}
</style>
