<template>
  <div :class="['switcher', { 'lite': lite }]">
    <label
      v-if="label"
      :for="id"
      class="switcher-label"
    >
      {{ label }}
    </label>
    <input
      :id="id"
      :checked="checked"
      @change="onChange"
      type="checkbox"
    >
    <label class="switcher-toggle" :for="id">
      <span class="switcher-toggle-circle">
      </span>
      <sprite
          v-if="!lite"
          :type="'uncheck'"
          :width="16"
          :height="16"
        />
        <sprite
          v-if="!lite"
          :type="'check'"
          :width="14"
          :height="14"
        />
    </label>
  </div>
</template>

<script>
import Sprite from '@/components/components/Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    id: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      required: true
    },
    lite: {
      type: Boolean,
      default: false
    },
    label: String,
  },
  methods: {
    onChange(e) {
      this.$emit('change', e.currentTarget.checked);
    }
  }
}
</script>

<style lang="scss" scoped>
.switcher {
  display: flex;
  flex-direction: column;

  &.lite {
    input:checked + .switcher-toggle {
      .switcher-toggle-circle {
        transform: translateX(18px);
      }
    }

    .switcher-toggle {
      border: 0;
      background-color: var(--grey-lightest) !important;
      width: 44px;
      height: 26px;

      &-circle {
        @include position(absolute, 2px null null 2px);
        @include size(22px);
      }
    }
  }

  &-label {
    @include padding(null null 14px);
    display: block;
    color: var(--grey);
    font-size: rem(14px);
    line-height: 1;
  }

  input {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    appearance: none;

    &:checked {
      + .switcher-toggle {
        background-color: var(--primary);

        .switcher-toggle-circle {
          transform: translateX(25px);
          background-color: var(--white);
        }

        .svg {
          &.check {
            opacity: 1;
            transform: translateY(-50%);
          }

          &.uncheck {
            opacity: 0;
            transform: translateY(-50%) scale(.7);
          }
        }
      }
    }
  }

  &-toggle {
    position: relative;
    display: block;
    width: 60px;
    height: 34px;
    transition: background-color .5s $ease-out-quart;
    border-radius: 30px;
    border: 1px solid var(--primary);
    background-color: var(--white);
    cursor: pointer;

    &-circle {
      @include position(absolute, 2px null null 3px);
      @include size(28px);
      content: '';
      z-index: 1;
      // transform: translateX(26px);
      transition: transform .5s $ease-out-quart, background-color .5s $ease-out-quart;
      border-radius: 50%;
      background-color: var(--primary);
    }

    .svg {
      @include position(absolute, 50% null null);
      z-index: 0;
      transform: translateY(-50%);
      transition: opacity .4s $ease-out-quad, transform .4s $ease-out-quad;
      fill: var(--primary);

      &.check {
        left: 9px;
        opacity: 0;
        transform: translateY(-50%) scale(.7);
        fill: var(--white);
      }

      &.uncheck {
        right: 8px;
      }
    }
  }
}
</style>
