<template>
  <div class="view">
    <!-- Header -->
    <div class="view-header">
      <topbar>
        <template #input>
          <inputs
            :type="'search'"
            :id="'lawyers-search'"
            :value="filter"
            @input="onSearchInput"
            @submit="onSearchSubmit"
          >
            <template #icon>
              <sprite
                :type="'search'"
              />
              <!-- <span class="svg">
                <svg viewBox="0 0 1000 1000" ><path d="m932.8 850-201-201c56.4-67.6 90.3-154.5 90.3-249.5.1-215.3-174.2-389.5-389.4-389.5-215.3 0-389.5 174.2-389.5 389.5 0 215.2 174.2 389.5 389.5 389.5 61.1 0 119-14.1 170.5-39.1 3 4.7 6.6 9.1 10.7 13.2l203 203c32 32 84 32 116 0 31.9-32.1 31.9-84.1-.1-116.1zm-807.6-450.5c0-169.8 137.7-307.5 307.5-307.5s307.5 137.7 307.5 307.5-137.8 307.5-307.5 307.5c-169.8 0-307.5-137.7-307.5-307.5z"/></svg>
              </span> -->
            </template>
          </inputs>
          <selection
            :id="'items_per_lisiting'"
            :selecteds="[perPage]"
            :options='itemsPerListing'
            @onOption="onChangeItemsPerListing"
          />
        </template>

        <template #actions>
          <btn
            :theme="'icon'"
            :disabled="loading"
            @click="$router.push({ name: 'LawyersCreate' })"
          >
            <sprite :type="'plus'" />
          </btn>
          <btn
            :theme="'icon ghost'"
            :disabled="selecteds.length === 0 || loading"
            @click="onToggleDelete"
          >
            <sprite :type="'less'" />
          </btn>
        </template>
      </topbar>
    </div>

    <!-- Content -->
    <div class="view-content">
      <div class="view-content-wrapper">
        <listing
          :items="items"
          :ordering="ordering"
          :properties="properties"
          :linkOn="['name']"
          :loading="loading"
          :to="{
            name: 'LawyersDetail',
            param: 'slug'
          }"
          @toggle="onToggle($event)"
          @toggleAll="onToggleAll"
        />
      </div>

      <loader v-show="loading"/>
    </div>

    <!-- Footer -->
    <div class="view-footer">
      <pagination
        v-bind="pages"
        @click="onPage"
        @prev="onPage('prev')"
        @next="onPage('next')"
      />
    </div>

    <!-- Popup -->
    <popup
      v-if="showPopupDelete"
      :title="deleteTitle"
      @close="onCloseDelete"
    >
      <p class="popup-text">{{ $t('lawyers.delete.text') }}</p>
      <div class="popup-form">
        <inputs
          :id="'lawyers-deletes'"
          :value="deleteInput"
          :error="deleteError"
          @input="onDeleteInput"
        />
        <btn
          :theme="'ghost'"
          :disabled="deleteInput.length === 0"
          @click="onDelete"
        >
          {{ $t('actions.delete') }}
        </btn>
      </div>
    </popup>
  </div>
</template>

<script>
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';
import Inputs from '@/components/components/Inputs';
import Selection from '@/components/components/Selection';
import Loader from '@/components/components/Loader';
import Listing from '@/components/modules/Listing';
import Pagination from '@/components/modules/Pagination';
import Topbar from '@/components/partials/Topbar';
import Popup from '@/components/partials/Popup';

import itemsPerListing from '@/data/static/items-per-listing';

export default {
  components: {
    Pagination,
    Popup,
    Topbar,
    Inputs,
    Sprite,
    Selection,
    Loader,
    Listing,
    Btn
  },
  data() {
    return {
      showPopupDelete: false,
      deleteInput: '',
      deleteError: false,
      itemsPerListing
    }
  },
  computed: {
    properties() {
      return this.$store.state.lawyers.properties;
    },
    ordering() {
      return this.$store.state.lawyers.ordering;
    },
    items() {
      if (!this.$store.state.lawyers.items || this.$store.state.lawyers.items.length === 0) {
        return [];
      }

      return this.$store.state.lawyers.items.map(item => {
        const date = new Date(item.rgpd_mail_sent);
        item.rgpd_mail = item.rgpd_mail_sent ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : '-';
        item.name = `${item.lastname} ${item.firstname}`;
        item.verified = item.verified === 1 ? 'check' : '';
        item.visible = item.is_tua_visible === 1 ? 'check' : '';
        item.bar = item.bars.map(bar => bar.name).join(', ');
        return item;
      });
    },
    filter() {
      return this.$store.state.lawyers.filter;
    },
    selecteds() {
      if (!this.$store.state.lawyers.items || this.$store.state.lawyers.items.length === 0) {
        return [];
      }

      return this.$store.state.lawyers.items.filter(item => item.selected);
    },
    deleteTitle() {
      if (this.selecteds.length <= 1) {
        return this.$t('lawyers.delete.title.singular');
      }

      return this.$t('lawyers.delete.title.plural', { number: this.selecteds.length });
    },
    loading() {
      return this.$store.state.lawyers.requests > 0;
    },
    pages() {
      return this.$store.state.lawyers.pages;
    },
    perPage() {
      return this.$store.state.perPage;
    }
  },
  watch: {
    $route(to, from) {
      const { query } = to;
      const { page, items, filter } = query;

      if (page !== from.query.page || items !== from.query.items || filter !== from.query.filter) {
        this.$store.dispatch('lawyers/getItems', { page, items, filter });
      }
    }
  },
  mounted() {
    const { query } = this.$route;
    let { page, items, filter } = query;

    if (filter) {
      this.$store.commit('lawyers/updateFilter', filter);
    }

    if (page && items) {
      this.$store.commit('setPerPage', {
        id: items,
        name: items
      });

      this.$store.dispatch('lawyers/getItems', { page, items , filter });
      return;
    }

    if (!page) {
      page = this.$store.state.lawyers.pages.current;
    }

    if (!items) {
      items = this.$store.state.perPage;
    }

    this.$store.commit('setPerPage', items );
    this.$router.push({ name: 'LawyersListing', query: { page: this.$store.state.lawyers.pages.current, items: items.name, filter } });
  },
  methods: {
    onSearchInput(e) {
      this.$store.commit('lawyers/updateFilter', e);
    },
    onSearchSubmit() {
      this.$router.push({
        name: 'LawyersListing',
        query: {
          page: 1,
          items: this.$store.state.perPage.name,
          filter: this.$store.state.lawyers.filter
        }
      });
    },
    onToggleAll() {
      this.$store.dispatch('lawyers/toggleAll');
    },
    onToggle(el) {
      this.$store.dispatch('lawyers/updateSelection', [el]);
    },
    onToggleDelete() {
      this.showPopupDelete = !this.showPopupDelete;
    },
    onCloseDelete() {
      this.showPopupDelete = false;
      this.deleteInput = '';
      this.deleteError = false;
    },
    onDeleteInput(value) {
      this.deleteInput = value;
    },
    onDelete() {
      if (this.deleteInput !== 'DELETE') {
        this.deleteError = true;
        return;
      }

      this.onCloseDelete();
      this.$store.dispatch('lawyers/deleteSelecteds');

      // this.$store.dispatch('notifications/addNotifs', [{
      //   text: 'Avocats supprimés !',
      //   type: 'success'
      // }]);
    },
    onCheck() {
      this.$store.dispatch('lawyers/check');
    },
    onPage(page) {
      if (page === 'prev') {
        this.$router.push({ name: 'LawyersListing', query: {
          page: this.$store.state.lawyers.pages.current - 1,
          items: this.$store.state.perPage.name,
          filter: this.$store.state.lawyers.filter
        } });
        return;
      }

      if (page === 'next') {
        this.$router.push({ name: 'LawyersListing', query: {
          page: this.$store.state.lawyers.pages.current + 1,
          items: this.$store.state.perPage.name,
          filter: this.$store.state.lawyers.filter
         } })
        return;
      }

      this.$router.push({ name: 'LawyersListing', query: {
        page,
        items: this.$store.state.perPage.name,
        filter: this.$store.state.lawyers.filter
      } });
    },
    onChangeItemsPerListing(value) {
      this.$store.commit('setPerPage', value);
      this.$router.push({ name: 'LawyersListing', query: {
        page: this.$store.state.lawyers.pages.current,
        items: value.name,
        filter: this.$store.state.lawyers.filter
      } });
    }
  }
}
</script>

