<template>
  <div class="input file">
    <div :class="`file-warning ${filesSize > 10 ? 'file-warning--error' : ''}`">
      <span class="file-warning-message">{{ $t('form.file.message') }}</span>
      <span class="file-warning-current">{{ $t('form.file.currentSize') }} <span class="file-warning-size">{{ filesSize }}MB</span></span>
    </div>
    <input
      v-on:change="onChange"
      :id="`input-file-${question.id}`"
      :name="`input-file-${question.id}`"
      multiple
      type="file"
      accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.txt,.rtf"
      class="u-visually-hidden"
    >
    <div :class="`file-container${ question.value.length > 0 ? ' is-filled' : '' }`">
      <div class="file-wrapper">
        <div v-if="question.value.length > 0" class="file-value">
          <div v-for="(file, i) in question.value" :key="`file-${question.id}-${i}`" class="file-item">
            <span class="file-item-name">{{ file.name }}</span>

            <button v-on:click="onReset(file)" type="button" class="file-item-reset" />
          </div>
        </div>

        <label :for="`input-file-${question.id}`" class="file-label">
          {{ $t('form.labels.file') }}
          <span class="svg">
            <svg viewBox="-34 59.3 18 20">
              <path d="M-24,62.7v11.6c0,0.6-0.4,1-1,1s-1-0.4-1-1V62.7l-3.3,3.3c-0.4,0.4-1.1,0.3-1.4-0.1c-0.3-0.4-0.3-0.9,0-1.3l5-5 c0.4-0.4,1-0.4,1.4,0l5,5c0.4,0.4,0.5,1,0.1,1.4s-1,0.5-1.4,0.1c0,0-0.1-0.1-0.1-0.1L-24,62.7L-24,62.7z M-34,74.3c0-0.6,0.4-1,1-1 s1,0.4,1,1v3h14v-3c0-0.6,0.4-1,1-1s1,0.4,1,1v3c0,1.1-0.9,2-2,2h-14c-1.1,0-2-0.9-2-2V74.3z" />
            </svg>
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    filesSize() {
      return this.$store.state.form.filesSize;
    }
  },
  methods: {
    onChange(e) {
      const $input = e.currentTarget;
      const files = $input.files;
      const accept = /\.png|\.jpg|\.jpeg|\.pdf|\.doc|\.docx|\.xls|\.xlsx|\.zip|\.rar|\.txt|\.rtf/g;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const lower = file.name.toLowerCase();
        const extAccepted = lower.match(accept);

         if (!extAccepted) {
          window.alert('File not supported :', file.name);
        } else {
          let index = -1;

          this.question.value.map((f, ind) => {
            if (f.name === file.name && f.lastModified === file.lastModified) {
              index = ind;
            }
          });

          if (index < 0) {
            const value = [...this.question.value];
            value.push(file);
            this.$store.commit('casus/updateValue', { index: this.index, value });
          }
        }

        this.$store.commit('casus/updateFilesSize');
      }
    },
    onReset(file) {
      const index = this.question.value.indexOf(file);
      const value = [...this.question.value];
      value.splice(index, 1);
      this.$store.commit('casus/updateValue', { index: this.index, value });
      this.$store.commit('casus/updateFilesSize');
    },
    getSize(file) {
      let { size } = file;

      if (file.size < 1000) {
        return `${size.toFixed(2)}B`;
      }

      if (file.size >= 1000 && file.size < 1000000) {
        size = parseFloat(file.size / 1000);
        return `${size.toFixed(2)}kB`;
      }

      size = file.size / 1000000;
      return `${size.toFixed(2)}MB`;
    }
  }
}
</script>

<style lang="scss" scoped>
  input {
    &:focus + .input-file-container {
      .file-label {
        color: color('highlight');
      }
    }
  }

  .file-container {
    position: relative;

    &:hover {
      .file-label {
        color: color('highlight');
      }
    }

    .file-value {
      display: flex;
      flex-direction: column;
    }
  }

  .file-label {
    @include padding(30px 30px 29px);
    @include size(100%, auto);
    cursor: pointer;
    font-family: var(--symplicy-font-secondary);
  }

  .file-warning {
    @include margin(null null 8px);
    display: flex;
    justify-content: space-between;
    color: color('text');

    &--error {
      color: color('error');
    }

    &-message {
      font-family: var(--symplicy-font-secondary);
      font-size: rem(14px);
      font-weight: 600;
    }

    &-current {
      @include margin(null null null 20px);
      flex-shrink: 0;
      font-family: var(--symplicy-font-secondary);
      font-size: rem(14px);
    }

    &-size {
      font-weight: 600;
    }
  }

  .file-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    transition: border-color .3s $ease-out-quart, background-color .3s $ease-out-quart;
    border: 1px solid color('background-input');
    border-radius: var(--symplicy-border-radius);
    background-color: color('background-input');
    color: color('text');
    font-size: rem(16px);
    font-weight: 600;
    line-height: (23 / 16);
    text-align: center;
    cursor: pointer;

    .svg {
      @include margin(-2px null null 10px);
      @include size(14px, 15px);
      display: inline-block;
      fill: currentColor;
      vertical-align: middle;
    }
  }

  .file-value {
    @include padding(30px 30px 10px);
    align-items: stretch;
    width: 100%;

    .file-item {
      display: flex;

      &:not(:first-child) {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid color('border');
      }

      .file-item-name {
        width: 100%;
        max-width: calc(100% - 30px);
        color: color('text');
        font-family: var(--symplicy-font-secondary);
        text-align: left;
      }

      .file-item-reset {
        @include size(30px, auto);
        @include padding(0);
        @include margin(0);
        display: inline-block;
        position: relative;
        z-index: 2;
        border: 0;
        border-radius: 0;
        background: none;
        appearance: none;
        outline: none;
        cursor: pointer;

        &::before,
        &::after {
          @include position(absolute, calc(50% - 2px) null null calc(50% - 6px));
          @include size(12px, 2px);
          content: '';
          transition: background-color .4s $ease-out-quart;
          border-radius: 1px;
          background-color: color('text');
        }

        &::before {
          transform: rotate(-45deg);
        }

          &::after {
          transform: rotate(45deg);
        }

        &:focus,
        &:hover {
          &::before,
          &::after {
            background-color: color('highlight');
          }
        }
      }
    }
  }
</style>
