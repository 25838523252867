<template>
  <span :class="`svg ${type}`">
    <svg v-if="type === 'search'" :width="width" :height="height" viewBox="-12 14 24 24">
      <path d="M11.4,36l-4.7-4.7C8.2,29.6,9,27.4,9,25c0-5.5-4.5-10-10-10c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10c2.4,0,4.6-0.8,6.3-2.3 l4.7,4.7L11.4,36z M-9,25c0-4.4,3.6-8,8-8s8,3.6,8,8s-3.6,8-8,8S-9,29.4-9,25z" />
    </svg>
    <svg v-else-if="type === 'close'" :width="width" :height="height" viewBox="-111 13 16 16">
      <polygon points="-95.6,15 -97,13.6 -103,19.6 -109,13.6 -110.4,15 -104.4,21 -110.4,27 -109,28.4 -103,22.4 -97,28.4 -95.6,27 -101.6,21 " />
    </svg>

    <svg v-else-if="type === 'chevron-left'" :width="width" :height="height" viewBox="0 0 5 10">
      <path d="m4.1 0c.2 0 .4.1.5.2.4.2.5.8.1 1.2l-2.9 3.7 2.9 3.3c.4.4.3 1 0 1.3-.3.4-.9.3-1.2-.1l-3.3-3.9c-.3-.4-.3-.8 0-1.2l3.5-4.4-.1.2c0-.3.3-.3.5-.3z" />
    </svg>

    <svg v-else-if="type === 'chevron-right'" :width="width" :height="height" viewBox="0 0 5 10" style="transform: rotate(180deg);">
      <path d="m4.1 0c.2 0 .4.1.5.2.4.2.5.8.1 1.2l-2.9 3.7 2.9 3.3c.4.4.3 1 0 1.3-.3.4-.9.3-1.2-.1l-3.3-3.9c-.3-.4-.3-.8 0-1.2l3.5-4.4-.1.2c0-.3.3-.3.5-.3z" />
    </svg>

    <svg v-else-if="type === 'check-round'" :width="width" :height="height" viewBox="0 0 96 96">
      <path d="M48,4C23.7,4,4,23.699,4,48s19.7,44,44,44s44-19.699,44-44S72.3,4,48,4z M48,84c-19.882,0-36-16.118-36-36s16.118-36,36-36  s36,16.118,36,36S67.882,84,48,84z"/>
      <path d="M64.284,37.17c-1.562-1.561-4.095-1.561-5.657,0L44.485,51.313l-5.657-5.657c-1.562-1.562-4.094-1.562-5.657,0  c-1.562,1.562-1.562,4.095,0,5.658l8.484,8.483c1.562,1.562,4.096,1.562,5.658,0l16.97-16.97  C65.846,41.265,65.848,38.733,64.284,37.17z"/>
    </svg>

    <svg v-else-if="type === 'cross'" :width="width" :height="height" viewBox="0 0 512 512">
      <path d="M74.966,437.013c-99.97-99.97-99.97-262.065,0-362.037c100.002-99.97,262.066-99.97,362.067,0  c99.971,99.971,99.971,262.067,0,362.037C337.032,536.998,174.968,536.998,74.966,437.013z M391.782,120.227  c-75.001-74.985-196.564-74.985-271.534,0c-75.001,74.985-75.001,196.55,0,271.535c74.97,74.986,196.533,74.986,271.534,0  C466.754,316.775,466.754,195.212,391.782,120.227z M188.124,369.137l-45.251-45.266l67.876-67.877l-67.876-67.876l45.251-45.267  L256,210.743l67.877-67.892l45.25,45.267l-67.876,67.876l67.876,67.877l-45.25,45.266L256,301.245L188.124,369.137z"/>
    </svg>

    <svg v-else-if="type === 'check'" :width="width" :height="height" viewBox="0 -46 417.81333 417">
      <path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" />
    </svg>

    <svg v-else-if="type === 'uncheck'" :width="width" :height="height" viewBox="0 0 512 512">
      <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/>
    </svg>

    <svg v-else-if="type === 'plus'" :width="width" :height="height" viewBox="0 0 448 448">
      <path d="m408 184h-136c-4.417969 0-8-3.582031-8-8v-136c0-22.089844-17.910156-40-40-40s-40 17.910156-40 40v136c0 4.417969-3.582031 8-8 8h-136c-22.089844 0-40 17.910156-40 40s17.910156 40 40 40h136c4.417969 0 8 3.582031 8 8v136c0 22.089844 17.910156 40 40 40s40-17.910156 40-40v-136c0-4.417969 3.582031-8 8-8h136c22.089844 0 40-17.910156 40-40s-17.910156-40-40-40zm0 0"/>
    </svg>

    <svg v-else-if="type === 'less'" :width="width" :height="height" viewBox="0 0 448 80">
      <path d="M408,0H40C17.9,0,0,17.9,0,40s17.9,40,40,40h368c22.1,0,40-17.9,40-40S430.1,0,408,0z"/>
    </svg>

    <svg v-else-if="type === 'upload'" :width="width" :height="height" viewBox="-34 59.3 18 20">
      <path d="M-24,62.7v11.6c0,0.6-0.4,1-1,1s-1-0.4-1-1V62.7l-3.3,3.3c-0.4,0.4-1.1,0.3-1.4-0.1c-0.3-0.4-0.3-0.9,0-1.3l5-5 c0.4-0.4,1-0.4,1.4,0l5,5c0.4,0.4,0.5,1,0.1,1.4s-1,0.5-1.4,0.1c0,0-0.1-0.1-0.1-0.1L-24,62.7L-24,62.7z M-34,74.3c0-0.6,0.4-1,1-1 s1,0.4,1,1v3h14v-3c0-0.6,0.4-1,1-1s1,0.4,1,1v3c0,1.1-0.9,2-2,2h-14c-1.1,0-2-0.9-2-2V74.3z" />
    </svg>

    <svg v-else-if="type === 'copy'" :width="width" :height="height" viewBox="0 0 1000 1000">
      <path d="M387.1,925.4c-86.1,86.1-226.3,86.1-312.5-0.1c-86.1-86.1-86.1-226.3,0-312.4l221.3-221.3c86.1-86.1,226.3-86.1,312.5,0.1c2.3,2.3,4.6,4.7,6.8,7.1c1.3,1.1,2.5,2.2,3.8,3.4c3.5,3.5,6.4,7.3,8.7,11.4c0.3,0.4,0.6,0.7,0.9,1.1c-0.1,0.1-0.1,0.1-0.2,0.1c10.6,20,7.5,45.4-9.4,62.2c-17,17-42.6,20-62.6,9.1c-1.4,1.5-15.3-12.3-22.6-19.7l-0.1-0.1c-45-45-117.6-45.3-162.6-0.4L149.4,687.7c-45,45-45,117.9,0,162.9l0.1,0.1c45,45,117.9,45,162.9,0l133.5-133.5c46.3,22.7,100.8,23.5,147.5,2L387.1,925.4L387.1,925.4L387.1,925.4z M925.4,387.1L704.1,608.4c-86.1,86.1-226.3,86.1-312.5-0.1c-2.3-2.3-4.6-4.7-6.8-7.1c-1.3-1.1-2.5-2.2-3.7-3.4c-3.5-3.5-6.4-7.3-8.7-11.4c-0.3-0.4-0.6-0.7-0.8-1.1c0,0,0.1-0.1,0.2-0.1c-10.6-20-7.5-45.4,9.4-62.2c17-17,42.6-20,62.6-9.1c1.4-1.5,15.3,12.4,22.6,19.7l0.1,0.1c45,45,117.6,45.4,162.5,0.4l221.7-221.7c45-45,45-117.9,0-162.9l0-0.1c-45-45-117.9-45-162.9,0L554.2,282.8c-46.2-22.6-100.8-23.5-147.5-2L612.9,74.6c86.1-86.1,226.3-86.1,312.5,0.1C1011.5,160.8,1011.5,301,925.4,387.1L925.4,387.1L925.4,387.1z" />
    </svg>

    <svg v-else-if="type === 'save'" :width="width" :height="height" viewBox="0 0 438.533 438.533">
      <path d="M432.823,121.049c-3.806-9.132-8.377-16.367-13.709-21.695l-79.941-79.942c-5.325-5.325-12.56-9.895-21.696-13.704 C308.346,1.903,299.969,0,292.357,0H27.409C19.798,0,13.325,2.663,7.995,7.993c-5.33,5.327-7.992,11.799-7.992,19.414v383.719 c0,7.617,2.662,14.089,7.992,19.417c5.33,5.325,11.803,7.991,19.414,7.991h383.718c7.618,0,14.089-2.666,19.417-7.991 c5.325-5.328,7.987-11.8,7.987-19.417V146.178C438.531,138.562,436.629,130.188,432.823,121.049z M182.725,45.677 c0-2.474,0.905-4.611,2.714-6.423c1.807-1.804,3.949-2.708,6.423-2.708h54.819c2.468,0,4.609,0.902,6.417,2.708 c1.813,1.812,2.717,3.949,2.717,6.423v91.362c0,2.478-0.91,4.618-2.717,6.427c-1.808,1.803-3.949,2.708-6.417,2.708h-54.819 c-2.474,0-4.617-0.902-6.423-2.708c-1.809-1.812-2.714-3.949-2.714-6.427V45.677z M328.906,401.991H109.633V292.355h219.273 V401.991z M402,401.991h-36.552h-0.007V283.218c0-7.617-2.663-14.085-7.991-19.417c-5.328-5.328-11.8-7.994-19.41-7.994H100.498 c-7.614,0-14.087,2.666-19.417,7.994c-5.327,5.328-7.992,11.8-7.992,19.417v118.773H36.544V36.542h36.544v118.771 c0,7.615,2.662,14.084,7.992,19.414c5.33,5.327,11.803,7.993,19.417,7.993h164.456c7.61,0,14.089-2.666,19.41-7.993 c5.325-5.327,7.994-11.799,7.994-19.414V36.542c2.854,0,6.563,0.95,11.136,2.853c4.572,1.902,7.806,3.805,9.709,5.708l80.232,80.23 c1.902,1.903,3.806,5.19,5.708,9.851c1.909,4.665,2.857,8.33,2.857,10.994V401.991z"/>
    </svg>

    <svg v-else-if="type === 'trash'" :width="width" :height="height" viewBox="0 0 512 512">
      <path d="m424 64h-88v-16c0-26.467-21.533-48-48-48h-64c-26.467 0-48 21.533-48 48v16h-88c-22.056 0-40 17.944-40 40v56c0 8.836 7.164 16 16 16h8.744l13.823 290.283c1.221 25.636 22.281 45.717 47.945 45.717h242.976c25.665 0 46.725-20.081 47.945-45.717l13.823-290.283h8.744c8.836 0 16-7.164 16-16v-56c0-22.056-17.944-40-40-40zm-216-16c0-8.822 7.178-16 16-16h64c8.822 0 16 7.178 16 16v16h-96zm-128 56c0-4.411 3.589-8 8-8h336c4.411 0 8 3.589 8 8v40c-4.931 0-331.567 0-352 0zm313.469 360.761c-.407 8.545-7.427 15.239-15.981 15.239h-242.976c-8.555 0-15.575-6.694-15.981-15.239l-13.751-288.761h302.44z"/>
      <path d="m256 448c8.836 0 16-7.164 16-16v-208c0-8.836-7.164-16-16-16s-16 7.164-16 16v208c0 8.836 7.163 16 16 16z"/>
      <path d="m336 448c8.836 0 16-7.164 16-16v-208c0-8.836-7.164-16-16-16s-16 7.164-16 16v208c0 8.836 7.163 16 16 16z"/>
      <path d="m176 448c8.836 0 16-7.164 16-16v-208c0-8.836-7.164-16-16-16s-16 7.164-16 16v208c0 8.836 7.163 16 16 16z"/>
    </svg>

    <svg v-else-if="type === 'edit'" :width="width" :height="height" viewBox="0 0 383.947 383.947">
      <polygon points="0,303.947 0,383.947 80,383.947 316.053,147.893 236.053,67.893"/>
      <path d="M377.707,56.053L327.893,6.24c-8.32-8.32-21.867-8.32-30.187,0l-39.04,39.04l80,80l39.04-39.04 C386.027,77.92,386.027,64.373,377.707,56.053z"/>
    </svg>

    <svg v-else-if="type === 'reset'" :width="width" :height="height" viewBox="0 0 40.5 42.17">
      <path d="M39.07,26.66l-3.82-1.15a2,2,0,0,0-2.43,1A13,13,0,1,1,26.69,9.48l-2.28,2.28a2,2,0,0,0,1.41,3.42H37a2,2,0,0,0,2-2V2A2,2,0,0,0,35.58.59l-3,3.05A21,21,0,1,0,21,42.17,20.95,20.95,0,0,0,40.35,29.32,2,2,0,0,0,39.07,26.66Z"/>
    </svg>

    <svg v-else-if="type === 'download'" :width="width" :height="height" viewBox="0 0 512 512">
      <path d="m239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0 -33.941 0l-49.539 49.539v-262.059a24 24 0 0 0 -48 0v262.059l-49.539-49.539a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941z"/>
      <path d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z"/>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 16
    }
  }
};
</script>

<style lang="scss" scoped>
.svg {
  transition: fill .3s $ease-out-quad;

  svg {
    display: block;
  }
}
</style>
