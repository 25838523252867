<template>
  <div class="view categories">
    <div class="view-header">
      <topbar>
        <template #input>
          <inputs
            :type="'search'"
            :id="'categories-search'"
            :value="filter"
            @input="onSearchInput"
            @submit="onSearchSubmit"
          >
            <template #icon>
              <sprite
                :type="'search'"
              />
            </template>
          </inputs>
          <selection
            :id="'items_per_lisiting'"
            :selecteds="[perPage]"
            :options='itemsPerListing'
            @onOption="onChangeItemsPerListing"
          />
        </template>

        <template #actions>
          <btn
            :theme="'icon'"
            :disabled="loading"
            @click="onCreate"
          >
            <sprite :type="'plus'" />
          </btn>
          <btn
            :theme="'icon ghost'"
            :disabled="selecteds.length === 0"
            @click="onToggleDelete"
          >
            <sprite :type="'less'" />
          </btn>
        </template>
      </topbar>
    </div>

    <div class="view-content">
      <div class="view-content-wrapper">
        <listing
          :items="items"
          :ordering="ordering"
          :properties="properties"
          :linkOn="['name']"
          :loading="loading"
          :to="{
            name: 'CategoriesDetail',
            param: 'id'
          }"
          @toggle="onToggle($event)"
          @toggleAll="onToggleAll"
        />
      </div>

      <loader v-show="loading"/>
    </div>

    <div class="view-footer">
      <pagination
        v-bind="pages"
        @click="onPage"
        @prev="onPage('prev')"
        @next="onPage('next')"
      />
    </div>

    <!-- Popup -->
    <popup
      v-if="showPopupDelete"
      :title="deleteTitle"
      @close="onCloseDelete"
    >
      <p class="popup-text">{{ $t('categories.delete.text') }}</p>
      <div class="popup-form">
        <inputs
          :id="'skills-deletes'"
          :value="deleteInput"
          :error="deleteError"
          @input="onDeleteInput"
        />
        <btn
          :theme="'ghost'"
          :disabled="deleteInput.length === 0"
          @click="onDelete"
        >
          {{ $t('actions.delete') }}
        </btn>
      </div>
    </popup>
  </div>
</template>

<script>
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';
import Inputs from '@/components/components/Inputs';
import Selection from '@/components/components/Selection';
import Loader from '@/components/components/Loader';
import Pagination from '@/components/modules/Pagination';
import Listing from '@/components/modules/Listing';
import Popup from '@/components/partials/Popup';
import Topbar from '@/components/partials/Topbar';

import itemsPerListing from '@/data/static/items-per-listing';

export default {
  components: {
    Pagination,
    Sprite,
    Popup,
    Topbar,
    Inputs,
    Listing,
    Loader,
    Selection,
    Btn
  },
  data() {
    return {
      showPopupDelete: false,
      deleteInput: '',
      deleteError: false,
      itemsPerListing
    }
  },
  mounted() {
    const { query } = this.$route;
    let { page, items, filter } = query;

    if (filter) {
      this.$store.commit('categories/updateFilter', filter);
    }

    if (page && items) {
      this.$store.commit('setPerPage', {
        id: items,
        name: items
      });

      this.$store.dispatch('categories/getItems', { page, items , filter });
      return;
    }

    if (!page) {
      page = this.$store.state.categories.pages.current;
    }

    if (!items) {
      items = this.$store.state.perPage;
    }

    this.$store.commit('setPerPage', items );
    this.$router.push({ name: 'CategoriesListing', query: { page: this.$store.state.categories.pages.current, items: items.name, filter } });
  },
  computed: {
    properties() {
      return this.$store.state.categories.properties;
    },
    ordering() {
      return this.$store.state.categories.ordering;
    },
    items() {
      return this.$store.state.categories.items;
    },
    filter() {
      return this.$store.state.categories.filter;
    },
    selecteds() {
      return this.$store.state.categories.items.filter(item => item.selected);
    },
    deleteTitle() {
      if (this.selecteds.length <= 1) {
        return this.$t('categories.delete.title.singular');
      }

      return this.$t('categories.delete.title.plural', { number: this.selecteds.length });
    },
    loading() {
      return this.$store.state.categories.requests > 0;
    },
    pages() {
      return this.$store.state.categories.pages;
    },
    perPage() {
      return this.$store.state.perPage;
    }
  },
  watch: {
    $route(to, from) {
      const { query } = to;
      const { page, items, filter } = query;

      if (page !== from.query.page || items !== from.query.items || filter !== from.query.filter) {
        this.$store.dispatch('categories/getItems', { page, items, filter });
      }
    }
  },
  methods: {
    onSearchInput(e) {
      this.$store.commit('categories/updateFilter', e);
    },
    onSearchSubmit() {
      this.$router.push({
        name: 'CategoriesListing',
        query: {
          page: 1,
          items: this.$store.state.perPage.name,
          filter: this.$store.state.categories.filter
        }
      });
    },
    onToggleAll() {
      this.$store.dispatch('categories/toggleAll');
    },
    onToggle(el) {
      this.$store.dispatch('categories/updateSelection', [el]);
    },
    onToggleDelete() {
      this.showPopupDelete = !this.showPopupDelete;
    },
    onCreate() {
      this.$router.push({ name: 'CategoriesCreate' });
    },
    onCloseDelete() {
      this.showPopupDelete = false;
      this.deleteInput = '';
      this.deleteError = false;
    },
    onDeleteInput(value) {
      this.deleteInput = value;
    },
    onDelete() {
      if (this.deleteInput !== 'DELETE') {
        this.deleteError = true;
        return;
      }

      this.onCloseDelete();

      this.$store.dispatch('categories/deleteSelecteds');
    },
    onPage(page) {
      if (page === 'prev') {
        this.$router.push({ name: 'CategoriesListing', query: {
          page: this.$store.state.categories.pages.current - 1,
          items: this.$store.state.perPage.name,
          filter: this.$store.state.categories.filter
        } });
        return;
      }

      if (page === 'next') {
        this.$router.push({ name: 'CategoriesListing', query: {
          page: this.$store.state.categories.pages.current + 1,
          items: this.$store.state.perPage.name,
          filter: this.$store.state.categories.filter
         } })
        return;
      }

      this.$router.push({ name: 'CategoriesListing', query: {
        page,
        items: this.$store.state.perPage.name,
        filter: this.$store.state.lawyers.filter
      } });
    },
    onChangeItemsPerListing(value) {
      this.$store.commit('setPerPage', value);
      this.$router.push({ name: 'CategoriesListing', query: {
        page: this.$store.state.categories.pages.current,
        items: value.name,
        filter: this.$store.state.categories.filter
      } });
    }
  }
}
</script>

<style lang="scss">
.categories {
  .view {
    &-content {
      &-wrapper {
        .listing {
          td.main {
            width: 500px;
          }
        }
      }
    }
  }
}
</style>
