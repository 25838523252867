import { render, staticRenderFns } from "./Casus.vue?vue&type=template&id=4573007f&scoped=true&"
import script from "./Casus.vue?vue&type=script&lang=js&"
export * from "./Casus.vue?vue&type=script&lang=js&"
import style0 from "./Casus.vue?vue&type=style&index=0&id=4573007f&lang=scss&scoped=true&"
import style1 from "./Casus.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4573007f",
  null
  
)

export default component.exports