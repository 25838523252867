const customization = {
  defaults: {
    color: {
      // 'backdrop': '0,0,0',
      'backdrop': '#000000',
      // 'background': '255,255,255',
      'background': '#ffffff',
      // 'highlight': '255,43,96',
      'highlight': '#FF2B60',
      // 'error': '255,43,96',
      'error': '#FF2B60',
      // 'success': '43,114,255',
      'success': '#2B72FF',
      // 'text': '15,15,15',
      'text': '#0F0F0F',
      // 'text-light': '143,137,143',
      'text-light': '#8F898F',
      // 'input-background': '242,244,245',
      'input-text': '#0F0F0F',
      // 'input-background': '242,244,245',
      'input-background': '#F2F4F5',
      // 'border': '235,233,232'
      'border': '#EBE9E8'
    },
    spacing: {
      v: 32,
      h: 32
    },
    border: {
      radius: 10
    },
    font: {
      primary: '\'Crimson Text\', serif',
      secondary: '\'Source Sans Pro\', sans-serif'
    }
  },
  types: [
    {
      id: 'color',
      label: 'Couleurs',
      fields: [
        { id: 'backdrop', label: 'Arrière plan', type: 'color' },
        { id: 'background', label: 'Fond', type: 'color' },
        { id: 'highlight', label: 'Highlight', type: 'color' },
        { id: 'text', label: 'Texte', type: 'color' },
        { id: 'text-light', label: 'Texte secondaire', type: 'color' },
        { id: 'input-text', label: 'Champs - texte', type: 'color' },
        { id: 'input-background', label: 'Champs - fond', type: 'color' },
        { id: 'border', label: 'Bordures', type: 'color' },
        { id: 'error', label: 'Erreur', type: 'color' },
        { id: 'success', label: 'Succès', type: 'color' },
      ]
    },
    {
      id: 'font',
      label: 'Police de caractère',
      fields: [
        {
          id: 'primary',
          label: 'Primaire',
          type: 'select',
          options: [
            {
              id: 'font-1',
              value: 'Arial, sans-serif',
              label: 'Arial'
            },
            {
              id: 'font-2',
              value: 'Courier New, serif',
              label: 'Courier New'
            },
            {
              id: 'font-3',
              value: '\'Crimson Text\', serif',
              label: 'Crimson Text'
            },
            {
              id: 'font-4',
              value: 'Helvetica, sans-serif',
              label: 'Helvetica'
            },
            {
              id: 'font-5',
              value: 'Georgia, serif',
              label: 'Georgia'
            },
            {
              id: 'font-6',
              value: '\'Source Sans Pro\', sans-serif',
              label: 'Source Sans Pro'
            },
            {
              id: 'font-7',
              value: 'Times New Roman, sans-serif',
              label: 'Times New Roman'
            },
            {
              id: 'font-8',
              value: 'Verdana, serif',
              label: 'Verdana'
            }
          ]
        },
        {
          id: 'secondary',
          label: 'Secondaire',
          type: 'select',
          options: [
            {
              id: 'font-1',
              value: 'Arial, sans-serif',
              label: 'Arial'
            },
            {
              id: 'font-2',
              value: 'Courier New, serif',
              label: 'Courier New'
            },
            {
              id: 'font-3',
              value: '\'Crimson Text\', serif',
              label: 'Crimson Text'
            },
            {
              id: 'font-4',
              value: 'Helvetica, sans-serif',
              label: 'Helvetica'
            },
            {
              id: 'font-5',
              value: 'Georgia, serif',
              label: 'Georgia'
            },
            {
              id: 'font-6',
              value: '\'Source Sans Pro\', sans-serif',
              label: 'Source Sans Pro'
            },
            {
              id: 'font-7',
              value: 'Times New Roman, sans-serif',
              label: 'Times New Roman'
            },
            {
              id: 'font-8',
              value: 'Verdana, serif',
              label: 'Verdana'
            }
          ]
        }
      ]
    },
    {
      id: 'border',
      label: 'Bords',
      fields: [
        {
          id: 'radius',
          label: 'Arrondi',
          type: 'range',
          min: 0,
          max: 32
        }
      ]
    },
    // {
    //   id: 'spacing',
    //   label: 'Espacements',
    //   fields: [
    //     {
    //       id: 'v',
    //       label: 'Vertical',
    //       type: 'range',
    //       min: 16,
    //       max: 44,
    //     },
    //     {
    //       id: 'h',
    //       label: 'Horizontal',
    //       type: 'range',
    //       min: 16,
    //       max: 44,
    //     }
    //   ]
    // }
  ]
};

export default customization;
