<template>
  <div class="view">
    <div class="view-header">
      <topbar>
        <template #nav>
          <ul>
            <li>
              <router-link to="/spartiate">Documents</router-link>
            </li>
            <li>
              <router-link to="/spartiate/import">Import</router-link>
            </li>
          </ul>
        </template>

        <template #actions>
          <btn>Créer</btn>
          <btn :theme="'ghost'" :disabled="true">Supprimer</btn>
        </template>
      </topbar>
    </div>

    <div class="view-content">
      <div class="view-content-wrapper">
        Import
      </div>
    </div>
  </div>
</template>

<script>
import Topbar from '@/components/partials/Topbar';
import Btn from '@/components/components/Btn';

export default {
  components: {
    Topbar,
    Btn
  }
}
</script>
