
const lawcase = {
  to: (data) => {
    return {
      content_fr: data.content_fr,
      content_en: data.content_en,
      content_nl: data.content_nl,
      algolia_primary: data.algolia_primary && data.algolia_primary.length > 0 ? data.algolia_primary : null,
      algolia_secondary: data.algolia_secondary && data.algolia_secondary.length > 0 ? data.algolia_secondary : null,
      penal: data.penal === 1,
      skill_ids: data.skills.map(s => s.id)
    }
  }
};

export default lawcase;
