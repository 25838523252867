<template>
  <div class="view form-detail">
    <div class="view-header">
      <topbar>
        <template #title>
          <h1 v-if="datas && datas.meta">{{ datas.meta.name }}</h1>
        </template>

        <template #actions>
          <!-- <a
            v-if="!create"
            download
            :href="getDownloadLink()"
            target="_blank"
          >
            <sprite
              :type="'download'"
              :width="20"
              :height="20"
            />
          </a> -->
          <btn
            v-if="!create"
            :theme="'icon'"
            :href="getDownloadUrl()"
            :target="'_blank'"
          >
            <sprite
              :type="'download'"
              :width="20"
              :height="20"
            />
          </btn>
          <btn
            :theme="'icon'"
            :disabled="loading"
            @click="onSave"
          >
            <sprite
              :type="'save'"
              :width="20"
              :height="20"
            />
          </btn>

          <btn
            :disabled="loading || create"
            :theme="'icon ghost'"
            @click="onToggleDelete"
          >
            <sprite :type="'less'" />
          </btn>
        </template>
      </topbar>
    </div>

    <div class="view-content">
      <div class="view-content-wrapper">
        <h2 class="view-content-title">Informations générales</h2>
        <fields v-if="datas && datas.meta">
          <div class="fields-row" data-column="3">
            <!-- Name -->
            <div class="fields-field">
              <inputs
                :id="'name'"
                :value="datas.meta.name"
                :label="$t('labels.name')"
                :required="true"
                @input="onInput($event, 'name')"
              />
            </div>

            <!-- File -->
            <div class="fields-field">
              <inputs
                :id="'form-file'"
                :type="'file'"
                :label="$t('labels.file')"
                @change="onUploadFile($event)"
              />
            </div>
          </div>
          <div
            v-if="datas.meta.uuid !== '3dc5fbe8-4c1f-42c2-bd98-6d2b8bc8a6cb'"
            class="fields-row"
            data-column="2"
          >
            <!-- Case search -->
            <div class="fields-field">
              <search
                :id="'cases-search'"
                :type="'cases'"
                :selecteds="selectedLawcases"
                :multiple="false"
                @click="onSelect($event, 'case')"
              />
              <pills
                v-if="datas.meta.case"
                :property="'content'"
                :els="[datas.meta.case]"
                :remove="false"
              />
            </div>

            <!-- Client search -->
            <div class="fields-field">
              <search
                :id="'clients-search'"
                :type="'clients'"
                :selecteds="selectedClients"
                :multiple="false"
                @click="onSelect($event, 'client')"
              />
              <pills
                v-if="datas.meta.client"
                :property="'name'"
                :els="[datas.meta.client]"
                :remove="false"
              />
            </div>
          </div>

          <div class="fields-row tags" data-column="3">
            <form
              class="tags-form"
              @submit.prevent="onAddFormTag"
            >
              <inputs
                :id="'name'"
                :value="datas.meta.tagToAdd"
                :label="'Tags'"
                @input="onInput($event, 'tagToAdd')"
              />

              <button
                class="tags-form-submit"
                :disabled="!datas.meta.tagToAdd || !datas.meta.tagToAdd.length"
              >
                <Sprite
                  :type="'plus'"
                  :width="16"
                  :height="16"
                />
              </button>
            </form>

            <ul class="tags-list">
              <li
                v-for="(tag, prop) in tags"
                :key="`tag-${prop}`"
              >
                <Btn
                  :class="[
                    'pill',
                    { 'pill-success': tag.type === 'add' },
                    { 'pill-error': tag.type === 'remove' },
                  ]"
                  @click="updateFormTagType(prop)"
                >
                  {{ prop }}
                  <Sprite
                    :type="'close'"
                    :width="12"
                    :height="12"
                    @click.native.stop="removeFormTag(prop)"
                  />
                </Btn>
              </li>
            </ul>
          </div>

          <div
            v-if="create"
            class="fields-row"
            data-column="2"
          >
            <!-- Case search -->
            <div class="fields-field">
              <search
                :id="'forms-search'"
                :type="'forms'"
                :selecteds="[]"
                :multiple="false"
                @click="onDuplicate($event)"
              />
            </div>
          </div>
        </fields>

        <h2 class="view-content-title">
          Questions

          <button
            class="title-button"
            @click="openCasus"
          >
            Tester
          </button>
        </h2>
        <div class="questions">
          <question
            v-for="(question, index) in datas.data"
            :key="`question-${question.id}-${index}`"
            :index="index"
            :question="question"
            :questions="datas.data"
            @add="onQuestionAdd(index)"
            @delete="onQuestionDelete(index)"
            @title="onTitleUpdate($event, index)"
            @description="onDescriptionUpdate($event, index)"
            @type="onTypeUpdate($event, index)"
            @answer="onAnswerUpdate($event, index)"
            @addAnswer="onAnswerAdd(index)"
            @deleteAnswer="onAnswerDelete($event, index)"
            @deleteRule="onRuleDelete($event, index)"
            @editRule="onRuleEdit($event, index)"
            @inputTag="onInputTag($event, index)"
            @addTag="onAddTag($event, index)"
            @removeTag="onRemoveTag($event, index)"
            @updateTagType="onUpdateTagType($event, index)"
            @toggleTags="onToggleTags($event, index)"
          />
          <button
            @click="onQuestionAdd(datas.data.length)"
            class="questions-add"
          >
            <sprite
              :type="'plus'"
              :width="20"
              :height="20"
            />
          </button>
        </div>


      </div>
      <loader v-show="loading"/>
    </div>

     <!-- Popup -->
    <popup
      v-if="showPopupDelete"
      :title="$t('forms.delete.title.singular')"
      @close="onCloseDelete"
    >
      <p class="popup-text">{{ $t('forms.delete.text') }}</p>
      <div class="popup-form">
        <inputs
          :id="'lawcase-delete'"
          :value="deleteInput"
          :error="deleteError"
          @input="onDeleteInput"
        />
        <btn
          :theme="'ghost'"
          :disabled="deleteInput.length === 0"
          @click="onDelete"
        >
          {{ $t('actions.delete') }}
        </btn>
      </div>
    </popup>

    <popup
      v-if="showRuleEdit"
      :title="'Modification'"
      @close="onCloseRuleEdit"
    >
      <div style="min-height: 300px">
        <selection
          :id="'rule-questions'"
          :options="ruleEditOptions"
          :selecteds="ruleSelecteds"
          @onOption="onRuleChangeQuestion($event.id)"
        />
        <div
          style="margin-top: 12px;"
        >
          <checkbox
            v-for="answer in ruleEditAnswers"
            :key="`rule-edition-answer-${answer.value}`"
            :id="`rule-edition-answer-${answer.value}`"
            :label="answer.label"
            :checked="answer.checked"
            @change="onRuleChangeAnswer(answer.value)"
          />
        </div>
      </div>
    </popup>

    <div
      v-if="casus"
      class="casus-popup"
    >
      <casus @close="closeCasus"/>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { getRandomString } from '@/helpers';
import Formatters from '@/data/formatters';

// Components
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';
import Inputs from '@/components/components/Inputs';
import Loader from '@/components/components/Loader';
import Checkbox from '@/components/components/Checkbox';
import Selection from '@/components/components/Selection';
import Pills from '@/components/modules/Pills';
import Search from '@/components/modules/Search';
import Question from '@/components/modules/Question';
import Fields from '@/components/partials/Fields';
import Topbar from '@/components/partials/Topbar';
import Popup from '@/components/partials/Popup';
import Casus from '@/components/partials/Casus';

import { getForm } from '@/data/calls';

import {
  getClient,
  getLawcase
} from '@/data/calls';

export default {
  components: {
    Btn, Inputs, Sprite, Loader, Selection, Checkbox,
    Pills, Search,
    Question,
    Fields, Topbar, Popup, Casus
  },
  data() {
    return {
      showPopupDelete: false,
      deleteInput: '',
      deleteError: false,
      showRuleEdit: false,
      ruleEdition: {
        indexQ: null,
        indexR: null,
        question: null,
        answers: []
      },
      reader: new FileReader(),
      casus: false
    }
  },
  computed: {
    datas() {
      return this.$store.state.form.datas;
    },
    tags() {
      if (!this.$store.state.form.datas.meta || !this.$store.state.form.datas.meta.tags) return {};
      return this.$store.state.form.datas.meta.tags;
    },
    content() {
      const data = this.$store.state.form.datas.data;
      return JSON.stringify(data, null, 2);
    },
    loading() {
      return this.$store.state.form.requests > 0;
    },
    create() {
      return this.$route.name === 'FormsCreate';
    },
    selectedLawcases() {
      return [this.$store.state.form.datas.meta.case_id];
    },
    selectedClients() {
      const { client_uuid } = this.$store.state.form.datas.meta;
      if (!client_uuid) {
        return [];
      }

      return [client_uuid];
    },
    ruleEditOptions() {
      const questions = this.$store.state.form.datas.data.filter((q, i) => (q.type === 'checkbox' || q.type === 'radio') && i < this.ruleEdition.indexQ);

      return questions.map(q => {
        return {
          name: q.title.fr,
          id: q.id
        }
      });
    },
    ruleEditAnswers() {
      const { data } = this.$store.state.form.datas;
      const ruleQuestion = data[this.ruleEdition.indexQ].visibility.rules[this.ruleEdition.indexR].question;
      const question = data.filter(q => q.id === ruleQuestion);

      return question[0].answers.map(a => {
        return {
          value: a.value,
          label: a.label.fr,
          checked: this.ruleEdition.answers.includes(a.value)
        }
      }).filter(a => a.label && a.label.length > 0);
    },
    ruleSelecteds() {
      const { data } = this.$store.state.form.datas;
      const ruleQuestion = data[this.ruleEdition.indexQ].visibility.rules[this.ruleEdition.indexR].question;
      const question = data.filter(q => q.id === ruleQuestion);

      return [{
        id: question[0].id,
        name: question[0].title.fr
      }]
    }
  },
  beforeMount() {
    this.$store.commit('form/reset');

    if (this.create) {
      return;
    }

    const { uuid } = this.$route.params;
    this.$store.dispatch('form/get', { uuid });
  },
  watch: {
    $route(to) {
      if (to.name === 'FormsDetail') {
        const { uuid } = this.$route.params;
        this.$store.dispatch('form/get', { uuid });
      }
    }
  },
  methods: {
    // Form
    onInput(value, type) {
      this.$store.commit('form/updateMeta', { value, type });
    },
    onUploadFile(event) {
      const { currentTarget } = event;
      const { files } = currentTarget;

      if (!files || files.length === 0) {
        return;
      }

      const file = files[0]
      const { type } = file;

      if (type !== 'application/json') {
        this.notify({
          type: 'error',
          text: 'Le fichier uploadé n\'est pas un fichier JSON'
        });
        return;
      }

      this.readFile(files[0]);
    },
    readFile(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const result = JSON.parse(e.target.result);
          const { meta, data } = result;

          if (meta && this.create) {
            this.setMetas(meta);
          }

          if (data) {
            const value = Formatters.questions.from(data);

            this.$store.commit('form/updateData', {
              value,
              type: 'data'
            });
          }
        } catch (error) {
          this.notify({
            type: 'error',
            text: `Il y a un erreur dans le fichier envoyé : ${error}`
          });
        }
      };

      reader.readAsText(file);
    },
    setMetas(metas) {
      if (metas.name) {
        this.$store.commit('form/updateMeta', {
          value: metas.name,
          type: 'name'
        });
      }

      if (metas.case_id) {
        this.setCase(metas.case_id);
      }

      if (metas.client_uuid) {
        this.setClient(metas.client_uuid);
      }
    },
    async setCase(id) {
      this.$store.commit('form/updateMeta', {
        value: id,
        type: 'case_id'
      });

      const request = await getLawcase({ id });

      if (request.status === 'success') {
        const { data } = request.data;
        this.$store.commit('form/updateMeta', {
          type: 'case',
          value: {
            content: data.content_fr,
            id: data.id
          }
        });
      } else {
        this.notify({
          type: 'error',
          text: `Aucun case avec l'id ${id} trouvé`
        });
      }
    },
    async setClient(uuid) {
      this.$store.commit('form/updateMeta', {
        value: uuid,
        type: 'client_uuid'
      });

      const request = await getClient({ uuid });

      if (request.status === 'success') {
        const { data } = request.data;
        this.$store.commit('form/updateMeta', {
          type: 'client',
          value: data
        });
      } else {
        this.notify({
          type: 'error',
          text: `Aucun client avec l'uuid ${uuid} trouvé`
        });
      }
    },
    // Actions
    onSave() {
      if (this.create) {
        this.$store.dispatch('form/create');
        return;
      }

      this.$store.dispatch('form/save');
    },
    // Form
    onSelect(e, type) {
      const item = e;

      this.$store.commit('form/updateMeta', {
        value: type === 'client' ? item.uuid : item.id,
        type: type === 'client' ? 'client_uuid' : 'case_id'
      });

      this.$store.commit('form/updateMeta', {
        value: item,
        type
      });
    },
    async onDuplicate(e) {
      // if (e && e.content) {
      //   this.$store.commit('form/setDatas', e.content.data);
      // }

      if (e && e.uuid) {
        const request = await getForm({ uuid: e.uuid });
        const { data } = request;
        const questions = data?.content?.data;
        if (questions && questions.length) {
          this.$store.commit('form/setDatas', questions);
        }
      }
    },
    // Notif
    notify(notif) {
      this.$store.dispatch('notifications/addNotifs', [notif]);
    },
    // Popups
    onCloseDelete() {
      this.showPopupDelete = false;
      this.deleteInput = '';
      this.deleteError = false;
    },
    onDeleteInput(value) {
      this.deleteInput = value;
    },
    onDelete() {
      this.$store.dispatch('form/delete');
    },
    onToggleDelete() {
      this.showPopupDelete = !this.showPopupDelete;
    },
    onCloseRuleEdit() {
      this.showRuleEdit = false;
    },
    openCasus() {
      this.$store.commit('casus/updateName', this.datas.meta.case.content);
      this.$store.dispatch('casus/updateQuestions', [...this.datas.data]);
      this.casus = true;
    },
    closeCasus() {
      this.casus = false;
    },
    // Questions
    onQuestionAdd(index) {
      this.$store.commit('form/addQuestion', index + 1);
    },
    onQuestionDelete(index) {
       this.$store.commit('form/deleteQuestion', index);
    },
    onTitleUpdate(event, index) {
      this.$store.commit('form/updateQuestionTitle', {
        value: event.value,
        index,
        lang: event.lang
      });
    },
    onDescriptionUpdate(event, index) {
      this.$store.commit('form/updateQuestionDescription', {
        value: event.value,
        index,
        lang: event.lang
      });
    },
    onTypeUpdate(event, index) {
      this.$store.commit('form/updateQuestionType', {
        value: event,
        index
      });
    },
    onAnswerUpdate(event, index) {
      if (event.type === 'label') {
        this.$store.commit('form/updateQuestionAnswerLabel', {
          value: event.value,
          lang: event.lang,
          indexA: event.index,
          indexQ: index
        });
        return;
      }

      this.$store.commit('form/updateQuestionAnswer', {
        value: event.value,
        type: event.type,
        lang: event.lang,
        indexA: event.index,
        indexQ: index
      });
    },
    onAnswerDelete(indexA, indexQ) {
      this.$store.commit('form/deleteAnswer', {
        indexA,
        indexQ
      });
    },
    onAnswerAdd(index) {
      this.$store.commit('form/addAnswer', index);
    },
    onRuleDelete(indexR, indexQ) {
       this.$store.commit('form/deleteRule', {
        indexQ,
        indexR
      });
    },
    onRuleEdit({ question, answers, index }, indexQ) {
      this.ruleEdition.indexQ = indexQ;
      this.ruleEdition.indexR = index;
      this.ruleEdition.question = question;
      this.ruleEdition.answers = answers;

      this.showRuleEdit = true;
    },
    onRuleChangeQuestion(id) {
      this.$store.commit('form/updateRule', {
        indexQ: this.ruleEdition.indexQ,
        prevQuestion: this.ruleEdition.question,
        newQuestion: id
      });
    },
    onRuleChangeAnswer(value) {
      this.$store.commit('form/updateRuleAnswer', {
        indexQ: this.ruleEdition.indexQ,
        indexR: this.ruleEdition.indexR,
        value
      });
    },
    onInputTag($event, index) {
      this.$store.commit('form/inputTag', {
        indexA: $event.index,
        indexQ: index,
        value: $event.value,
      });
    },
    onAddTag($event, index) {
      this.$store.commit('form/addTag', {
        indexA: $event,
        indexQ: index
      });
    },
    onRemoveTag($event, index) {
      this.$store.commit('form/removeTag', {
        indexA: $event.index,
        indexQ: index,
        tag: $event.tag
      });
    },
    onUpdateTagType($event, index) {
      this.$store.commit('form/updateTagType', {
        indexA: $event.index,
        indexQ: index,
        tag: $event.tag
      });
    },
    onToggleTags($event, index) {
      this.$store.commit('form/toggleTags', {
        indexA: $event,
        indexQ: index
      });
    },
    onAddFormTag() {
      this.$store.commit('form/addFormTag');
    },
    updateFormTagType(tag) {
      this.$store.commit('form/updateFormTagType', { tag });
    },
    removeFormTag(tag) {
      this.$store.commit('form/removeFormTag', { tag });
    },
    getDownloadUrl() {
      return `${process.env.VUE_APP_API_URL}storage/forms/${encodeURIComponent(this.datas.meta.path)}`;
    },
    // onDownload(e) {}
  }
}
</script>

<style lang="scss" scoped>
.form-detail {
  .view-content-title {
    position: relative;

    .title-button {
      @include padding(12px 12px 8px);
      @include position(absolute, 0 0 null null);
      font-size: rem(12px);
      font-weight: 600;
      color: var(--black);
      outline: none;
      cursor: pointer;
    }
  }

  .questions {
    @include margin(null null 24px);
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 72px;

    &-add {
      @include padding(16px null 32px);
      width: 100%;
      text-align: center;
      cursor: pointer;
      outline: none;

      &:first-child {
        padding-top: 32px;
      }

      &:not(:first-child) {
        margin-top: -20px;
      }

      .svg {
        transition: fill .3s $ease-out-quart;
        fill: var(--grey-lighter);
      }

      &:hover {
        .svg {
          fill: var(--black);
        }
      }
    }
  }
  
  .tags {
    &-form {
      position: relative;

      &-submit {
        @include padding(null 10px);
        @include position(absolute, null 0 0 null);
        position: absolute;
        z-index: 1;
        height: 44px;
        border-radius: 0 4px 4px 0;

        &:not(:disabled) {
          cursor: pointer;
        }

        &[disabled] {
          .svg {
            fill: var(--white-darker);
          }
        }
      }
    }

    &-list {
      grid-column: 2 / span 2;
      display: flex;
      flex-wrap: wrap;
      padding-top: 24px;

      li {
        @include margin(null 4px 4px);
      }
    }
  }

  .casus-popup {
    @include position(fixed, 0 null null 0);
    @include size(100%);
    z-index: 1001;
  }
}
</style>

<style lang="scss">
.view.form-detail {
  textarea#content {
    height: auto;
    min-height: 250px;
  }
}
</style>
