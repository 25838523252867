<template>
  <div
    :class="[
      'list',
      { 'inline': inline }
    ]"
  >
    <span
      v-if="label"
      :for="`input-${id}`"
      class="list-label"
    >
      {{ label }}
    </span>
    <btn
      v-if="isButton"
      :theme="'ghost'"
      @click="onAdd"
    >
      {{ $t('actions.add') }}
    </btn>
    <inputs
      v-else
      :id="`input-${id}`"
      :value="value"
      @input="onInput"
      @submit="onAdd"
    >
      <template #icon>
        <sprite :type="'plus'"/>
      </template>
    </inputs>


    <div class="list-values">
      <p
        v-for="value in values"
        :key="`list-${displayProperty ? value[displayProperty] : value}`"
        class="list-value"
      >
        <a
          v-if="isLink && (value.link || value.href)"
          :href="value.link || value.href"
          class="list-value-link"
          target="_blank"
        />
        <span class="list-value-text">{{ displayProperty ? value[displayProperty] : value }}</span>
        <button
          @click.prevent="$emit('remove', value)"
          class="list-value-remove"
        >
          <sprite
            :type="'close'"
            :width="12"
            :height="12"
          />
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';
import Inputs from '@/components/components/Inputs';

export default {
  components: {
    Btn,
    Inputs,
    Sprite
  },
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    displayProperty: {
      type: String,
      default: null
    },
    inline: {
      type: Boolean,
      default: false
    },
    isButton: {
      type: Boolean,
      default: false
    },
    values: Array,isLink: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: ''
    }
  },
  methods: {
    onInput(value) {
      this.value = value;
    },
    onAdd() {
      if (this.isButton) {
        this.$emit('add');
        return;
      }

      if (this.value.length > 0) {
        this.$emit('add', this.value);
        this.value = '';
      }
    },
    // onClick(value) {
    //   if (!this.isLink) {
    //     return;
    //   }
      
    //   const url = value.link || value.href;
    //   window.open(url, '_blank');
    // }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "label label"
    "input values";
  column-gap: 24px;
  align-items: flex-start;
  width: 100%;

  &.inline {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "label label label label"
      "input values values values";

    .list-values {
      @include margin(-6px null null);
    }

    .list-value {
      @include margin(6px 3px null);
      @include padding(5px 24px 5px 10px);
      width: auto;
      border-radius: 3px;
      background-color: var(--grey-blue);

      &:before {
        content: none;
      }

      &-remove {
        @include size(12px);
        right: 4px;
        outline: none;
        cursor: pointer;
      }
    }
  }

  &-label {
    @include padding(null null 10px);
    grid-area: label;
    display: block;
    color: var(--grey);
    font-size: rem(14px);
    line-height: 1;
  }

  .input {
    grid-area: input;
  }


  &-values {
    display: flex;
    flex-wrap: wrap;
    grid-area: values;
  }

  &-value {
    @include padding(null 20px null null);
    position: relative;
    width: 100%;
    font-size: rem(16px);

    &:not(:first-child) {
      @include margin(20px null null);

      &:before {
        @include position(absolute, -9px null null 0);
        @include size(100%, 1px);
        content: '';
        background-color: var(--grey-lightest);
      }
    }

    &-link {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      z-index: 1;

      &:hover {
        + .list-value-text {
          color: var(--primary);
        }
      }
    }

    &-text {
      transition: color .3s $ease-out-quart;
    }

    &-remove {
      @include position(absolute, 50% 0 null null);
      z-index: 2;
      transform: translateY(-50%);
      outline: none;
      cursor: pointer;

      .svg {
        fill: var(--primary);
      }
    }
  }
}
</style>
