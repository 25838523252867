import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/views/Home';
import Dashboard from '@/views/Dashboard';
// Lawyers
import Lawyers from '@/views/Lawyers';
import LawyersDetail from '@/views/lawyers/Detail';
import LawyersListing from '@/views/lawyers/Listing';
// Clients
import Clients from '@/views/Clients';
import ClientsDetail from '@/views/clients/Detail';
import ClientsListing from '@/views/clients/Listing';
// Lawcases
import Lawcases from '@/views/Lawcases';
import LawcasesDetail from '@/views/lawcases/Detail';
import LawcasesListing from '@/views/lawcases/Listing';
// Skills
import Skills from '@/views/Skills';
import SkillsDetail from '@/views/skills/Detail';
import SkillsListing from '@/views/skills/Listing';
// Categories
import Categories from '@/views/Categories';
import CategoriesDetail from '@/views/categories/Detail';
import CategoriesListing from '@/views/categories/Listing';
// Forms
import Forms from '@/views/Forms';
import FormsDetail from '@/views/forms/Detail';
import FormsListing from '@/views/forms/Listing';
// Sprtiate
import Spartiate from '@/views/Spartiate';
import Documents from '@/views/spartiate/Documents';
import Import from '@/views/spartiate/Import';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  // Lawyers
  {
    path: '/lawyers',
    component: Lawyers,
    children: [
      {
        path: '',
        name: 'LawyersListing',
        component: LawyersListing
      },
      {
        path: 'create',
        name: 'LawyersCreate',
        component: LawyersDetail
      },
      {
        path: 'detail/:slug',
        name: 'LawyersDetail',
        component: LawyersDetail
      }
    ]
  },
  // Clients
  {
    path: '/clients',
    component: Clients,
    children: [
      {
        path: '',
        name: 'ClientsListing',
        component: ClientsListing
      },
      {
        path: 'create',
        name: 'ClientsCreate',
        component: ClientsDetail
      },
      {
        path: 'detail/:uuid',
        name: 'ClientsDetail',
        component: ClientsDetail
      }
    ]
  },
  // Lawcases
  {
    path: '/lawcases',
    component: Lawcases,
    children: [
      {
        path: '',
        name: 'LawcasesListing',
        component: LawcasesListing
      },
      {
        path: 'create',
        name: 'LawcasesCreate',
        component: LawcasesDetail
      },
      {
        path: 'detail/:id',
        name: 'LawcasesDetail',
        component: LawcasesDetail
      }
    ]
  },
  // Skills
  {
    path: '/skills',
    component: Skills,
    children: [
      {
        path: '',
        name: 'SkillsListing',
        component: SkillsListing
      },
      {
        path: 'create',
        name: 'SkillsCreate',
        component: SkillsDetail
      },
      {
        path: 'detail/:id',
        name: 'SkillsDetail',
        component: SkillsDetail
      }
    ]
  },
  // Categories
  {
    path: '/categories',
    component: Categories,
    children: [
      {
        path: '',
        name: 'CategoriesListing',
        component: CategoriesListing
      },
      {
        path: 'create',
        name: 'CategoriesCreate',
        component: CategoriesDetail
      },
      {
        path: 'detail/:id',
        name: 'CategoriesDetail',
        component: CategoriesDetail
      }
    ]
  },
  // Forms
  {
    path: '/forms',
    component: Forms,
    children: [
      {
        path: '',
        name: 'FormsListing',
        component: FormsListing
      },
      {
        path: 'create',
        name: 'FormsCreate',
        component: FormsDetail
      },
      {
        path: 'detail/:uuid',
        name: 'FormsDetail',
        component: FormsDetail
      }
    ]
  },

  // Spartiate
  {
    path: '/spartiate',
    component: Spartiate,
    children: [
      {
        path: '',
        name: 'SpartiateDocuments',
        component: Documents,
      },
      {
        path: 'import',
        name: 'SpartiateImport',
        component: Import,
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
