import Vue from 'vue';
import Vuex from 'vuex';

// Module
import form from './form';
import forms from './forms';
import login from './login';
import skill from './skill';
import casus from './casus';
import lawyer from './lawyer';
import client from './client';
import skills from './skills';
import lawyers from './lawyers';
import clients from './clients';
import lawcase from './lawcase';
import lawcases from './lawcases';
import category from './category';
import categories from './categories';
import notifications from './notifications';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appURL: 'http://localhost:8080/',
    locales: ['en', 'fr'],
    locale: 'fr',
    darkmode: false,
    perPage: {
      id: 20,
      name: 20
    }
  },
  mutations: {
    SET_LANG(state, locale) {
      // eslint-disable-next-line
      if (state.locales.indexOf(locale) !== -1) {
        state.locale = locale;
      }
    },
    setDarkmode(state, mode) {
      state.darkmode = mode;
    },
    setPerPage(state, value) {
      state.perPage = value;
    }
  },
  actions: {},
  modules: {
    login,
    form,
    forms,
    skill,
    casus,
    skills,
    lawyer,
    client,
    lawcase,
    lawyers,
    clients,
    lawcases,
    category,
    categories,
    notifications
  }
})
