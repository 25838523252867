// eslint-disable-next-line
import Vue from 'vue';
// eslint-disable-next-line
import { getLawcases, searchLawcases, deleteLawcase } from '@/data/calls';

const lawyers = {
  namespaced: true,
  state: {
    requests: 0,
    filter: '',
    properties: [
      'content',
      'hits',
      'penal'
    ],
    ordering: {
      property: 'content',
      dir: 'asc'
    },
    pages: {
      next: null,
      prev: null,
      current: 1,
      total: null,
    },
    items: []
  },
  mutations: {
    updateFilter(state, value) {
      state.filter = value;
    },
    updateSelected(state, el) {
      const index = state.items.indexOf(el);
      if (index >= 0) {
        state.items[index].selected = !state.items[index].selected;
        return;
      }
    },
    updateRequests(state, desc = false) {
      if (desc) {
        state.requests--;
        return;
      }

      state.requests++;
    },
    setItems(state, items) {
      state.items = items;
    },
    setPagesElement(state, { el, value }) {
      state.pages[el] = value;
    }
  },
  actions: {
    updateSelection({ commit }, els) {
      for (let i = 0; i < els.length; i++) {
        commit('updateSelected', els[i]);
      }
    },
    // eslint-disable-next-line
    async getItems({ rootState, commit, dispatch }, { page, items, filter }) {
      commit('updateRequests');
      let request = null;

      if (filter && filter.length > 0) {
        request = await searchLawcases({
          page,
          items: items ? items : rootState.perPage,
          filter: filter ? filter : null
        });
      } else {
        request = await getLawcases({
          page,
          items: items ? items : rootState.perPage,
        });
      }

      if (request.status === 'success') {
        const { data } = request;

        dispatch('updatePages', data);
        commit('setItems', data.data.map(item => {
          Vue.set(item, 'selected', false);
          return item;
        }));
      }

      commit('updateRequests', true);
    },
    async deleteSelecteds({ state, commit, dispatch }) {
      commit('updateRequests');
      const selecteds = state.items.filter(item => item.selected);
      const notifs = [];

      for (let i = 0; i < selecteds.length; i++) {
        const { id } = selecteds[i];
        const request = await deleteLawcase(id);

        if (request.status === 'success') {
          notifs.push({
            type: 'success',
            text: request.data
          });
        } else {
          notifs.push({
            type: 'error',
            text: `Problème : cas ${id} non supprimé`
          });
        }
      }

      dispatch('notifications/addNotifs', notifs, { root: true });
      commit('updateRequests', true);
    },
    updatePages({ commit }, pages) {
      commit('setPagesElement', { el: 'next', value: pages.next_page_url });
      commit('setPagesElement', { el: 'prev', value: pages.prev_page_url });
      commit('setPagesElement', { el: 'current', value: pages.current_page });
      commit('setPagesElement', { el: 'total', value: pages.last_page });
    },
    toggleAll({ state, commit }) {
      const notSelecteds = state.items.filter(item => !item.selected);

      if (notSelecteds && notSelecteds.length > 0) {
        for (let i = 0; i < notSelecteds.length; i++) {
          commit('updateSelected', notSelecteds[i]);
        }

        return;
      }

      for (let i = 0; i < state.items.length; i++) {
        state.items[i].selected = false;
      }
    }
  }
}

export default lawyers;
