<template>
  <div
    :class="`search search-${position}`"
  >
    <inputs
      :id="id"
      :value="terms"
      :label="$t(`labels.${this.type}_list`)"
      :name="inputName"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput($event)"
      @click.native.prevent
    />

    <div
      v-if="terms.length > 3"
      :class="['search-results', { 'focus': hasFocus }, { 'visible': showDropdown }]"
    >
      <loader v-if="requests > 0"/>
      <template v-else>
        <p
          v-if="items.length === 0"
          class="search-no-result"
        >
          Aucun élément ne correspond
        </p>
        <template v-else>
          <button
            v-for="item in items"
            :key="item.id"
            :class="[ 'search-result', { 'selected': selecteds.includes(item.id)}, { 'highlight': item.isCat } ]"
            @click="onOption(item)"
          >
            {{ item.displayLabel }}
            <span
              v-if="item.isCat"
              class="search-result-label"
            >
              - Catégorie
            </span>
          </button>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
// Dependencies
import _ from 'lodash';
import { getRandomString } from '@/helpers';

import Inputs from '@/components/components/Inputs';
import Loader from '@/components/components/Loader';

// Calls
import {
  getForms,
  // getSkills,
  getSkillsAndCategories,
  getLawyers,
  getClients,
  searchLawcases
} from '@/data/calls';

export default {
  components: {
    Inputs, Loader
  },
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    position: {
      type: String,
      default: 'bottom'
    },
    selecteds: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  beforeMount() {
    this.inputName = getRandomString();
  },
  data() {
    return {
      terms: '',
      items: [],
      inputName: '',
      hasFocus: false,
      showDropdown: true,
      requests: 0,
      onClick: this.click.bind(this),
      debounceRequest: _.debounce(this.request.bind(this), 300, {
        'leading': true,
        'trailing': true
      })
    }
  },
  methods: {
    async onInput(e) {
      this.terms = e;

      if (this.terms.length < 3) {
        this.items = [];
        return;
      }

      this.debounceRequest();
    },
    async request() {
      this.requests++;

      const params = {
        page: 0,
        items: 5,
        filter: this.terms
      };

      let request = null;

      switch (this.type) {
        case 'forms':
          request = await getForms(params);
          this.items = request.data.data.map(el => {
            el.id = el.uuid;
            el.displayLabel = el.name;
            return el;
          });
          break;
        case 'clients':
          request = await getClients(params);
          this.items = request.data.data.map(el => {
            el.id = el.uuid;
            el.displayLabel = el.name;
            return el;
          });
          break;
        case 'skills':
          request = await getSkillsAndCategories(params);
          // request = await getSkills(params);
          this.items = request.data.data.map(el => {
            el.displayLabel = el.name;
            return el;
          });
          break;
        case 'cases':
          request = await searchLawcases(params);
          this.items = request.data.data.map(el => {
            el.displayLabel = el.content;
            return el;
          });
          break;
        default:
          request = await getLawyers(params);
          this.items = request.data.data.map(el => {
            el.displayLabel = `${el.firstname} ${el.lastname}`;
            return el;
          });
          break;
      }

      this.requests--;
    },
    onFocus() {
      this.hasFocus = true;
      this.showDropdown = true;
      window.addEventListener('click', this.onClick);
    },
    onBlur() {
      this.hasFocus = false;
    },
    onOption(item) {
      this.$emit('click', item);

      if (!this.multiple) {
        this.hasFocus = false;
        this.showDropdown = false;
        window.removeEventListener('click', this.onClick);
      }
    },
    click(e) {
      const { target } = e;

      if (this.$el !== target && !this.$el.contains(target)) {
        this.hasFocus = false;
        this.showDropdown = false;
        window.removeEventListener('click', this.onClick);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  width: 100%;

  &.search-top {
    .search-results {
      @include position(absolute, null null 41px 0);
      @include size(100%, auto);
      margin-top: 0;
      border-top: 1px solid var(--white-darker);
      border-bottom: 0;
      border-radius: 4px 4px 0 0;

      &.focus {
        border-color: var(--primary);
      }

      &::before {
        top: auto;
        bottom: 0;
      }
    }
  }

  .input {
    width: 100%;
  }

  &-results {
    @include margin(-3px null null);
    @include padding(10px null);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    border: 1px solid var(--white-darker);
    border-top: 0;
    border-radius: 0 0 4px 4px;
    background-color: var(--white);

    &.focus {
      border-color: var(--primary);
    }

    &:not(.visible) {
      display: none;
    }

    &::before {
      @include position(absolute, 0 null null 15px);
      @include size(calc(100% - 30px), 1px);
      content: '';
      background-color: var(--grey-lightest);
    }
  }

  &-result {
    @include padding(5px 15px);
    width: 100%;
    transition: color .3s $ease-out-quart, background-color .3s $ease-out-quart;
    color: var(--grey);
    font-weight: 600;
    text-align: left;
    cursor: pointer;
    outline: none;

    &.selected {
      color: var(--primary);
    }

    &:not(.selected):focus,
    &:not(.selected):hover {
      color: var(--black);

      .search-result-label {
        color: var(--primary);
      }
    }

    &-label {
      transition: color .3s $ease-out-quart;
      color: var(--grey-light);
      font-size: rem(11px);
    }
  }

  &-no-result {
    @include padding(10px 15px);
    width: 100%;
    font-weight: 600;
    text-align: center;
  }
}
</style>
