export default [
  {
    id: 'question-firstname',
    type: 'text',
    title: {
      fr: 'Prénom'
    }
  },
  {
    id: 'question-lastname',
    type: 'text',
    title: {
      fr: 'Nom'
    }
  },
  {
    id: 'question-email',
    type: 'email',
    title: {
      fr: 'Email'
    }
  },
  {
    id: 'question-phone',
    type: 'phone',
    optional: true,
    title: {
      fr: 'Téléphone'
    }
  },
  {
    id: 'question-privacy',
    type: 'privacy',
    title: {
      fr: 'Politique de vie privée'
    }
  },
  {
    id: 'question-terms',
    type: 'terms',
    title: {
      fr: 'Conditions générales d\'utilisation'
    }
  }
];
